import React, { useState } from 'react';
import { ILoadout } from '../../../../../../game-server/src/modules/loadout/loadout.interface';
import { itemList } from '../../../../utils/itemList';
import { abilities as masterAbilityList } from '../../../../utils/abilityList';
import LoadoutItem from './LoadoutItem';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { Box } from '@chakra-ui/react';
import AbilityList from '../Abilities/AbilityList';
import { doItemsMatch } from '../../../../utils/itemFunctions';
import FakeItem from '../../Inventory/FakeItem';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import { cloneDeep } from 'lodash';
import { COMBAT_INVENTORY_SIZE } from '../../../../utils/constantsCollection';

const templateLoadoutData: ILoadout = {
	loadoutId: 0,
	loadoutName: 'Empty',
	loadoutRelatedZones: [],
	loadoutEquipment: [],
	loadoutInventory: [],
	loadoutAbilityRotation: [],
	loadoutIconItemId: 0,
};

const RED = 'rgba(255, 0, 0, 0.5)';
const GREEN = 'rgba(0, 255, 0, 0.5)';
const BLUE = 'rgba(0, 0, 255, 0.5)';

const LoadoutView = ({ loadoutId, editMode = false }: { loadoutId: number; editMode?: boolean }) => {
	const loadouts = usePlayerField('loadouts');
	const stockpile = usePlayerField('stockpile');
	const vault = usePlayerField('vault');
	const currentlyEquipped = usePlayerField('equipment');
	const combatInventory = usePlayerField('combatInventory');
	const [selectedContainerTab, setSelectedContainerTab] = useState('equipment');
	const selectedLoadoutData = loadouts.find((loadout) => loadout.loadoutId === loadoutId) || templateLoadoutData;

	function getEquipmentBorder(equippedItem: TPartialItemWithItemID<IItem> | undefined) {
		// Check if it's equipped already, then if it's in the stockpile, then if it's in vault, and if not in vault then it's not found
		const currentlyEquippedItem = Object.values(currentlyEquipped).find((e) => doItemsMatch(e, equippedItem));
		if (currentlyEquippedItem) {
			return BLUE;
		}
		const stockpileItem = stockpile.find((e) => doItemsMatch(e, equippedItem));
		if (!stockpileItem) {
			// Find it in the vault
			const vaultItem = vault.find((e) => doItemsMatch(e, equippedItem));
			if (!vaultItem) {
				// Couldn't be found
				return RED;
			}
		}
		return GREEN;
	}

	function getFoodBorders(inventory: ILoadout['loadoutInventory']) {
		const borderColors: string[] = [];
		const stockpileCopy = cloneDeep(stockpile);
		const combatInventoryCopy = cloneDeep(combatInventory);
		for (const item of inventory) {
			const combatInventoryItemindex = combatInventoryCopy.findIndex((e) => doItemsMatch(e, item));
			if (combatInventoryItemindex !== -1) {
				borderColors.push(BLUE);
				combatInventoryCopy.splice(combatInventoryItemindex, 1);
				continue;
			}
			const stockpileItem = stockpileCopy.find((e) => doItemsMatch(e, item));
			if (stockpileItem && stockpileItem.stackSize > 0) {
				borderColors.push(GREEN);
				stockpileItem.stackSize--;
			} else {
				borderColors.push(RED);
			}
		}
		return borderColors;
	}

	function renderEquipment(equipment: ILoadout['loadoutEquipment']) {
		return (
			<div className='loadout-equipment-container-wrapper idlescape-container idlescape-container-light'>
				<div className='loadout-equipment-container'>
					<img src='/images/combat/combat_stick_figure.png' alt='Combat stick figure' />
					{[
						'helm',
						'cape',
						'necklace',
						'arrows',
						'weapon',
						'body',
						'shield',
						'legs',
						'gloves',
						'boots',
						'ring',
						'pickaxe',
						'hatchet',
						'hoe',
						'tacklebox',
						'tome',
						'tongs',
						'ladle',
						'chisel',
					].map((slot) => {
						const equippedItem = equipment.find((e) => itemList[e.itemID]?.equipmentStats?.slot === slot);
						const borderColor = getEquipmentBorder(equippedItem);
						return (
							<LoadoutItem
								key={slot}
								class={`gear-${slot}`}
								item={equippedItem}
								borderColor={borderColor}
								onClick={() => changeIconItemId(equippedItem?.itemID)}
							/>
						);
					})}
				</div>
			</div>
		);
	}

	function changeIconItemId(itemIconId: number | undefined) {
		if (editMode && itemIconId && itemIconId !== selectedLoadoutData.loadoutIconItemId) {
			socket.emit('inventory:saveLoadout', {
				loadoutID: loadoutId,
				saveEquipmentAndInventory: false,
				saveAbilityRotation: false,
				loadoutIconItemId: itemIconId,
			});
		}
	}

	function renderInventory(inventory: ILoadout['loadoutInventory']) {
		const items: React.ReactElement[] = [];

		const borderColors = getFoodBorders(inventory);
		for (const [index, item] of inventory.entries()) {
			items.push(
				<div key={index} className='combat-consumable'>
					<FakeItem
						item={item}
						height='45px'
						width='45px'
						margin='0 auto'
						float='none'
						borderWidth='2px'
						borderStyle='solid'
						borderColor={borderColors[index]}
					/>
				</div>
			);
		}

		for (let i = items.length; i < COMBAT_INVENTORY_SIZE; i++) {
			items.push(
				<div key={i} className='combat-consumable'>
					<div className='combat-empty-slot' />
				</div>
			);
		}
		return (
			<div className='loadout-inventory-container-wrapper idlescape-container idlescape-container-light'>
				<div className='loadout-inventory-container'>{items}</div>
			</div>
		);
	}

	function renderAbilities(abilityIds: ILoadout['loadoutAbilityRotation']) {
		const abilityList = abilityIds.map((abilityId) => masterAbilityList[abilityId]);
		return (
			<Box
				width='100%'
				className='anchor-loadout-combat-abilities idlescape-container thin idlescape-container-light'
			>
				<AbilityList abilities={abilityList} justifyContent='center' columnGap='15px' padding='5px' />
			</Box>
		);
	}

	return (
		<>
			<div className='loadout-equipment-inventory-buttons'>
				<div
					className='loadout-button idlescape-button idlescape-button-green'
					onClick={() => setSelectedContainerTab('equipment')}
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/equipment_icon.png' alt='Equipment' />
				</div>
				<div
					className='loadout-button idlescape-button idlescape-button-blue'
					onClick={() => setSelectedContainerTab('inventory')}
				>
					<img
						className='nav-tab-icon icon-border'
						src='/images/ui/inventory_icon.png'
						alt='Combat inventory'
					/>
				</div>
			</div>

			{selectedContainerTab === 'equipment' && <>{renderEquipment(selectedLoadoutData.loadoutEquipment)}</>}
			{selectedContainerTab === 'inventory' && <>{renderInventory(selectedLoadoutData.loadoutInventory)}</>}

			{renderAbilities(selectedLoadoutData.loadoutAbilityRotation)}
		</>
	);
};

export default LoadoutView;
