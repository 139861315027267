import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React from 'react';
import { socket } from '../../../../services/socket.service';
import { buffStacks, getEnchantmentStrength } from '../../../../utils/itemFunctions';
import { itemList } from '../../../../utils/itemList';
import { EnchantmentData } from '../../EnchantmentData';
import { IDialogProps } from './MainItemDialog';

function ConsumeDialog(props: IDialogProps) {
	const enchantment = props.item.enchantmentID
		? EnchantmentData.findEnchantmentByID(props.item.enchantmentID)
		: undefined;
	const { name: enchanmentName, stackMult } = enchantment ?? { name: undefined, stackMult: undefined };
	const enchantmentStrength = getEnchantmentStrength(props.item, itemList);
	const stacks = buffStacks(props.item.augmentations, props.itemData?.stackMultiplier, stackMult, props.sliderValue);

	function handleEatFood() {
		socket.emit('consumable:consume', {
			inventoryItemID: props.item.id,
			consumeCount: props.sliderValue,
			sourceInventory: props.inventoryName,
		});
		props.onClose();
	}
	return (
		<Modal isOpen={true} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>{props.name}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Are you sure you want to consume {props.sliderValue} {props.name}
					{props.sliderValue > 1 && 's'}?
					{enchanmentName !== undefined && enchantmentStrength !== undefined && (
						<Text as='p'>
							You will gain {stacks}{' '}
							<Text as='span' color='rgb(201, 30, 207)'>
								{enchanmentName} {enchantmentStrength}
							</Text>{' '}
							stacks from consuming this.
						</Text>
					)}
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={handleEatFood}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default ConsumeDialog;
