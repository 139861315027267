import { Box } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IItemData } from '../../../../../game-server/src/modules/items/items.interface';
import { marketplaceItemAtom } from '../../../atoms/marketplaceItemAtom';
import { socket } from '../../../services/socket.service';
import { filterItem } from '../../../utils/helperFunctions';
import { itemList } from '../../../utils/itemList';
import MarketplaceBuyItemList from './MarketplaceBuyItemList';
import { usePlayerField } from '../../../hooks/hooks';
import FakeItem from '../Inventory/FakeItem';
import { marketplaceFilterAtom } from '../../../atoms/marketplaceFilterAtom';

export default function MarketplaceBuy() {
	const filter = useRecoilValue(marketplaceFilterAtom);
	const [currentlyListedItems, setCurrentlyListedItems] = useState<{ itemID: IItemData['id'] }[]>([]);
	const [scrollPos, setScrollPos] = useState(0);
	const playerSettings = usePlayerField('settings');

	const [itemToBuy, setItemToBuy] = useRecoilState(marketplaceItemAtom);

	const overviewRef = useRef<HTMLDivElement>(null);
	const overviewRefHasListener = useRef(false);

	useEffect(() => {
		if (!playerSettings.miscellaneous.showEmptyMarketManifest) {
			socket.emit('marketplace:manifest:get');
			socket.on('marketplace:manifest:send', (data) => {
				setCurrentlyListedItems(data);
			});
			return () => {
				socket.off('marketplace:manifest:send');
			};
		}
	}, []);

	useEffect(() => {
		if (overviewRef.current !== null) {
			if (!overviewRefHasListener.current) {
				overviewRef.current.scrollTop = scrollPos;
				overviewRef.current.addEventListener('scroll', () => {
					if (overviewRef.current) setScrollPos(overviewRef.current.scrollTop);
				});
				overviewRefHasListener.current = true;
			}
		} else {
			overviewRefHasListener.current = false;
		}
	}, [overviewRef.current, itemToBuy]);

	function renderItems() {
		const allItems: { itemID: number }[] = [];
		let i = 0;
		for (const item of Object.values(itemList)) {
			if (!item.tradeable) {
				continue;
			}
			if (playerSettings.miscellaneous.showEmptyMarketManifest) {
				allItems.push({ itemID: item.id });
				continue;
			}
			//Expecting sorted list
			if (currentlyListedItems[i]?.itemID === item.id) {
				allItems.push({ itemID: item.id });
				i++;
			} else if (item.dummy) {
				//Add combined items
				allItems.push({ itemID: item.id });
			}
		}
		const sortedList = allItems.filter((item) => filterItem(item, filter));

		return sortedList.map((item) => (
			<FakeItem key={item.itemID} item={{ itemID: item.itemID }} onClick={() => setItemToBuy(item.itemID)} />
		));
	}
	if (itemToBuy !== null) {
		return <MarketplaceBuyItemList key={itemToBuy} />;
	} else {
		return (
			<Box
				paddingTop='5px'
				className='all-items anchor-buy-all-items'
				overflowY={'auto'}
				height='100%'
				ref={overviewRef}
			>
				{renderItems()}
			</Box>
		);
	}
}
