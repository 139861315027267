import { Box, Flex, Grid } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeInput } from '@idlescape/ui';
import { useEffect, useState } from 'react';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import { IMarketListing } from '../../../../../../game-server/src/repositories/MarketListing.repository';
import { IMarketOffer } from '../../../../../../game-server/src/repositories/MarketOffer.repository';
import { socket } from '../../../../services/socket.service';
import { filterItem, formatNumberToString } from '../../../../utils/helperFunctions';
import { itemAugment, itemEnchant, itemHigherAugment, itemImage } from '../../../../utils/itemHelperFunctions';
import { itemList } from '../../../../utils/itemList';
import ItemTooltip from '../../../game/Tooltips/ItemTooltip';

export default function MarketListings(props: { playerId: number }) {
	const [listingData, setListingData] = useState<IMarketListing[]>([]);
	const [offerData, setOfferData] = useState<IMarketOffer[]>([]);
	const [filter, setFilter] = useState<string>('');

	useEffect(() => {
		socket.emit('marketplace:moderator:get', props.playerId);
		socket.on('moderator:marketplace:get', (data) => {
			setListingData(data.listings);
			setOfferData(data.offers);
		});
		return () => {
			socket.off('moderator:marketplace:get');
		};
	}, []);

	function refreshListing() {
		socket.emit('marketplace:moderator:get', props.playerId);
	}

	function renderAuctions() {
		const filteredList = listingData.filter((auction) => filterItem(auction.item, filter));
		return filteredList.map((auction) => {
			const item = auction.item;
			return (
				<Grid
					key={auction.listingId}
					gridTemplateColumns='repeat(4, 1fr)'
					gridTemplateRows='50px'
					position='relative'
					className='marketplace-table-row'
					alignItems='center'
				>
					<Box>{new Date(auction.listingDate).toLocaleDateString()}</Box>
					<Box position='relative' height='50px' width='50px' margin='auto'>
						{itemImage(itemList[item.itemID])}
						{itemAugment(item)}
						{itemEnchant(item)}
					</Box>
					<Box>{item.stackSize.toLocaleString()}</Box>
					<Box>{auction.price.toLocaleString()}</Box>
					<ItemTooltip item={item} forceDefaultPositions={true} listingDate={auction.listingDate} />
				</Grid>
			);
		});
	}

	function renderOffers() {
		const filteredList = offerData.filter((offer) => {
			const item: TPartialItemWithItemID<IItem> = {
				itemID: offer.itemID,
				augmentations: offer.augmentations ?? undefined,
				enchantmentID: offer.enchantment_id ?? undefined,
				enchantmentStrength: offer.enchantment_strength ?? undefined,
			};
			return filterItem(item, filter);
		});
		return filteredList.map((offer) => {
			const item: TPartialItemWithItemID<IItem> = {
				itemID: offer.itemID,
				augmentations: offer.augmentations ?? undefined,
				enchantmentID: offer.enchantment_id ?? undefined,
				enchantmentStrength: offer.enchantment_strength ?? undefined,
			};
			return (
				<Grid
					key={offer.offerId}
					gridTemplateColumns='repeat(5, 1fr)'
					gridTemplateRows='50px'
					position='relative'
					className='marketplace-table-row'
					alignItems='center'
				>
					<Box>{new Date(offer.offerDate).toLocaleDateString()}</Box>
					<Box position='relative' height='50px' width='50px' margin='auto'>
						{itemImage(itemList[item.itemID])}
						{itemAugment(item)}
						{itemEnchant(item, offer.anyEnchantment)}
						{itemHigherAugment(offer.higherAugments)}
					</Box>
					<Box>
						{offer.leftToPurchase.toLocaleString()}/
						{(offer.purchase_count + offer.leftToPurchase).toLocaleString()}
					</Box>
					<Box>{offer.price.toLocaleString()}</Box>
					<Box>{formatNumberToString(offer.price * offer.leftToPurchase)}</Box>
					<ItemTooltip item={item} forceDefaultPositions={true} listingDate={offer.offerDate} />
				</Grid>
			);
		});
	}

	function changeSearch(input: string) {
		setFilter(input);
	}

	return (
		<div className='marketplace-container'>
			<Flex>
				<IdlescapeButton variant='green' size='medium' onClick={refreshListing}>
					&#8635; REFRESH
				</IdlescapeButton>
				<IdlescapeInput variant='search' setValue={changeSearch} value={filter} type='search' width='100%' />
			</Flex>
			<Box className='marketplace-content marketplace-sell-container' textAlign='center'>
				<div className='marketplace-table'>
					Sell Listings
					<Grid gridTemplateColumns='repeat(4, 1fr)'>
						<div>Date</div>
						<div>Item</div>
						<div>Amount</div>
						<div>Price</div>
					</Grid>
					{renderAuctions()}
				</div>
				<div className='marketplace-table anchor-sell-buy-orders'>
					Buy Offers
					<Grid gridTemplateColumns='repeat(5, 1fr)'>
						<div>Date</div>
						<div>Item</div>
						<div>Amount</div>
						<div>Price Per</div>
						<div>Gold on Hold</div>
					</Grid>
					{renderOffers()}
				</div>
			</Box>
		</div>
	);
}
