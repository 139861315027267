import { BaseTooltip } from './BaseTooltip';
import { IItem, IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { TEnchant, THealingStats, TTooltipStats } from '../Data/TooltipData';
import { enchantmentsList } from '../../../../utils/enchantmentList';
import { stringCapitalize } from '../../../../utils/StringFormatter';
import { buffStacks, getHealingStats } from '../../../../utils/itemFunctions';
import { cookingList } from '../../../../utils/cookingList';
import { FOOD_DEFAULT_ENCHANT_STRENGTH } from '../../../../utils/constantsCollection';
import { EnchantmentData } from '../../EnchantmentData';

export class FoodTooltip extends BaseTooltip {
	protected getItemType(itemResource: IItemData): string {
		const types: string[] = [];
		if (itemResource.tags?.includes('consumable')) types.push('Consumable');
		const cookingData = cookingList[itemResource.id];
		if (cookingData?.ingredientTags) types.push('Ingredient');
		return types.join(', ');
	}

	protected getUse(item: IItem, itemResource: IItemData): string {
		if (!itemResource.healing) return '';

		const augments = item.augmentations ?? 0;
		return `Heals ${itemResource.healing.hp * (1 + augments)} hp`;
	}
	protected getHealing(item: IItem, itemResource: IItemData): Record<THealingStats, number> | undefined {
		return getHealingStats(item, itemResource, 0);
	}

	protected getStats(
		item: IItem,
		itemResource: IItemData
	): Partial<Record<TTooltipStats, Record<string, string | number>>> | undefined {
		const ingredientStats: Record<string, string | number> = {};

		const cookingData = cookingList[itemResource.id];
		if (!cookingData) return { foodStats: {} };
		if (cookingData.ingredientTags) {
			ingredientStats['food.tags'] = cookingData.ingredientTags.map((tag) => stringCapitalize(tag)).join(', ');
		}
		if (cookingData.size) ingredientStats['food.size'] = cookingData.size;
		if (cookingData.alchemySize) ingredientStats['food.alchemySize'] = cookingData.alchemySize;
		if (cookingData.difficulty) {
			ingredientStats['food.difficulty'] = cookingData.difficulty;
		}
		if (cookingData.cookingEnchantment) {
			ingredientStats['food.cookingEnchantment'] = enchantmentsList[cookingData.cookingEnchantment]?.name;
		}

		if (cookingData.alchemyEnchantment) {
			ingredientStats['food.alchemyEnchantment'] = enchantmentsList[cookingData.alchemyEnchantment]?.name;
		}

		return { foodStats: ingredientStats };
	}

	protected getEnchant(item: IItem, itemResource: IItemData): Record<TEnchant, string | number> | undefined {
		if (!item.enchantmentID) return undefined;

		const enchantment = enchantmentsList[item.enchantmentID];
		const enchantmentStrength = itemResource.stackStrength || FOOD_DEFAULT_ENCHANT_STRENGTH;
		return {
			enchantId: enchantment.id,
			enchantName: enchantment.name,
			enchantStrength: enchantmentStrength,
			enchantDescription: enchantment.getTooltip(enchantmentStrength, enchantment.strengthPerLevel),
			enchantType: enchantment.relatedSkills[0],
		};
	}

	protected getFoodEnchant(
		item: IItem,
		itemResource: IItemData
	): Record<string, string | number | boolean> | undefined {
		if (!item.enchantmentID) return undefined;

		const enchantment = this.getEnchant(item, itemResource);
		if (!enchantment) return undefined;

		const foodEnchant: Record<string, string | number | boolean> = { ...enchantment };

		const enchantmentData = enchantmentsList[item.enchantmentID];
		foodEnchant.combat_only = EnchantmentData.isCombatEnchantment(item.enchantmentID);

		foodEnchant.stacks = buffStacks(item.augmentations, itemResource.stackMultiplier, enchantmentData.stackMult);

		return foodEnchant;
	}
}
