import {
	Box,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Textarea,
	TextareaProps,
} from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeSelect } from '@idlescape/ui';
import React, { useState } from 'react';
import { TFeedbackType } from '../../../../game-server/src/modules/discord-manager/discordManager';
import { socket } from '../../services/socket.service';
import { itemList } from '../../utils/itemList';
import { itemsIds } from '../../utils/lookup-dictionaries/lookupItemList';

const INPUT_CHARACTER_LIMIT = 100;
const TEXTAREA_CHARACTER_LIMIT = 4000;

const TextareaNoHorizontalResize = (props: TextareaProps) => <Textarea {...props} resize='vertical' />;

interface IFeedbackCategory {
	id: TFeedbackType;
	label: string;
	image: string | undefined;
	inputs: {
		label: string | React.ReactElement;
		placeholder?: string;
		internalName?: string;
		type: string;
		required: boolean;
	}[];
	additionalInfo?: string;
}

const options: { [key: string]: IFeedbackCategory } = {
	Feedback: {
		id: 'Feedback',
		label: 'General Feedback',
		image: 'images/misc/notepad_icon.png',
		inputs: [{ label: 'Message', type: 'textarea', required: true }],
	},
	Bug: {
		id: 'Bug',
		label: 'Bug',
		image: itemList[itemsIds.glowbug_bait].itemImage,
		inputs: [
			{
				label: 'Describe the bug',
				placeholder: 'Describe the bug',
				type: 'textarea',
				required: true,
			},
			{
				label: 'If you can reproduce the bug, please describe:',
				placeholder: 'Reproduce the bug',
				type: 'textarea',
				required: false,
			},
		],
	},
	Key: {
		id: 'Key',
		label: 'Key refund',
		image: itemList[itemsIds.dwarven_research_key].itemImage,
		inputs: [
			{ label: 'Party members', type: 'textarea', required: true },
			{ label: 'Key name', type: 'input', required: true },
			{ label: 'Refund reason', type: 'textarea', required: true },
			{ label: 'Bug description', type: 'textarea', required: true },
		],
		additionalInfo: `Please make sure that you spelled all the usernames correctly and that you are the only one submitting a
				refund request for this run. If you or someone else in your group are caught sending separate refund
				requests for the same run, you will not receive a refund.`,
	},
	Appeal: {
		id: 'Appeal',
		label: 'Mute/Tradeban appeal',
		image: itemList[itemsIds.ban_hamer].itemIcon,
		inputs: [
			{ label: 'Mute reason', type: 'textarea', required: true },
			{ label: 'Appeal reason', type: 'textarea', required: true },
		],
	},
	Affixes: {
		id: 'Affixes',
		label: 'Affix Feedback',
		image: itemList[itemsIds.mythical_runic_dust].itemImage,
		inputs: [
			{
				label: (
					<>
						We are looking for feedback on the affix system. It would help a lot if you could spare a few
						minutes and share your feedback. In case you didn&apos;t play season 1, check out the{' '}
						<a href='https://www.idlescape.com/news/beta-0-17-0' target='_blank' rel='noreferrer'>
							initial patchnotes
						</a>
						. Note that there are affixes for combat and skilling by now.
						<br />
						Have you played on season 1 and used affixes?
					</>
				),
				placeholder: 'yes/no',
				internalName: 'Have you played on season 1 and used affixes?',
				type: 'input',
				required: true,
			},
			{
				label: 'What do you think about affixes in general?',
				placeholder: 'General thoughts',
				type: 'textarea',
				required: true,
			},
			{
				label: (
					<>
						Some people have raised concerns that affixes will add too much complexity to the game, while
						others believe that they are intuitive enough for everyone to understand. What do you think?
					</>
				),
				placeholder: 'Complexity thoughts',
				type: 'textarea',
				required: false,
			},
			{
				label: (
					<>
						Affixes on season 1 were intentionally overpowered. When they hit mainscape, they will come in a
						nerfed form. This will mean less slots per item and smaller effects. There might also be changes
						to which affix can go on which slot.
						<br />
						What do you think about these adjustments? Would you like to see any other changes?
					</>
				),
				placeholder: 'Adjustment thoughts',
				type: 'textarea',
				required: false,
			},
		],
	},
};

export default function NavFeedback(props: { open: boolean; close: () => void }) {
	const [category, setCategory] = useState<string | undefined>(undefined);
	const [messageParts, setMessageParts] = useState<string[]>([]);
	const [discordName, setDiscordName] = useState('');
	const [missingInput, setMissingInput] = useState(false);

	const option = category ? options[category] : undefined;

	function handleSendFeedback() {
		if (option === undefined) return;
		if (messageParts.some((part, index) => part.length === 0 && option.inputs[index].required)) {
			setMissingInput(true);
			setTimeout(() => setMissingInput(false), 3000);
			return;
		}
		const message = messageParts.reduce((acc, part, index) => {
			const input = option.inputs[index];
			const label = input.internalName ?? input.placeholder ?? input.label;
			return acc + `**${label}:** ${part}\n`;
		}, '');
		const messageWithDiscordName = discordName.length > 0 ? `${message}**Discord name:** ${discordName}` : message;
		socket.emit('feedback:send', { category: option.id, message: messageWithDiscordName });
		setCategory(undefined);
		setMessageParts([]);
		props.close();
	}

	function handleChangeCategory(id: string) {
		setCategory(id);
		setMessageParts(options[id].inputs.map(() => ''));
	}

	function renderInputs() {
		if (!option) return null;
		return (
			<>
				{option.inputs.map((input, index) => {
					const inputLength = messageParts[index].length;
					const limit = input.type === 'input' ? INPUT_CHARACTER_LIMIT : TEXTAREA_CHARACTER_LIMIT;
					const InputComponent = input.type === 'input' ? Input : TextareaNoHorizontalResize;
					return (
						<Box key={index} marginBottom='10px'>
							<Text>
								{input.label}
								{input.required && '*'}
							</Text>
							<InputComponent
								placeholder={input.placeholder ?? input.label.toString()}
								value={messageParts[index]}
								onChange={(e: React.ChangeEvent<HTMLTextAreaElement & HTMLInputElement>) => {
									const value = e.target.value;
									if (value.length <= limit) {
										setMessageParts((prev) => {
											const newParts = [...prev];
											newParts[index] = value;
											return newParts;
										});
									}
								}}
								style={missingInputStyle(input.required && missingInput, inputLength)}
							/>
							{characterLimit(inputLength, limit)}
						</Box>
					);
				})}
				{option.additionalInfo && <Text>{option.additionalInfo}</Text>}
			</>
		);
	}

	return (
		<Modal isOpen={props.open} onClose={props.close} size='xl'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Feedback form</ModalHeader>
				<ModalBody>
					<Text>Select category:</Text>
					<IdlescapeSelect options={options} selected={category} select={handleChangeCategory} />
					{renderInputs()}
					{category && (
						<>
							<Text style={{ clear: 'both' }}>
								If you want to get contacted back and are on the{' '}
								<a href='https://idlescape.com/discord' target='_blank' rel='noreferrer'>
									Idlescape discord
								</a>
								, please enter your discord name. If you are not on the discord, a moderator will
								contact you ingame.
							</Text>
							<Input
								value={discordName}
								onChange={(e) => setDiscordName(e.target.value)}
								placeholder='Discord name'
							/>
							<Text color='lightgray' fontStyle='oblique'>
								Required fields are marked with *
							</Text>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={props.close}>
						Close
					</IdlescapeButton>
					{category && (
						<IdlescapeButton variant='green' onClick={handleSendFeedback}>
							Submit
						</IdlescapeButton>
					)}
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

function characterLimit(length: number, limit: number) {
	return (
		<Box float='right' color='lightgray' fontStyle='oblique'>
			{length}/{limit} characters
		</Box>
	);
}

function missingInputStyle(missingInput: boolean, inputLength: number) {
	return missingInput && inputLength === 0 ? { border: '1px solid red', boxShadow: '0 0 10px red' } : undefined;
}
