import './Runecrafting.css';
import RunecraftingRunes from './RunecraftingRunes';
import RunecraftingCloth from './RunecraftingCloth';
import RunecraftingAffix from './Affixes/RunecraftingAffix';
import React from 'react';
import { useRecoilState } from 'recoil';
import { runecraftingTabAtom } from '../../../../atoms/runecraftingTabAtom';

export default function Runecrafting() {
	const [tab, setTab] = useRecoilState(runecraftingTabAtom);
	return (
		<div className='runecrafting-container'>
			<div className={'runecrafting-tabs'}>
				<div onClick={() => setTab('rune')} className={tab === 'rune' ? 'runecrafting-tab-selected' : ''}>
					<img
						src='/images/runecrafting/RuneCraftingIcon.png'
						alt='rune logo'
						className='enchanting-tab-icon'
					></img>
					<span>Runecrafting</span>
				</div>
				<div onClick={() => setTab('cloth')} className={tab === 'cloth' ? 'runecrafting-tab-selected' : ''}>
					<img
						src='/images/combat/materials/imbued_cloth.png'
						alt='cloth logo'
						className='enchanting-tab-icon'
					></img>
					<span>Cloth Weaving</span>
				</div>
				<div onClick={() => setTab('affix')} className={tab === 'affix' ? 'runecrafting-tab-selected' : ''}>
					<img
						src='/images/runecrafting/dust/runic_dust_legendary.png'
						alt='affix logo'
						className='enchanting-tab-icon'
					></img>
					<span>Affix Warding</span>
				</div>
			</div>
			{tab === 'rune' && <RunecraftingRunes />}
			{tab === 'cloth' && <RunecraftingCloth />}
			{tab === 'affix' && <RunecraftingAffix />}
		</div>
	);
}
