import { io, Socket } from 'socket.io-client';
import { IClientToServerEvents, IServerToClientEvents } from '../../../game-server/src/modules/io/io.events';

export let socket: Socket<IServerToClientEvents, IClientToServerEvents>;

export const initiateSocketConnection = () => {
	const CONFIG = {
		query: { token: localStorage.jwtToken, characterID: sessionStorage.characterID },
		reconnection: true,
		forceNew: true,
	};
	socket = io(CONFIG);
};

export const getSocket = () => {
	return socket;
};

/* export const socket; */
