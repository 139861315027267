import { IdlescapeButton, IdlescapeContainer, IdlescapeTooltip, IdlescapeWrappingTooltip } from '@idlescape/ui';
import Slider from '@material-ui/core/Slider';
import { socket } from '../../../services/socket.service';
import React, { useEffect, useState } from 'react';
import { usePlayerField } from '../../../hooks/hooks';
import { locations } from '../../../utils/locationList';
import LoadoutView from './Loadout/LoadoutView';
import Bestiary from './Bestiary/Bestiary';
import { Box, Flex, Heading, HStack, Switch, Text } from '@chakra-ui/react';
import { AiFillCaretLeft, AiFillCaretRight, AiFillCaretUp } from 'react-icons/ai';
import { cloneDeep } from 'lodash';
import CombatGroupSize from './CombatGroupSize';
import ItemSelector from '../Inventory/ItemSelector';
import { IItem } from '../../../../../game-server/src/modules/items/items.interface';
import LoadoutSelector from './Loadout/LoadoutSelector';
import { socketEmitDebounced } from '../../../utils/helperFunctions';
import ActionQueueButton from '../ActionQueue/ActionQueueButton';
import { BASE_DIFFICULTY, STARTING_DIFFICULTY_MULT, COMBAT_ZONE_SETTINGS } from '../../../utils/constantsCollection';

export const CombatLocationInfo = (props: {
	locationId: number;
	eliteChallenge: number;
	back: CallableFunction;
	prev: CallableFunction;
	next: CallableFunction;
	allowPrev: boolean;
	allowNext: boolean;
}) => {
	const location = locations[props.locationId];
	const requiredItemId = location?.relatedKey;

	const loadouts = usePlayerField('loadouts');
	const relatedLoadout = loadouts.find((loadout) => loadout.loadoutRelatedZones.includes(props.locationId));
	const settings = usePlayerField('settings');
	const league = usePlayerField('league');
	const stockpile = usePlayerField('stockpile');

	const thisPlayerMaxStartingDiff = Math.floor(props.eliteChallenge * STARTING_DIFFICULTY_MULT);
	const thisPlayerMaxMaxDiff = BASE_DIFFICULTY + props.eliteChallenge;

	const globalAutoEatThresholdSetting = settings.combat.autoEatThreshold ?? 100;
	const globalStartDifficultySetting = Math.min(settings.combat.startingDifficulty ?? 0, thisPlayerMaxStartingDiff);
	const globalMaxDifficultySetting = Math.min(settings.combat.maxDifficulty ?? 30, thisPlayerMaxMaxDiff);
	const localAutoEatThresholdSetting = settings.combat.zoneSpecificSettings[props.locationId]?.autoEatThreshold ?? -1;
	const localStartDifficultySetting =
		settings.combat.zoneSpecificSettings[props.locationId]?.startingDifficulty ?? -1;
	const localMaxDifficultySetting = settings.combat.zoneSpecificSettings[props.locationId]?.maxDifficulty ?? -1;
	const localQuickStartSetting = settings.combat.zoneSpecificSettings[props.locationId]?.quickStart ?? false;
	const [autoEatThreshold, setAutoEatThreshold] = useState(localAutoEatThresholdSetting);
	const [startDifficulty, setStartDifficulty] = useState(localStartDifficultySetting);
	const [maxDifficulty, setMaxDifficulty] = useState(localMaxDifficultySetting);
	const [quickStart, setQuickStart] = useState(localQuickStartSetting);

	const dungeonRequeue = settings.combat.dungeonRequeue ?? false;
	const dungeonRequeueIgnoreAug = settings.combat.dungeonRequeueIgnoreAug ?? false;

	let recommendedLevel = '1+';
	let recommendedStyle = 'Any';
	let tooltipString = 'A Combat Zone';
	if (location?.extraTooltipInfo) tooltipString = location.extraTooltipInfo;
	const delineated = tooltipString.split('|');
	if (delineated.length > 1) {
		recommendedLevel = delineated[0];
		recommendedStyle = delineated[1];
		tooltipString = delineated[2];
	}

	const imageStyle = location.locationImageStyle ? cloneDeep(location.locationImageStyle) : {};
	let backgroundImage = `${location.locationImage}`;
	if (imageStyle.linearGradient) {
		backgroundImage = `linear-gradient(${imageStyle.linearGradient}), url(${location.locationImage})`;
		delete imageStyle.linearGradient;
	}

	const stockpileItems = stockpile
		.filter((item) => item.itemID === requiredItemId)
		.sort((a, b) => {
			if (!a.augmentations) return -1;
			if (!b.augmentations) return 1;
			return a.augmentations - b.augmentations;
		});
	const [selectedAccessItemId, setSelectedAccessItemId] = useState<number>(
		dungeonRequeue && dungeonRequeueIgnoreAug && stockpileItems[0] ? stockpileItems[0].id : -1
	);

	useEffect(() => {
		if (!requiredItemId) return;
		if (dungeonRequeue && dungeonRequeueIgnoreAug && stockpileItems[0]) {
			setSelectedAccessItemId(stockpileItems[0].id);
		} else if (selectedAccessItemId !== -1) {
			const item = stockpileItems.find((item) => item.id === selectedAccessItemId);
			if (!item) {
				setSelectedAccessItemId(-1);
			}
		}
	}, [stockpileItems]);

	function handleAutoeat(event: React.ChangeEvent<object>, values: number | number[]) {
		const value = typeof values === 'number' ? values : values[0];
		setAutoEatThreshold(value);
		if (value === -1 && settings.combat.zoneSpecificSettings[props.locationId]) {
			settings.combat.zoneSpecificSettings[props.locationId].autoEatThreshold = undefined;
		} else {
			settings.combat.zoneSpecificSettings[props.locationId] = {
				...settings.combat.zoneSpecificSettings[props.locationId],
				autoEatThreshold: value,
			};
		}
		emitUpdate('autoEatThreshold', value === -1 ? undefined : value);
	}

	function handleStartDifficulty(event: React.ChangeEvent<object>, values: number | number[]) {
		const value = typeof values === 'number' ? values : values[0];
		setStartDifficulty(value);
		if (value === -1 && settings.combat.zoneSpecificSettings[props.locationId]) {
			settings.combat.zoneSpecificSettings[props.locationId].startingDifficulty = undefined;
		} else {
			settings.combat.zoneSpecificSettings[props.locationId] = {
				...settings.combat.zoneSpecificSettings[props.locationId],
				startingDifficulty: value,
			};
		}
		emitUpdate('startingDifficulty', value === -1 ? undefined : value);
	}

	function handleMaxDifficulty(event: React.ChangeEvent<object>, values: number | number[]) {
		const value = typeof values === 'number' ? values : values[0];
		setMaxDifficulty(value);
		if (value === -1 && settings.combat.zoneSpecificSettings[props.locationId]) {
			settings.combat.zoneSpecificSettings[props.locationId].maxDifficulty = undefined;
		} else {
			settings.combat.zoneSpecificSettings[props.locationId] = {
				...settings.combat.zoneSpecificSettings[props.locationId],
				maxDifficulty: value,
			};
		}
		emitUpdate('maxDifficulty', value === -1 ? undefined : value);
	}

	function handleQuickStart(value: boolean) {
		setQuickStart(value);
		if (!value && settings.combat.zoneSpecificSettings[props.locationId]) {
			settings.combat.zoneSpecificSettings[props.locationId].quickStart = undefined;
		} else {
			settings.combat.zoneSpecificSettings[props.locationId] = {
				...settings.combat.zoneSpecificSettings[props.locationId],
				quickStart: value,
			};
		}
		emitUpdate('quickStart', value ?? undefined);
	}

	function handlePrevious() {
		props.prev();
	}

	function handleNext() {
		props.next();
	}

	function emitUpdate(setting: keyof typeof COMBAT_ZONE_SETTINGS, value: number | boolean | undefined) {
		socketEmitDebounced('settings:zoneSpecificSettings', {
			location: props.locationId,
			setting,
			value,
		});
	}

	function handleSelectedAccessItem(item: IItem) {
		setSelectedAccessItemId(item.id);
	}

	function handleDungeonRequeue(value: boolean) {
		if (dungeonRequeueIgnoreAug && selectedAccessItemId === -1 && stockpileItems[0]) {
			setSelectedAccessItemId(stockpileItems[0].id);
		}
		socket.emit('settings:update', {
			value: value,
			path: 'combat.dungeonRequeue',
		});
	}

	function handleDungeonRequeueIgnoreAug(value: boolean) {
		if (selectedAccessItemId === -1 && stockpileItems[0]) {
			setSelectedAccessItemId(stockpileItems[0].id);
		}
		socket.emit('settings:update', {
			value: value,
			path: 'combat.dungeonRequeueIgnoreAug',
		});
	}

	function startCombat() {
		if (requiredItemId) {
			if (selectedAccessItemId === null) {
				return;
			}
			socket.emit('consumable:key', { inventoryItemID: selectedAccessItemId });
		} else {
			socket.emit('action:start', { action: 'combat', location: props.locationId });
		}
	}
	function queueButton() {
		if (location.relatedKey) return null;
		return <ActionQueueButton action='combat' location={props.locationId} variant='orange' />;
	}

	function renderAccessItemSelection() {
		return (
			<>
				<Box>
					<Heading size='md'>Select Item to Use</Heading>
					{dungeonRequeue && dungeonRequeueIgnoreAug ? (
						<ItemSelector
							items={stockpileItems}
							selectedIds={stockpileItems.map((item) => item.id)}
							select={() => undefined}
							multiSelection={true}
						/>
					) : (
						<ItemSelector
							items={stockpileItems}
							selectedId={selectedAccessItemId}
							select={handleSelectedAccessItem}
							multiSelection={false}
						/>
					)}
				</Box>
				<Flex>
					<IdlescapeWrappingTooltip
						content={
							dungeonRequeue
								? 'With this active, you will use all keys of the same augment level. If you want to stop earlier without interrupting a run, move the keys to your vault.'
								: ''
						}
					>
						<Box marginRight='10px'>
							<Box marginRight='5px'>Dungeon queue</Box>
							<Switch
								isChecked={dungeonRequeue}
								onChange={(e) => handleDungeonRequeue(e.target.checked)}
							/>
						</Box>
					</IdlescapeWrappingTooltip>
					<IdlescapeWrappingTooltip
						content={dungeonRequeueIgnoreAug ? 'Use all keys regardless of augment levels.' : ''}
					>
						<Box>
							<Box marginRight='5px'>Queue all</Box>
							<Switch
								isChecked={dungeonRequeueIgnoreAug}
								onChange={(e) => handleDungeonRequeueIgnoreAug(e.target.checked)}
								isDisabled={!dungeonRequeue}
							/>
						</Box>
					</IdlescapeWrappingTooltip>
				</Flex>
			</>
		);
	}

	function renderDifficultySliders() {
		return (
			<>
				<div>Difficulty</div>
				<div>
					Start:{' '}
					{localStartDifficultySetting !== -1
						? localStartDifficultySetting
						: `Using Global (${globalStartDifficultySetting})`}
				</div>
				<Slider
					value={startDifficulty}
					min={-1}
					max={thisPlayerMaxStartingDiff}
					onChange={handleStartDifficulty}
				/>
				<div>
					Maximum:{' '}
					{localMaxDifficultySetting !== -1
						? localMaxDifficultySetting
						: `Using Global (${globalMaxDifficultySetting})`}
				</div>
				<Slider value={maxDifficulty} min={-1} max={thisPlayerMaxMaxDiff} onChange={handleMaxDifficulty} />
				<IdlescapeWrappingTooltip content='Shows a "Quick Start" button in the location list. Save one click!'>
					<Flex gap='4px' marginY='4px'>
						<div>Quick Start: </div>
						<Switch isChecked={quickStart} onChange={(e) => handleQuickStart(e.target.checked)} />
					</Flex>
				</IdlescapeWrappingTooltip>
			</>
		);
	}

	function readyToStart() {
		return !(requiredItemId && selectedAccessItemId === -1);
	}

	return (
		<IdlescapeContainer variant='dark'>
			<HStack position='absolute'>
				<IdlescapeButton
					variant='blue'
					leftIcon={<AiFillCaretUp />}
					position='relative'
					onClick={() => props.back()}
				>
					Back
				</IdlescapeButton>
				<IdlescapeButton
					variant={props.allowPrev ? 'blue' : 'disabled'}
					leftIcon={<AiFillCaretLeft />}
					position='relative'
					onClick={() => handlePrevious()}
				>
					Previous
				</IdlescapeButton>
				<IdlescapeButton
					variant={props.allowNext ? 'blue' : 'disabled'}
					rightIcon={<AiFillCaretRight />}
					position='relative'
					onClick={() => handleNext()}
				>
					Next
				</IdlescapeButton>
			</HStack>
			<Flex direction='column' alignItems='center' padding='5px'>
				<Heading textAlign='center'>{location.name}</Heading>
				<div>
					<IdlescapeButton
						margin='5px auto'
						variant={readyToStart() ? 'green' : 'disabled'}
						size={'large'}
						onClick={startCombat}
					>
						Start Combat
						{!readyToStart() && (
							<IdlescapeTooltip>
								You need to select the item you want to use, before combat can start.
							</IdlescapeTooltip>
						)}
					</IdlescapeButton>
					{queueButton()}
				</div>
				<Text maxWidth='max(300px, 80%)' margin='auto' align='center' fontStyle='italic'>
					{tooltipString}
				</Text>
			</Flex>
			<CombatGroupSize
				locationId={props.locationId}
				showPopup={true}
				style={{ position: 'absolute', top: '30px', right: '30px' }}
			/>
			<Flex wrap='wrap' justifyContent='center' gap='5px'>
				<Flex minWidth='calc((100% - 2*5px)*7/20)' flex='1 0 300px' flexDirection='column' alignItems='center'>
					<Heading textAlign='center'>Connected Loadout</Heading>
					<LoadoutSelector locationId={props.locationId} />
					<LoadoutView loadoutId={relatedLoadout?.loadoutId || 0} />
				</Flex>
				<Flex minWidth='calc((100% - 2*5px)*6/20)' flex='1 0 300px' direction='column'>
					<Box>
						{requiredItemId ? renderAccessItemSelection() : renderDifficultySliders()}
						<div>
							Autoeat Threshold:{' '}
							{localAutoEatThresholdSetting !== -1
								? `${localAutoEatThresholdSetting}%`
								: `Using Global (${globalAutoEatThresholdSetting}%)`}
						</div>
						<Slider value={autoEatThreshold} min={-1} max={100} onChange={handleAutoeat} />
					</Box>
					<Box>
						<div>Recommendations</div>
						<div>Level: {recommendedLevel}</div>
						<div>Style: {recommendedStyle}</div>
					</Box>
					<Box className='combat-zone' backgroundImage={backgroundImage} {...imageStyle} />
				</Flex>
				<Bestiary
					locationId={props.locationId}
					league={league}
					minWidth='calc((100% - 2*5px)*7/20)'
					flex='1 0 300px'
				/>
			</Flex>
			{/* This was to test the recommend thing in console */}
			{/* <LoadoutRecommendation locationId={props.locationId}></LoadoutRecommendation> */}
		</IdlescapeContainer>
	);
};
