import { IEnchantmentData, TRelatedSkill } from '../../../../game-server/src/modules/enchantment/enchantment.interface';
import { leagueList } from '../../utils/leagueList';
import { enchantmentsList } from '../../utils/enchantmentList';
import { PlayerData } from '../../../../game-server/src/modules/player/PlayerData';
import { globalBuffApplies } from '../../utils/leagueFunctions';
import { enchantmentsIds } from '../../utils/lookup-dictionaries/lookupEnchantmentList';
import { GATHERING_SKILLS, PRODUCTION_SKILLS } from '../../utils/constantsCollection';
import { TSkillTab } from '../../../../game-server/src/Atypes/Skills';
import { IGlobals } from '../../../../game-server/src/modules/globals/Globals';
import { maxNormalLevel } from '../../utils/experienceFunctions';

export class EnchantmentData {
	static combatSkills: TRelatedSkill[] = [
		'combat',
		'attack',
		'constitution',
		'defense',
		'strength',
		'range',
		'magic',
	];

	static getEnchantmentStrength(
		enchantmentID: IEnchantmentData['id'],
		player: PlayerData,
		globals: IGlobals,
		skillName?: TSkillTab
	): number {
		const enchantment = this.findEnchantmentByID(enchantmentID);
		if (!enchantment) {
			console.log(`Enchantment ${enchantmentID} not found!!`);
			return 0;
		}
		const blacklistedBuffs = player.settings.miscellaneous.blacklistedBuffs;
		const leagueData = leagueList[player.league];
		const masteryLevel = maxNormalLevel(leagueData?.rules.expFormula);

		let strength = 0;

		// enchantments
		if (player.enchantments) {
			for (const activeEnchantment of player.enchantments) {
				if (activeEnchantment.id === enchantment.id) {
					strength += activeEnchantment.strength * enchantment.strengthPerLevel;
				}
			}
		}

		// buffs, can be blacklisted
		if (!blacklistedBuffs.includes(enchantment.id)) {
			let highestStrength = 0;
			for (let i = 0; i < player.buffs.length; i += 1) {
				if (player.buffs[i].enchantmentID === enchantment.id) {
					if (player.buffs[i].enchantmentStrength > highestStrength) {
						highestStrength = player.buffs[i].enchantmentStrength;
					}
				}
			}
			strength += highestStrength * enchantment.strengthPerLevel;

			if (globals && globals.buffs) {
				const buffs = globals.buffs.filter((buff) => {
					if (buff.enchantmentID !== enchantment.id || !globalBuffApplies(buff, leagueList[player.league])) {
						return false;
					}
					if (
						buff.source === 'shrine' &&
						buff.extraInfo?.shrineIndex !== undefined &&
						buff.extraInfo.shrineIndex > player.shrineContribution.progress
					) {
						return false;
					}
					return true;
				});
				for (const buff of buffs) {
					strength += buff.enchantmentStrength * enchantment.strengthPerLevel;
				}
			}
		}

		if (leagueList[player.league].rules.leagueBuffs) {
			const buffs = leagueList[player.league].rules.leagueBuffs;
			if (buffs) {
				for (let i = 0; i < buffs.length; i += 1) {
					if (buffs[i].id === enchantment.id) {
						strength += buffs[i].strength * enchantment.strengthPerLevel;
					}
				}
			}
		}

		// If the enchantment is haste, we also need to check empowered haste
		if (enchantmentID === enchantmentsIds.haste) {
			const empoweredHasteStrength = this.getEnchantmentStrength(
				enchantmentsIds.empowered_haste,
				player,
				globals
			);
			strength += empoweredHasteStrength;
		}

		if (enchantment.strengthCap && strength > enchantment.strengthCap) {
			strength = enchantment.strengthCap;
		}

		// Affix handling, woo
		if (skillName && enchantmentID === enchantmentsIds.haste && player.affixes) {
			if (GATHERING_SKILLS.includes(skillName)) {
				// gathering haste
				strength += player.affixes.find((affix) => affix.path === 'gathering.haste')?.value ?? 0;
			}
			if (PRODUCTION_SKILLS.includes(skillName)) {
				// production haste
				strength += player.affixes.find((affix) => affix.path === 'production.haste')?.value ?? 0;
			}
		}

		// Check if mastery for scholar
		if (enchantmentID === enchantmentsIds.scholar && skillName) {
			const skillLevel = player.skills[skillName].level;
			if (skillLevel < masteryLevel) {
				strength = 0;
			}
		}

		return strength;
	}

	static getTooltip(enchantmentID: number, enchantmentStrength: number) {
		const enchantment = this.findEnchantmentByID(enchantmentID);
		return enchantment.getTooltip(enchantmentStrength, enchantment.strengthPerLevel);
	}

	static findEnchantmentByID(id: number | string): IEnchantmentData {
		const enchantId = Number(id);
		if (enchantmentsList[enchantId]) {
			return enchantmentsList[enchantId];
		}
		throw new Error();
	}

	static isCombatEnchantment(enchantmentID: IEnchantmentData['id']): boolean {
		return this.findEnchantmentByID(enchantmentID).relatedSkills.some((skill) => this.combatSkills.includes(skill));
	}
}
