import React from 'react';
import ReactSlider from 'react-slider';
import { useRecoilState, useRecoilValue } from 'recoil';
import { smithingActiveForgeAtom } from '../../../../atoms/smithingActiveForgeAtom';
import { smithingIntensitySelector } from '../../../../atoms/smithingIntensityAtom';
import { ProgressBar } from '../../../layout/UI/ProgressBar';
import { forges } from '../../../../utils/forgeList';
import { enchantmentsIds } from '../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { usePlayerEnchantmentStrength, usePlayerField } from '../../../../hooks/hooks';

export default function SmithingIntensity() {
	const [intensity, setIntensity] = useRecoilState(smithingIntensitySelector);
	const activeForgeID = useRecoilValue(smithingActiveForgeAtom);
	const forge = forges[activeForgeID];

	const actionQueue = usePlayerField('actionQueue');
	const metallurgyStrength = usePlayerEnchantmentStrength(enchantmentsIds.metallurgy, 'smithing');
	const moltenMetalStrength = usePlayerEnchantmentStrength(enchantmentsIds.molten_metal, 'smithing');
	const moltenMetalBonus = activeForgeID === 3 ? moltenMetalStrength : 0;
	const maxForgeIntensity = Math.floor(forge.forgeMaxIntensity + metallurgyStrength + moltenMetalBonus);

	const isSmithing = actionQueue?.actionType === 'Action-Smithing';

	return (
		<div className='smithing-intensity'>
			<ReactSlider
				className='smithing-intensity-slider'
				thumbClassName='smithing-intensity-slider-thumb'
				trackClassName='smithing-intensity-slider-track'
				min={0}
				max={maxForgeIntensity}
				value={[1, intensity]}
				onChange={(value) => {
					if (!isSmithing) {
						setIntensity(value[1]);
					}
				}}
				renderThumb={(props) => <div {...props}>{intensity}</div>}
			/>
			<ProgressBar value={intensity} max={maxForgeIntensity} theme='intensity'></ProgressBar>
		</div>
	);
}
