import {
	Box,
	Flex,
	Grid,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { itemList } from '../../../utils/itemList';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';
import { SellMarketplaceDialogue } from '../../layout/dialogs/SellMarketplaceDialogue';
import { BuyMarketplaceDialogue } from '../../layout/dialogs/BuyMarketplaceDialogue';
import ItemTooltip from '../Tooltips/ItemTooltip';
import { IMarketListingWithStackSize } from './MarketplaceBuyItemList';
import { marketplaceItemAtom } from '../../../atoms/marketplaceItemAtom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { IdlescapeButton, IdlescapeNumberInput } from '@idlescape/ui';
import {
	coinIcon,
	itemAffix,
	itemAugment,
	itemEnchant,
	itemHigherAugment,
	itemImage,
	textAugment,
	textEnchant,
} from '../../../utils/itemHelperFunctions';
import useIsMobile from '../../../hooks/useIsMobile';
import { mobileTooltipViewAtom } from '../../../atoms/mobileTooltipViewAtom';

export default function MarketplaceBuyItemListItem(props: { listing: IMarketListingWithStackSize; isOffer?: boolean }) {
	const itemToBuy = useRecoilValue(marketplaceItemAtom);
	const [mobileView, setMobileView] = useRecoilState(mobileTooltipViewAtom);
	const isMobile = useIsMobile();
	const item = props.listing.item;
	const costPer = props.listing.costPerStack ?? 0;
	const stockpile = usePlayerField('stockpile');
	const playerGold = stockpile.find((item) => item.itemID === itemsIds.gold)?.stackSize || 0;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [amountToBuy, setAmountToBuy] = useState(1);
	const itemData = itemList[props.listing.item.itemID];
	const isFood = itemData.tags?.includes('consumable');
	const isPot = itemData.name.includes('Potion');
	const isOffer = props.isOffer;
	const belowVendor = props.listing.price < (itemList[item.itemID].value ?? 0);
	const ownedItems = stockpile.filter((i) => {
		return (
			i.itemID == item.itemID &&
			(i.augmentations ?? 0) === (item.augmentations ?? 0) &&
			(i.enchantmentID ?? 0) === (item.enchantmentID ?? 0) &&
			(i.enchantmentStrength ?? 0) === (item.enchantmentStrength ?? 0)
		);
	});

	function handleCloseModal() {
		onClose();
		setAmountToBuy(1);
	}

	function handleValidInput() {
		if (amountToBuy > 0) {
			return true;
		} else {
			onClose();
			return false;
		}
	}

	function handleBuyAll() {
		const numCanAfford = Math.floor(playerGold / props.listing.price);
		setAmountToBuy(Math.min(props.listing.item.stackSize, numCanAfford));
	}

	function handleBuyItem(e: React.BaseSyntheticEvent) {
		e.stopPropagation();
		if (!handleValidInput()) return;

		socket.emit('marketplace:listing:buy', {
			amount: amountToBuy,
			listingId: props.listing.listingId,
			price: props.listing.price,
			refreshItemId: itemToBuy,
		});
		onClose();
		setAmountToBuy(1);
	}

	function handleSliderChange(newValue: number) {
		setAmountToBuy(newValue);
	}

	const maxAmount = props.listing.item.stackSize;

	const valueSlider = (
		<Flex className='item-input' alignItems='center' gap='4'>
			<Slider
				value={amountToBuy}
				onChange={handleSliderChange}
				min={1}
				max={maxAmount}
				focusThumbOnChange={false}
				flex='2 0 150px'
			>
				<SliderTrack>
					<SliderFilledTrack />
				</SliderTrack>
				<SliderThumb />
			</Slider>
			<IdlescapeNumberInput
				value={amountToBuy}
				setValue={setAmountToBuy}
				min={1}
				max={maxAmount}
				flex='1 0 80px'
				onKeyUp={(e) => {
					if (e.key === 'Enter') {
						handleBuyItem(e);
					}
				}}
			/>
		</Flex>
	);

	let tdCostPerStack: React.ReactElement | null = null;
	let extraField: React.ReactElement;
	if (isFood) {
		tdCostPerStack = (
			<Box>
				{'≃ ' + (costPer > 10 ? Math.round(costPer) : Math.round(costPer * 10) / 10).toLocaleString('en-us')}
			</Box>
		);
		if (isPot) {
			extraField = <Box className='market-field-extra'>{textEnchant(item, props.listing.anyEnchantment)}</Box>;
		} else {
			extraField = <Box className='market-field-extra'>{props.listing.totalHP?.toLocaleString('en-us')}</Box>;
		}
	} else {
		extraField = (
			<Box className='market-field-extra'>
				{textAugment(item)}
				{textEnchant(item, props.listing.anyEnchantment)}
			</Box>
		);
	}

	const noItemPopup = (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader> {'Sell ' + item.name}</ModalHeader>
				<ModalBody>
					{'You do not have ' +
						item.name +
						(item.augmentations ? ' +' + item.augmentations : '') +
						(item.enchantmentID ? ' with ' + enchantmentsList[item.enchantmentID].name : '') +
						(item.enchantmentStrength ? ' ' + item.enchantmentStrength : '') +
						'.'}
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={onClose}>
						Close
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

	const dialog = (
		<Modal isOpen={isOpen} onClose={handleCloseModal}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				{isMobile && item && (
					<Flex justifyContent='center' width='100%' top='0.5rem' margin='-13px 0'>
						<IdlescapeButton variant='blue' onClick={() => setMobileView(false)}>
							Details
						</IdlescapeButton>
						<IdlescapeButton variant='green' onClick={() => setMobileView(true)}>
							Action
						</IdlescapeButton>
					</Flex>
				)}
				<ModalHeader>
					{'Buy '}
					{props.listing.item.name +
						(props.listing.item.augmentations ? '+' + props.listing.item.augmentations : '')}
				</ModalHeader>
				{isMobile && !mobileView && item ? (
					<Flex justifyContent='center'>
						<ItemTooltip item={item} embed={true} />
					</Flex>
				) : (
					<>
						<ModalBody>
							<Text>How many do you want to buy?</Text>
							{valueSlider}
							<Flex justifyContent='center'>
								<IdlescapeButton variant='blue' onClick={handleBuyAll}>
									Buy Max
								</IdlescapeButton>
							</Flex>
							<Text marginBottom='0'>
								This will cost you: {(amountToBuy * props.listing.price).toLocaleString('en-us')}{' '}
								{coinIcon(16)}
							</Text>
						</ModalBody>
						<ModalFooter>
							<IdlescapeButton variant='red' onClick={handleCloseModal}>
								Close
							</IdlescapeButton>
							<IdlescapeButton variant='green' onClick={handleBuyItem}>
								Buy
							</IdlescapeButton>
						</ModalFooter>
					</>
				)}
			</ModalContent>
		</Modal>
	);

	return (
		<>
			<Grid
				gridTemplateColumns='repeat(auto-fit, minmax(50px, 1fr))'
				height='45px'
				position='relative'
				onClick={onOpen}
				className={`${
					props.listing.belongsToCurrentUser ? 'marketplace-own-listing ' : ''
				}marketplace-table-row`}
				_hover={{ backgroundColor: 'rgba(104, 104, 104, 0.9) !important', cursor: 'pointer' }}
				alignItems='center'
			>
				{!isFood && extraField}
				<Box margin={'auto'} position={'relative'} width={'45px'} height={'45px'}>
					{itemImage(itemData)}
					{itemEnchant(item, props.listing.anyEnchantment)}
					{itemAugment(item)}
					{itemAffix(item)}
					{itemHigherAugment(props.listing.higherAugments)}
				</Box>
				<Box>{props.listing.item.stackSize.toLocaleString('en-us')}</Box>
				<Box color={belowVendor ? 'red' : ''}>
					<Box fontFamily='Arial, monospace'>{props.listing.price.toLocaleString('en-us')}</Box>
				</Box>
				{isFood && extraField}
				{tdCostPerStack}
				{!isMobile && (
					<ItemTooltip
						item={props.listing.item}
						forceDefaultPositions={true}
						listingDate={props.listing.listingDate}
					/>
				)}
			</Grid>
			{isOffer && !props.listing.belongsToCurrentUser && isOpen && ownedItems.length > 0 && (
				<SellMarketplaceDialogue
					itemProp={ownedItems[0]}
					offerProp={props.listing}
					onClose={handleCloseModal}
				/>
			)}
			{isOffer && !props.listing.belongsToCurrentUser && ownedItems.length == 0 && noItemPopup}
			{isOffer && props.listing.belongsToCurrentUser && isOpen && (
				<BuyMarketplaceDialogue
					itemIdProp={props.listing.item.itemID}
					listingProp={props.listing}
					onClose={handleCloseModal}
				/>
			)}
			{!isOffer && !props.listing.belongsToCurrentUser && isOpen && dialog}
			{!isOffer && props.listing.belongsToCurrentUser && isOpen && (
				<SellMarketplaceDialogue listingProp={props.listing} onClose={handleCloseModal} />
			)}
		</>
	);
}
