import React from 'react';
import { IAbilityData } from '../../../../../../game-server/src/modules/ability/ability.interface';
import { AbilityTooltip } from './AbilityTooltip';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, BoxProps } from '@chakra-ui/react';
import { IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { itemImage } from '../../../../utils/itemHelperFunctions';
import { itemList } from '../../../../utils/itemList';

function CombatAbility({
	ability,
	learned,
	onClick,
	noTooltip,
	size = '80px',
	hideName = false,
	needAmmo = 0,
	...props
}: {
	ability: IAbilityData;
	learned?: boolean;
	onClick?: () => void;
	noTooltip?: boolean;
	size?: string;
	hideName?: boolean;
	needAmmo?: IItemData['id'];
} & BoxProps) {
	const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
		id: ability.id.toString(),
	});
	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
		zIndex: active ? 1 : undefined,
		opacity: active && active.id === ability.id.toString() ? 0.5 : undefined,
		width: size,
		height: size,
	};
	return (
		<Box
			className='combat-ability'
			onClick={onClick}
			ref={setNodeRef}
			style={style}
			{...listeners}
			{...attributes}
			{...props}
		>
			<Box
				backgroundImage={ability.abilityImage}
				filter={needAmmo ? 'brightness(40%) grayscale(100%)' : ''}
				className='combat-ability-icon'
			/>
			{needAmmo ? (
				<Box
					position='absolute'
					width='50px'
					height='50px'
					bottom='0'
					left='50%'
					transform='translate(-50%)'
					filter='brightness(1.2)'
				>
					{itemImage(itemList[needAmmo])}
				</Box>
			) : (
				''
			)}
			{!hideName && (
				<div className={learned ? 'combat-ability-name' : 'combat-ability-name-learned'}>
					{ability.abilityName}
				</div>
			)}
			{!active && !noTooltip && <AbilityTooltip ability={ability} />}
		</Box>
	);
}

export default CombatAbility;
