import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { PERSONAL_CONTRIBUTION_THRESHOLDS } from '../../../utils/constantsCollection';
import { formatNumberToString } from '../../../utils/helperFunctions';

export default function ShrineGuide() {
	const thresholds = PERSONAL_CONTRIBUTION_THRESHOLDS.map((t) => formatNumberToString(t));
	// last threshold is infinity
	thresholds.pop();
	return (
		<Box>
			The Shrine is an optional activity that all players can access and benefit from weekly. Every week, the
			shrine will select three random altars that will enhance certain skills within the game. These altars will
			contain three buffs that players can offer gold towards to enhance the strength of the buffs, with some
			buffs having caps. Players will now have personal milestones for offering gold to the shrine, the first tier
			being {thresholds.join(', then ')}. Fulfilling these milestones will reward you with more altar buffs with
			the third milestone granting you all the buffs from the altars. Any donation to any altar will contribute to
			the personal milestones.
			<br />
			In addition to the three altars, there will be an altar that will increase the duration of the altars as
			well, with each milestone increasing the time by 8 hours.
			<br />
			In order to donate to an altar simply click on it, input the amount of gold you wish to donate, then click
			donate. You can also just opt into donating to meet the next milestone to upgrade the altar by clicking the
			&quot;Complete milestone&quot; button.
			<Text color='gray' fontStyle='oblique'>
				A significant part of this guide was written by Feylos. Thx Feylos {'<3'}
			</Text>
		</Box>
	);
}
