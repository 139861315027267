import { AbsoluteCenter, Box, Grid, Image, Text } from '@chakra-ui/react';
import { IAffix, TAffixesPaths } from '../../../../../game-server/src/modules/affixes/affixes.interface';
import { TRelatedSkill } from '../../../../../game-server/src/modules/enchantment/enchantment.interface';
import { useGlobalField, usePlayerField } from '../../../hooks/hooks';
import { affixList } from '../../../utils/affixList';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { leagueList } from '../../../utils/leagueList';
import Tooltip from '../UI/Tooltip';
import { Buff } from './Buff';
import { StatusAffix } from './StatusAffix';

export const ActiveEffects = () => {
	const league = usePlayerField('league');
	const leagueData = leagueList[league];
	const actionQueue = usePlayerField('actionQueue');
	const enchantments = usePlayerField('enchantments');
	const equipment = usePlayerField('equipment');
	const tempBuffs = usePlayerField('buffs');
	const globals = useGlobalField('buffs');
	if (!actionQueue) {
		return null;
	}
	const action = actionQueue.actionType.replace('Action-', '').toLowerCase() as TRelatedSkill;

	function actionTypeToCategory(action: string): TRelatedSkill[] {
		switch (action) {
			case 'combat':
				return ['combat', 'attack', 'constitution', 'defense', 'range', 'defense', 'magic'];
			case 'mining':
			case 'foraging':
			case 'fishing':
				return ['gathering', 'skilling'];
			case 'smithing':
			case 'enchanting':
			case 'runecrafting':
			case 'cooking':
				return ['production', 'skilling'];
			default:
				return [];
		}
	}

	const category = actionTypeToCategory(action);

	function doesBuffApply(enchantmentID: number): boolean {
		const enchantment = enchantmentsList[enchantmentID];
		const relatedSkills = enchantment.relatedSkills;
		// Check if it matches the action first
		if (relatedSkills.includes(action)) {
			return true;
		}
		const category = actionTypeToCategory(action);
		for (const skill of relatedSkills) {
			if (category.includes(skill)) {
				return true;
			}
		}
		return false;
	}

	function doesAffixApply(path: TAffixesPaths): boolean {
		const affix = affixList[path];
		const relatedSkills = affix.relatedCategory;
		// Check if it matches the action first
		if (relatedSkills.includes(action)) {
			return true;
		}
		const category = actionTypeToCategory(action);
		for (const skill of relatedSkills) {
			if (category.includes(skill)) {
				return true;
			}
		}
		return false;
	}

	if (category.length === 0) {
		return null;
	}

	const buffs: {
		type: 'enchantment' | 'affix';
		strength: number;
		source: string;
		id?: number;
		path?: TAffixesPaths;
		itemID?: number;
	}[] = [];

	// Get the player's league buffs, temporary buffs, affixes, and enchantments
	const leagueBuffs = leagueData.rules?.leagueBuffs || [];
	for (const buff of leagueBuffs) {
		if (doesBuffApply(buff.id)) {
			buffs.push({ id: buff.id, type: 'enchantment', strength: buff.strength, source: 'League Buffs' });
		}
	}
	for (const buff of globals) {
		if (buff.leagueId) {
			if (buff.leagueId !== league) {
				continue;
			}
		}
		if (doesBuffApply(buff.enchantmentID)) {
			buffs.push({
				id: buff.enchantmentID,
				type: 'enchantment',
				strength: buff.enchantmentStrength,
				source: 'Global Buff',
			});
		}
	}
	const affixes: { itemID: number; affix: IAffix }[] = [];
	for (const gear of Object.values(equipment)) {
		if (!gear) {
			continue;
		}
		if (!gear.affixes) {
			continue;
		}
		for (const affix of gear.affixes) {
			affixes.push({ itemID: gear.itemID, affix: affix });
		}
	}
	for (const affix of affixes) {
		const affixHere = affix.affix;
		if (doesAffixApply(affixHere.path)) {
			// Get the original value of this affix from the item that was found, not the current calculated one
			buffs.push({
				path: affixHere.path,
				type: 'affix',
				strength: affixHere.value,
				source: 'Equipment Affix',
				itemID: affix.itemID,
			});
		}
	}
	for (const enchantment of enchantments) {
		if (doesBuffApply(enchantment.id)) {
			buffs.push({
				id: enchantment.id,
				type: 'enchantment',
				strength: enchantment.strength,
				source: 'Enchantment / Talent',
			});
		}
	}
	for (const buff of tempBuffs) {
		if (doesBuffApply(buff.enchantmentID)) {
			buffs.push({
				id: buff.enchantmentID,
				type: 'enchantment',
				strength: buff.enchantmentStrength,
				source: 'Temporary Buff',
			});
		}
	}
	if (buffs.length === 0) {
		return null;
	}

	function renderBuffs() {
		// Sort by source
		buffs.sort((a, b) => {
			if (a.source < b.source) return -1;
			if (a.source > b.source) return 1;
			return 0;
		});
		const fullArray = [];
		let currentSourceBuffs = [];
		const buffSources: string[] = [];
		for (const buff of buffs) {
			if (!buffSources.includes(buff.source)) {
				buffSources.push(buff.source);
			}
		}
		for (const buffSource of buffSources) {
			const sourceBuffs = buffs.filter((buff) => buff.source === buffSource);
			if (sourceBuffs.length > 0) {
				fullArray.push(
					<Text key={'status-bar-active-effects-source-' + buffSource} gridColumn={'1 / -1'}>
						{buffSource}
					</Text>
				);
				for (const buff of sourceBuffs) {
					if (buff.id) {
						currentSourceBuffs.push(
							<Buff
								key={'status-bar-active-effects-buff-' + buff.id + buffSource}
								source={buff.source}
								enchantmentID={buff.id}
								enchantmentStrength={buff.strength}
							/>
						);
					} else {
						if (buff.path && buff.itemID) {
							currentSourceBuffs.push(
								<StatusAffix
									key={'status-bar-active-effects-affix-' + buff.path}
									path={buff.path}
									value={buff.strength}
									parentItemID={buff.itemID}
								/>
							);
						}
					}
				}
				fullArray.push(
					<Grid
						key={'status-bar-active-effects-source-grid-' + buffSource}
						textAlign={'center'}
						maxWidth={'100%'}
						gridTemplateColumns={'repeat(auto-fit, 40px)'}
						justifyContent={'center'}
						alignContent={'center'}
						gap={'5px'}
					>
						{currentSourceBuffs}
					</Grid>
				);
				currentSourceBuffs = [];
			}
		}
		return (
			<div
				style={{
					width: '100%',
					gap: '5px',
					justifyContent: 'center',
					alignContent: 'center',
					textAlign: 'center',
				}}
			>
				{fullArray}
			</div>
		);
	}

	return (
		<Box
			borderRadius={'5px'}
			cursor={'pointer'}
			position={'relative'}
			padding={'2px'}
			width={'40px'}
			height={'40px'}
			border={'2px solid #573a6f'}
			backgroundColor={'#1e1829'}
		>
			<AbsoluteCenter
				fontSize={'16px'}
				fontWeight={'bold'}
				bottom={'20%'}
				textShadow={'-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'}
			>
				{buffs.length}
			</AbsoluteCenter>
			<Image src={'/images/magic/prismatic_gem.png'} height={'100%'} width={'100%'}></Image>
			<Tooltip onClick={true}>
				<Text textAlign={'center'} fontSize={'1.5rem'} margin={'5px 0'}>
					Active Effects - {action.charAt(0).toUpperCase() + action.slice(1)}
				</Text>
				{renderBuffs()}
			</Tooltip>
		</Box>
	);
};
