import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { jwtDecode } from 'jwt-decode';

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from './types';
import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import { ICharacterJWTData } from '../../../game-server/src/modules/session/SessionStarter';
import { openGameUrl } from '../GameUrls';

// Register User, UNUSED
export const registerUser =
	(
		userData: {
			username: string;
			email: string;
			password: string;
			password2: string;
		},
		navigate: NavigateFunction
	) =>
	(dispatch: Dispatch) => {
		axios
			.post('/api/auth/register', userData)
			.then((res) => {
				console.log(res, res.data, 'RESPONSE', history);
				/* dispatch(loginUser(userData, history));  */
				const { token } = res.data;
				localStorage.setItem('jwtToken', token);
				// Set token to Auth header
				setAuthToken(token);
				// Decode token to get user data
				const decoded: ICharacterJWTData = jwtDecode(token);
				// Set current user
				dispatch(setCurrentUser(decoded));
				openGameUrl('characters', { navigate });
			})
			.catch((err) =>
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				})
			);
	};

// Login - get user token, UNUSED
export const loginUser =
	(
		userData: {
			username: string;
			password: string;
			'2fa': string;
		},
		history: string[] = []
	) =>
	(dispatch: Dispatch) => {
		axios
			.post('/api/auth/login', userData)
			.then((res) => {
				// Save to localStorage
				// Set token to localStorage
				const { token } = res.data;
				localStorage.setItem('jwtToken', token);
				// Set token to Auth header
				setAuthToken(token);
				// Decode token to get user data
				const decoded: ICharacterJWTData = jwtDecode(token);
				// Set current user
				dispatch(setCurrentUser(decoded));
				if (history) {
					history.push('/characters');
				}
			})
			.catch((err) => {
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
			});
	};

// Guest login, UNUSED
export const guestLogin =
	(history: string[] = []) =>
	(dispatch: Dispatch) => {
		axios
			.post('/api/auth/guest-login')
			.then((res) => {
				// Save to localStorage
				// Set token to localStorage
				const { token } = res.data;
				localStorage.setItem('jwtToken', token);
				// Set token to Auth header
				setAuthToken(token);
				// Decode token to get user data
				const decoded: ICharacterJWTData = jwtDecode(token);
				// Set current user
				dispatch(setCurrentUser(decoded));
				sessionStorage.setItem('characterID', res.data.characterId);
				if (history) {
					history.push('/game');
				}
			})
			.catch((err) => {
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
			});
	};

// Forgot password, UNUSED
export const forgotPassword = (recoveryData: { email: string; recaptcha: string }) => {
	axios.post('/api/password/forgot-password-request', recoveryData);
};
// UNUSED
export const resetPassword =
	(
		passwordData: { password: string; password2: string; '2fa': string; token: string | null | undefined },
		history: string[] = []
	) =>
	(dispatch: Dispatch) => {
		axios
			.post('/api/password/password-recovery', passwordData)
			.then((res) => {
				if (history) {
					history.push('/password-changed');
				}
			})
			.catch((err) => {
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
			});
	};

// Set logged in user
export const setCurrentUser = (decoded: Partial<ICharacterJWTData>) => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded,
	};
};

// User loading, UNUSED
export const setUserLoading = () => {
	return {
		type: USER_LOADING,
	};
};

// Log user out
export const logoutUser = async (dispatch: Dispatch) => {
	try {
		// Wait for the logout API call to complete
		await axios.post('/api/auth/logout');
	} catch (error) {
		console.error('Logout failed', error);
		// Optionally, handle the error (e.g., display a message to the user)
	}
	// Remove token from local storage
	localStorage.removeItem('jwtToken');
	// Remove auth header for future requests
	setAuthToken(false);
	// Set current user to empty object {} which will set isAuthenticated to false
	dispatch(setCurrentUser({}));

	openGameUrl('landing');
};
