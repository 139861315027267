// @@@ THIS FILE IS DYNAMICALLY GENERATED. MODIFICATIONS WILL NEVER BE SAVED.
// MODIFY THE MASTER LIST AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES TO LIST ITEMS HERE

import { TPlatinumPackList } from '../../../game-server/src/modules/monetization/PlatinumPackData';

export const platinumPacks: TPlatinumPackList = {
	1: {
		id: 1,
		name: '1000 Platinum',
		description: 'Platinum can be used in the Platinum Shop to buy cosmetics and more',
		price: 7.95,
		currency: 'USD',
		platinumAmount: 1000,
		image: '/images/premium/platinum_pack_1.png',
		priceId: {
			development: 'price_1Mf2rSEnEMjApR1KPlbBGLpN',
			production: 'price_1Mg2SMEnEMjApR1KvFuVFJcV',
		},
	},
	2: {
		id: 2,
		name: '2800 Platinum',
		description: 'Contains 2800 Platinum',
		price: 19.95,
		currency: 'USD',
		platinumAmount: 2800,
		image: '/images/premium/platinum_pack_2.png',
		discount: 11,
		priceId: {
			development: 'price_1Mf2m6EnEMjApR1Kme1BOaSP',
			production: 'price_1Mg2SjEnEMjApR1KBZlsMRJ8',
		},
	},
	3: {
		id: 3,
		name: '5000 Platinum',
		description: 'Contains 5000 Platinum',
		price: 31.95,
		currency: 'USD',
		platinumAmount: 5000,
		image: '/images/premium/platinum_pack_3.png',
		discount: 24,
		priceId: {
			development: 'price_1Mf2t0EnEMjApR1KXo8pWN5H',
			production: 'price_1Mg2SbEnEMjApR1KEj9zwjTR',
		},
	},
	4: {
		id: 4,
		name: '13500 Platinum',
		description: 'Contains 13500 Platinum',
		price: 79.95,
		currency: 'USD',
		platinumAmount: 13500,
		image: '/images/premium/platinum_pack_4.png',
		discount: 34,
		priceId: {
			development: 'price_1Mf2u6EnEMjApR1KoMALB6J7',
			production: 'price_1Mg2SREnEMjApR1Kq7jsSwGy',
		},
	},
};
