import { Flex, Grid, ImageProps } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { MdOutlineRemoveCircle } from 'react-icons/md';
import { ILocationData } from '../../../../../../game-server/src/modules/locations/location.interface';
import { IInspectData } from '../../../../../../game-server/src/modules/profile/Profile';
import useIsMobile from '../../../../hooks/useIsMobile';
import { socket } from '../../../../services/socket.service';
import { locations } from '../../../../utils/locationList';
import { locationsIds } from '../../../../utils/lookup-dictionaries/lookupLocationList';
import FakeItem from '../../../game/Inventory/FakeItem';

export function DungeonDisplay({
	dungeon,
	highestAug,
	date,
	allowRemove,
	...props
}: { dungeon: ILocationData['locID']; highestAug?: number; date?: Date; allowRemove?: boolean } & ImageProps) {
	const dungeonData = locations[dungeon];
	if (!dungeonData) return null;
	const dungeonKey = dungeonData.relatedKey;
	if (!dungeonKey) return null;
	function removeDungeon(dungeonID: number | null) {
		if (dungeonID === null) return;
		socket.emit('profile:dungeon:remove', dungeonID);
	}

	return (
		<Flex flexDir='column' alignItems='center' position='relative'>
			<FakeItem item={{ itemID: dungeonKey, augmentations: highestAug }} {...props} />
			{date !== undefined && new Date(date).toLocaleDateString()}
			{allowRemove && (
				<Flex
					onClick={() => removeDungeon(dungeon)}
					position='absolute'
					top='0'
					right='0'
					fontSize='20px'
					color='red.500'
					cursor='pointer'
					_hover={{ transform: 'scale(1.1)' }}
				>
					<MdOutlineRemoveCircle />
				</Flex>
			)}
		</Flex>
	);
}

export function DungeonEditor({ activeDungeons }: { activeDungeons: IInspectData['dungeons'] }) {
	const dungeons = [locationsIds.goblin_settlement, locationsIds.giants_keep].filter(
		(dungeon) => !activeDungeons.map((dungeon) => dungeon.locID).includes(dungeon)
	);

	const isMobile = useIsMobile();

	if (dungeons.length === 0) return null;

	function addDungeon(dungeonID: number) {
		socket.emit('profile:dungeon:add', dungeonID);
	}

	function dungeonPicker() {
		return (
			<Grid gridTemplateColumns='repeat(auto-fit, 45px)' gap='5px' maxWidth='300px'>
				{dungeons.map((dungeon) => {
					return (
						<DungeonDisplay
							key={dungeon}
							dungeon={dungeon}
							onClick={() => addDungeon(dungeon)}
							height={isMobile ? '30px' : '40px'}
							width={isMobile ? '30px' : '40px'}
							style={{ cursor: 'pointer' }}
						/>
					);
				})}
			</Grid>
		);
	}
	return (
		<IdlescapeWrappingTooltip content={dungeonPicker()} activateOnClick>
			<Flex
				height={isMobile ? '50px' : '60px'}
				width={isMobile ? '50px' : '60px'}
				border='3px solid white'
				borderRadius='15px'
				borderStyle='dashed'
				justifyContent='center'
				alignItems='center'
				cursor='pointer'
				_hover={{ backgroundColor: 'rgba(255,255,255,0.3)' }}
			>
				<FaPlus />
			</Flex>
		</IdlescapeWrappingTooltip>
	);
}
