import { Box, Grid } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React, { useState } from 'react';
import AbilityEditor from './Abilities/AbilityEditor';
import CombatAmmoInventory from './CombatAmmoInventory';
import { CombatCharacterEquipment } from './CombatCharacterEquipment';
import CombatFoodInventory from './CombatFoodInventory';
import CombatPlayerStats from './CombatPlayerStats';

/**
 * This is its own file because trying to put it in CombatGear led to a bunch of weird hooks issues
 */
export default function CombatGearRightPanel() {
	const [containerView, setContainerView] = useState<'equipment' | 'cosmetics' | 'ammo' | 'food'>('equipment');

	function renderView() {
		if (containerView === 'equipment' || containerView === 'cosmetics') {
			return <CombatCharacterEquipment view={containerView}></CombatCharacterEquipment>;
		}
		if (containerView === 'ammo') {
			return (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<CombatAmmoInventory />
				</div>
			);
		}
		if (containerView === 'food') {
			return (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<CombatFoodInventory />
				</div>
			);
		}
	}

	return (
		<div className='combat-gear-container-wrapper'>
			<div className='combat-gear-container'>
				<Box gridRow={'span 2'}>
					<Grid
						gridTemplateColumns={'repeat(auto-fit, minmax(50px, 1fr))'}
						textAlign={'center'}
						columnGap={'10px'}
					>
						<IdlescapeButton
							onClick={() => setContainerView('equipment')}
							variant='green'
							width={'100%'}
							maxWidth={'150px'}
							justifySelf={'end'}
						>
							Gear
						</IdlescapeButton>
						<IdlescapeButton
							onClick={() => setContainerView('cosmetics')}
							variant='blue'
							width={'100%'}
							maxWidth={'150px'}
							justifySelf={'start'}
						>
							Cosmetics
						</IdlescapeButton>
						<IdlescapeButton
							onClick={() => setContainerView('food')}
							variant='orange'
							width={'100%'}
							maxWidth={'150px'}
							justifySelf={'end'}
						>
							Food
						</IdlescapeButton>
						<IdlescapeButton
							onClick={() => setContainerView('ammo')}
							variant='red'
							width={'100%'}
							maxWidth={'150px'}
							justifySelf={'start'}
						>
							Ammo
						</IdlescapeButton>
					</Grid>
					{renderView()}
				</Box>

				<CombatPlayerStats />

				<AbilityEditor />
			</div>
		</div>
	);
}
