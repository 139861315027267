import { ReactElement, useState } from 'react';
import { itemList } from '../../../utils/itemList';
import { locations } from '../../../utils/locationList';
import {
	Flex,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { usePlayerField } from '../../../hooks/hooks';
import { BASE_ACTIONS_COUNT, PLUS_ACTIONS_COUNT } from '../../../utils/constantsCollection';
import { IActionOptions } from '../../../../../game-server/src/modules/actions/Action';
import { forges } from '../../../utils/forgeList';
import { IdlescapeButton, IdlescapeNumberInput, IdlescapeWrappingTooltip } from '@idlescape/ui';
import { getSocket } from '../../../services/socket.service';
import { Box } from '@chakra-ui/react';
import './ActionQueue.css';
import { TSkillTab } from '../../../../../game-server/src/Atypes/Skills';
import { tabs } from '../../../utils/NavigationTabsList';
import { useSetRecoilState } from 'recoil';
import { activeTabAtom } from '../../../atoms/activeTabAtom';

export default function ActionQueueButton(props: {
	action: TSkillTab | 'combat';
	location: number;
	options?: IActionOptions;
	className?: string;
	variant?: string;
}) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const socket = getSocket();
	const subscription = usePlayerField('subscription');
	const isSubscribed = subscription?.active ?? false;
	const bonusActionQueueCount = usePlayerField('bonusActionQueueCount');
	const bonusActionQueueLength = usePlayerField('bonusActionQueueLength');
	const settings = usePlayerField('settings');

	const [length, setLength] = useState(0); // in minutes, it's converted to ms later by backend
	const [count, setCount] = useState(0);

	const queueList = settings.miscellaneous.userActionQueue;
	const maxQueueCount =
		BASE_ACTIONS_COUNT + bonusActionQueueCount + bonusActionQueueLength + (isSubscribed ? PLUS_ACTIONS_COUNT : 0);

	const location = locations[props.location];
	const actionName = location.actionType.replace('Action-', '');
	const skillIcon = props.action === 'combat' ? '/images/combat/attack_icon.png' : tabs[props.action].icon;
	const usedCount = queueList.length;

	const canQueue = usedCount < maxQueueCount;

	function addToQueue() {
		const location = props.location;
		const options = props.options;
		socket.emit('action:queue:add', { location, options, length: length, count: count });
		onClose();
	}

	function renderAdditionalOptions(options: IActionOptions) {
		const returnObject: ReactElement[] = [];
		if (options.runecraftingId || options.scrollcraftingId) {
			const item = itemList[options.runecraftingId ?? options.scrollcraftingId ?? 1];
			returnObject.push(
				<div key={'action-option-rc'}>
					<Image className='action-queue-icon' src={item.itemImage} /> {item.name}
				</div>
			);
		}
		if (options.smithing) {
			const item = itemList[options.smithing.barId];
			if (item) {
				returnObject.push(
					<div key={'action-option-smithing'}>
						{forges[options.smithing.forgeId]?.forgeName} -{' '}
						<Image className='action-queue-icon' src={item.itemImage} />
						{item.name}, Intensity: {options.smithing.intensity}
					</div>
				);
			}
		}
		return returnObject;
	}

	const goToQueue = (
		<IdlescapeWrappingTooltip content='Open action queue'>
			<Box position='absolute' top='60px' right='18px'>
				<Image
					src='/images/pontification/skill_waiting_icon.png'
					alt='Open action queue'
					className='share-marketplace-listing-button'
					filter='drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'
					onClick={() => {
						setActiveTab('action-queue');
					}}
				></Image>
			</Box>
		</IdlescapeWrappingTooltip>
	);

	function actionQueueDialog() {
		return (
			<Modal isOpen={true} onClose={onClose}>
				<ModalOverlay />
				<ModalContent className='sell-item-dialog'>
					<ModalHeader>Add to Queue: {actionName}</ModalHeader>
					<ModalCloseButton />
					{goToQueue}
					<ModalBody>
						<div>
							Queue Count: {usedCount} / {maxQueueCount}
							<br />
							<Image className='action-queue-icon' src={skillIcon} />
							{location.locationImage ? (
								<Image className='action-queue-icon' src={location.locationImage} />
							) : (
								''
							)}
							{location.activeMessage}
							<>{props.options ? renderAdditionalOptions(props.options) : ''}</>
						</div>
						<Flex gap='10px' alignItems='center'>
							<IdlescapeNumberInput
								setValue={setLength}
								value={length}
								placeholder='∞'
								width='110px'
								min={0}
								maxLength={10}
							/>
							<Box as='span' fontSize='1rem'>
								minutes
							</Box>
						</Flex>
						<Flex gap='10px' alignItems='center'>
							<IdlescapeNumberInput
								setValue={setCount}
								value={count}
								placeholder='∞'
								display='inline-block'
								width='110px'
								min={0}
								maxLength={10}
							/>
							<Box as='span' fontSize='1rem'>
								amount
							</Box>
						</Flex>
					</ModalBody>
					<ModalFooter className='item-dialogue-button-div'>
						<IdlescapeButton
							variant={canQueue ? 'green' : 'disabled'}
							size='large'
							justifySelf={'center'}
							onClick={addToQueue}
						>
							Add to Queue
						</IdlescapeButton>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	}

	return (
		<>
			{props.variant ? (
				<IdlescapeButton
					className={`add-queue-button ${props.className ? props.className : ''}`}
					variant={props.variant}
					onClick={onOpen}
					size='medium'
				>
					Add Queue
				</IdlescapeButton>
			) : (
				<button className={`add-queue-button ${props.className ? props.className : ''}`} onClick={onOpen}>
					Add Queue
				</button>
			)}
			{isOpen && actionQueueDialog()}
		</>
	);
}
