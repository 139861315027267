import { IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import { enchantingSelectedItemIdAtom } from '../../../../atoms/enchantingSelectedItemIdAtom';
import { enchantingSelectedScrollIdAtom } from '../../../../atoms/enchantingSelectedScrollIdAtom';
import { usePlayerField } from '../../../../hooks/hooks';
import { filterItem } from '../../../../utils/helperFunctions';
import { itemList } from '../../../../utils/itemList';
import ItemSelector from '../../Inventory/ItemSelector';

export default function EnchantingItemList() {
	const [selectedItemId, setActiveItemId] = useRecoilState(enchantingSelectedItemIdAtom);
	const selectedScrollId = useRecoilValue(enchantingSelectedScrollIdAtom);
	const stockpile = usePlayerField('stockpile');
	const selectedItem = stockpile.find((item) => item.id === selectedItemId);
	const selectedScroll = stockpile.find((item) => item.id === selectedScrollId);

	const [sort, setSort] = useState('');
	const filteredStockpile = stockpile
		.filter((item) => {
			const itemData = itemList[item.itemID];
			if (!itemData.enchantmentTier) {
				return false;
			}
			if (selectedScroll) {
				const selectedScrollData = itemList[selectedScroll.itemID];
				const targetSlot = itemData.enchantmentOverrideSlot ?? itemData?.equipmentStats?.slot ?? '';
				if (!selectedScrollData.categories?.includes(targetSlot)) {
					if (itemData.forcedEnchant === selectedScrollData.enchantmentID) {
						return true;
					}
					return false;
				}
			}
			return true;
		})
		.filter((item) => filterItem(item, sort));

	function handleSelectItem(item: IItem) {
		if (selectedItem?.id === item.id) {
			setActiveItemId(-1);
		} else {
			setActiveItemId(item.id);
		}
	}

	return (
		<div className='enchanting-items-container'>
			<h5 className='enchanting-title'>Items</h5>
			<div className='enchanting-title-border'></div>
			<IdlescapeInput
				variant='search'
				setValue={setSort}
				value={sort}
				type='search'
				className={'anchor-enchanting-inventory-filter'}
				placeholder='Inventory Filter'
			/>
			<div className='enchanting-items'>
				<ItemSelector
					items={filteredStockpile}
					selectedId={selectedItem?.id ?? -1}
					select={handleSelectItem}
					multiSelection={false}
				/>
			</div>
		</div>
	);
}
