import React from 'react';
import { useRecoilValue } from 'recoil';
import { tabs } from '../../utils/NavigationTabsList';
import { activeTabAtom } from '../../atoms/activeTabAtom';
import { locations } from '../../utils/locationList';
import { Box } from '@chakra-ui/react';
import { usePlayerField } from '../../hooks/hooks';
import { smithingActiveForgeAtom } from '../../atoms/smithingActiveForgeAtom';

export const PlayAreaBackground = () => {
	const activeTab = useRecoilValue(activeTabAtom);
	const activeForge = useRecoilValue(smithingActiveForgeAtom);
	const tabInfo = tabs[activeTab];
	const actionQueue = usePlayerField('actionQueue');
	const isCombat =
		tabInfo.theme === 'theme-combat' &&
		actionQueue &&
		locations[actionQueue.location].actionType === 'Action-Combat';
	const url = isCombat ? locations[actionQueue.location].locationImage : undefined;
	const extraClasses = tabInfo.theme === 'theme-smithing' && activeForge ? 'smithing-background-' + activeForge : '';
	return (
		<div className={`play-area-background play-area-background-${tabInfo.theme}`}>
			<Box
				className={`${tabInfo.theme} ${extraClasses}`}
				width='calc(100% - 26px)'
				height='calc(100% - 26px)'
				backgroundImage={url}
				backgroundSize='cover'
				backgroundPosition='center'
				boxShadow='0 0 8px 4px rgb(36, 39, 44) inset'
			/>
		</div>
	);
};
