import React from 'react';
import { useRecoilValue } from 'recoil';
import { messageAtom } from '../../../atoms/messageAtom';
import ChatTab from './ChatTab';

export default function ChatTabsList() {
	const channels = useRecoilValue(messageAtom);
	const channelKeys = Object.keys(channels);

	function renderTab(key: string, index: number) {
		/** The first message in the array contains the settings */
		const channelSettings = channels[key][0];
		let unreadMessages = 0;
		let hasUnreadMention = false;
		for (const message of channels[key]) {
			if (message.type !== 'settings' && message.read === false) {
				if (message.type !== 'system-message' || message.channelName === 'Activity') unreadMessages++;
				if (message.hasMention) {
					hasUnreadMention = true;
				}
			}
		}
		return (
			<ChatTab
				hasUnreadMention={hasUnreadMention}
				unreadMessages={unreadMessages}
				settings={channelSettings}
				index={index}
				key={key}
			/>
		);
	}

	const activityIndex = channelKeys.indexOf('Activity');
	const groupChatIndex = channelKeys.indexOf('Group Chat');

	return (
		<div className='chat-tabs-list'>
			{activityIndex !== -1 && renderTab(channelKeys[activityIndex], activityIndex)}
			{groupChatIndex !== -1 && renderTab(channelKeys[groupChatIndex], groupChatIndex)}
			{channelKeys.map((key, index) => {
				if (key !== 'Activity' && key !== 'Group Chat') {
					return renderTab(key, index);
				}
			})}
		</div>
	);
}
