import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import React, { useEffect, useRef } from 'react';
import { IItemData } from '../../../../game-server/src/modules/items/items.interface';
import { usePlayerEnchantmentStrength, usePlayerField } from '../../hooks/hooks';
import { socket } from '../../services/socket.service';
import {
	MINIMUM_ACTION_TIME_IN_SECONDS,
	RUNECRAFTING_ESSENCE,
	RUNECRAFTING_RUNES,
} from '../../utils/constantsCollection';
import { itemList } from '../../utils/itemList';
import { enchantmentsIds } from '../../utils/lookup-dictionaries/lookupEnchantmentList';
import { itemsIds } from '../../utils/lookup-dictionaries/lookupItemList';
import { locationsIds } from '../../utils/lookup-dictionaries/lookupLocationList';
import { tabs } from '../../utils/NavigationTabsList';
import { IProgressBarRef, ProgressBar } from '../layout/UI/ProgressBar';
import Tooltip from '../layout/UI/Tooltip';
import { CraftingAugmentingData } from './CraftingAugmenting/CraftingAugmentingData';
import { dwarvenElvenBonus, dwarvenElvenHaste } from '../../utils/itemFunctions';
import './ResourceAsRow.css';
import { Box } from '@chakra-ui/react';
import { TEssenceID } from '../../../../game-server/src/modules/player/PlayerTypes';
import ActionQueueButton from './ActionQueue/ActionQueueButton';

// ! This was ResourceAsRow.js. Was only used for runecrafting, so I renamed it

export default function RunecraftingRow(props: { resource: IItemData; dungeon?: boolean }) {
	const progressBarRef = useRef<IProgressBarRef>(null);

	const actionQueue = usePlayerField('actionQueue');
	const skills = usePlayerField('skills');
	const skillEquipmentStats = usePlayerField('skillEquipmentStats');
	const effectiveRunecraftingLevel =
		skills.runecrafting.level + skills.runecrafting.masteryLevel + skillEquipmentStats.runecrafting;
	const settings = usePlayerField('settings');
	const highPerformance = settings.miscellaneous.highPerformance;
	const stockpile = usePlayerField('stockpile');
	const talismans = usePlayerField('talismans');
	const running = actionQueue?.options?.runecraftingId === props.resource.id;

	const hasteStrength = usePlayerEnchantmentStrength(enchantmentsIds.haste, 'runecrafting');
	const dwarvenManufacturingStrength = usePlayerEnchantmentStrength(
		enchantmentsIds.dwarven_manufacturing,
		'runecrafting'
	);
	const elvenLogisticsStrength = usePlayerEnchantmentStrength(enchantmentsIds.elven_logistics, 'runecrafting');
	const essenceConcentrationStrength = usePlayerEnchantmentStrength(
		enchantmentsIds.essence_concentration,
		'runecrafting'
	);
	const essenceConcentrationExpertStrength = usePlayerEnchantmentStrength(
		enchantmentsIds.essence_concentration_expert,
		'runecrafting'
	);
	const finalEssenceConcentrationStrength = Math.max(
		0,
		essenceConcentrationStrength / 4 - essenceConcentrationExpertStrength
	);
	const runecraftingStrength = usePlayerEnchantmentStrength(enchantmentsIds.runecrafting, 'runecrafting');
	const scholarStrength = usePlayerEnchantmentStrength(enchantmentsIds.scholar, 'runecrafting');
	const wealthStrength = usePlayerEnchantmentStrength(enchantmentsIds.wealth, 'runecrafting');

	const overallTime = getTime();

	// Change image if wealth
	let runeImage = <img className='resource-as-row-image' src={props.resource.itemImage} alt={props.resource.name} />;
	if (wealthStrength) {
		const gold = Math.floor((props.resource.value ?? 0) * (1 + wealthStrength));
		runeImage = (
			<>
				<img className='scrollcrafting-image' src={itemList[itemsIds.gold].itemImage} alt='Gold coins' />
				<Box position='absolute' bottom='10px' width='60px' textAlign='center' fontSize='xl'>
					{gold.toLocaleString()}
				</Box>
			</>
		);
	}

	// Change image and experience if scholar
	let experience = props.resource.experience ?? 25; // Runes don't have an exp value so default 25
	if (scholarStrength && !wealthStrength) {
		experience *= 1 + scholarStrength;
		experience = Math.floor(experience);
		runeImage = <img className='scrollcrafting-image' src='/images/total_level.png' alt='experience' />;
	}

	useEffect(() => {
		socket.on('animation:start', startAnimation);
		return () => {
			socket.off('animation:start');
		};
	}, []);

	useEffect(() => {
		if (!running) {
			progressBarRef.current?.stopAnimation();
		} else {
			const expectedEndTime = actionQueue?.info?.completionTime;
			let newLength = 0;
			if (expectedEndTime) {
				newLength = expectedEndTime - new Date().getTime();
			}
			progressBarRef.current?.startAnimation(newLength);
		}
	}, [running]);

	function startWorking() {
		if (running) {
			socket.emit('action:stop');
		} else {
			socket.emit('action:start', {
				action: 'runecrafting',
				location: locationsIds.the_runecrafting_shrines,
				options: { runecraftingId: props.resource.id },
			});
		}
	}

	function queueButton() {
		return (
			<ActionQueueButton
				action='runecrafting'
				location={locationsIds.the_runecrafting_shrines}
				options={{ runecraftingId: props.resource.id }}
			/>
		);
	}

	function startAnimation(data: { action: string; location: number; length: number; resource?: number }) {
		if (props.resource.id === data.resource) {
			progressBarRef.current?.startAnimation(data.length * 1000);
		}
	}

	function getTime() {
		let time = props.resource.time ? props.resource.time / 1000 : 5;
		time = time / ((99 + Math.max(effectiveRunecraftingLevel, 7.5) / 7.5) / 100);
		const logisticsStrength = dwarvenElvenHaste(dwarvenManufacturingStrength, elvenLogisticsStrength);
		time /= 1 + logisticsStrength;

		time = Math.max(MINIMUM_ACTION_TIME_IN_SECONDS, time / (1 + hasteStrength));
		return time;
	}

	// calculate required resource list
	const requiredResources: React.ReactElement[] = [];
	let canCraft = true;

	//level
	if (props.resource.level) {
		requiredResources.push(
			<div className='resource-node-time-tooltip'>
				<img src={tabs['runecrafting'].icon} alt='level' className='icon16' />
				<span>{props.resource.level}</span>
				<Tooltip>
					<span className='resource-tooltip-text'>Level Required</span>
				</Tooltip>
			</div>
		);
	}

	// dungeon
	if (props.dungeon) {
		const ancientCloth = itemList[itemsIds.ancient_cloth_fragment];
		// copied from RunecraftingHelper.ts
		const chance = (1 / 200000000) * (props.resource?.level ?? 1) ** 2 * effectiveRunecraftingLevel;
		requiredResources.push(
			<IdlescapeWrappingTooltip content={`Chance for an ${ancientCloth.name}`}>
				<div className='resource-node-time-tooltip'>
					<img src={ancientCloth.itemImage} alt='dungeon' className='icon16' />
					<span>
						{chance.toLocaleString('en-us', {
							style: 'percent',
							minimumFractionDigits: 2,
						})}
					</span>
				</div>
			</IdlescapeWrappingTooltip>
		);
	}

	//time
	if (props.resource.time) {
		requiredResources.push(
			<div className='resource-node-time-tooltip'>
				<img src='/images/clock.png' alt='time' className='icon16' />
				<span>{overallTime.toFixed(2)}s</span>
				<Tooltip>
					<span className='resource-tooltip-text'>Time Required</span>
				</Tooltip>
			</div>
		);
	}

	if (experience) {
		requiredResources.push(
			<div className='resource-node-time-tooltip'>
				<img src='/images/total_level.png' alt='time' className='icon16' />
				<span>{experience}xp</span>
				<Tooltip>
					<span className='resource-tooltip-text'>Experience Per Item</span>
				</Tooltip>
			</div>
		);
	}

	//other resources
	const runecraftingRecipe = CraftingAugmentingData.getRunecraftingByID(props.resource.id);
	if (!runecraftingRecipe) return null;
	for (const [resourceString, amount] of Object.entries(runecraftingRecipe)) {
		const resource = parseInt(resourceString);
		let requiredResourceAmount = amount;
		const logisticsBonus = dwarvenElvenBonus(dwarvenManufacturingStrength, elvenLogisticsStrength);
		requiredResourceAmount *= logisticsBonus;
		const availableItem = stockpile.find((obj) => obj.itemID === resource);
		if (RUNECRAFTING_ESSENCE.includes(resource) || resource === itemsIds.winter_essence) {
			// Essence for RC
			requiredResourceAmount = Math.round(requiredResourceAmount * (1 - runecraftingStrength));
			requiredResourceAmount = requiredResourceAmount * (1 + finalEssenceConcentrationStrength);
		}
		if (resource === itemsIds.rune_slate) {
			const base = Math.ceil(effectiveRunecraftingLevel / 20);
			const idx = RUNECRAFTING_RUNES.findIndex((rune) => rune === props.resource.id);
			const hasTalisman = talismans[RUNECRAFTING_ESSENCE[idx] as TEssenceID];

			requiredResourceAmount = 1 + base;
			requiredResourceAmount += hasTalisman ? 1 : 0;
			// Rune Slate for RC
			requiredResourceAmount += essenceConcentrationStrength;
			requiredResourceAmount = Math.ceil(requiredResourceAmount * logisticsBonus);
		}
		requiredResourceAmount = Math.round(requiredResourceAmount);
		requiredResourceAmount = Math.max(0, requiredResourceAmount);

		const enoughResources = (availableItem?.stackSize ?? 0) >= requiredResourceAmount;
		canCraft &&= enoughResources;

		if (itemList[resource]) {
			requiredResources.push(
				<div className='resource-node-time-tooltip' data-itemid={resource}>
					{<img src={itemList[resource]?.itemImage} alt={itemList[resource]?.name} className='icon16' />}
					<span className={enoughResources ? '' : 'red-text'}>{requiredResourceAmount}</span>
					<Tooltip>
						<span className='resource-tooltip-text'>
							Resource required:
							<br />
							{itemList[resource]?.name}
						</span>
					</Tooltip>
				</div>
			);
		}
	}

	return (
		<div className='resource-as-row-container' data-itemid={props.resource.id}>
			{runeImage}

			<Box
				className='resource-as-row-title'
				textDecoration={(wealthStrength || scholarStrength) && 'line-through'}
			>
				{props.resource.name}
			</Box>
			<div className='resource-as-row-button'>
				<button disabled={!canCraft} onClick={startWorking}>
					{running ? 'Stop' : 'Craft'}
				</button>
				{queueButton()}
			</div>

			<div className='resource-as-row-required-resources'>
				{requiredResources.map((requiredResource, index) => (
					<div key={index} className='resource-as-row-property'>
						{requiredResource}
					</div>
				))}
			</div>

			{!highPerformance && (
				<ProgressBar ref={progressBarRef} value={0} max={100} theme='default' gridArea='bar' height='100%' />
			)}

			{(props.resource.level ?? 0) > skills.runecrafting.level && (
				<div className='resource-as-row-locked-cover'>
					<p>
						Requires {props.resource.level}
						<img src={tabs['runecrafting'].icon} className='icon50' alt='Runecrafting Level' />
					</p>
				</div>
			)}
		</div>
	);
}
