import { atom } from 'recoil';
import { TActiveTab } from '../../../game-server/src/Atypes/Skills';
import { tabs } from '../utils/NavigationTabsList';
import { localStorageEffect } from './effects/localStorageEffect';

export const activeTabAtom = atom({
	key: 'activeTab',
	default: 'mining' as TActiveTab,
	effects: [localStorageEffect('activeTab', (parsed) => tabs[parsed as TActiveTab] !== undefined)],
});
