export const questsIds = {
	getting_started_verify_email: 1,
	who_is_that_guy: 2,
	climbing_the_ranks: 3,
	socializing: 4,
	le_toucan_has_arrived: 5,
	hiring_mercenaries: 6,
	the_action_queue: 7,
	helping_hand: 100,
	caves_and_caverns: 101,
	city_services: 102,
	city_secrets: 103,
	secrets_unveiled: 104,
	the_fallen_academy: 105,
	runes_of_the_lava_maze: 106,
	the_path_to_enhancement: 200,
	realizing_your_enchanting_potential: 201,
	going_beyond_the_academy: 202,
	unearthing_elder_knowledge: 203,
	unleashing_ancient_knowledge: 204,
	crafting_tools_i: 205,
	crafting_tools_ii: 206,
	crafting_tools_iii: 207,
	crafting_tools_iv: 208,
	crafting_tools_v: 209,
	mining_equipment: 210,
	legendary_pickaxe: 211,
	foraging_equipment: 212,
	legendary_hatchet: 213,
	fishing_set_quest: 214,
	legendary_tacklebox: 215,
	runecrafting_set_quest: 216,
	legendary_chisel: 217,
	smithing_equipment: 218,
	legendary_tongs: 219,
	cooking_set_quest: 220,
	legendary_ladle: 221,
	legendary_hoe: 222,
	cave_cleanup: 300,
	first_place_farm_fiasco: 301,
	punishing_the_punishers: 302,
	purging_the_cultists: 303,
	exorcising_the_pits: 304,
	clearing_the_undergrowth: 305,
	entering_the_armory: 306,
	conquering_the_corruption: 307,
	entering_the_elves_domain: 308,
	a_giant_valley: 309,
	the_chaotic_plane: 310,
	gathering_tutorial: 2000,
	processing_tutorial: 2001,
	combat_tutorial: 2002,
	item_improvement_tutorial: 2003,
	affixing_tutorial: 2004,
	talent_tutorial: 2005,
	farm_fiend: 5000,
	cavern_crusader: 5001,
	internal_auditor: 5002,
	obsidian_obliterator: 5003,
	giant_slayer: 5004,
	cult_crusher: 5005,
	demon_destroyer: 5006,
	elemental_exiles: 5007,
	shrimp_aberrancy: 5008,
	goblin_extermination: 5009,
	dark_necessities: 5010,
	giant_problems: 5011,
	elder_error: 5012,
	castle_hunting: 5013,
	archaeology_in_force: 5014,
	enjoying_demonic_architecture: 5015,
	chaotic_seas: 5016,
	hurricane_hunter: 5017,
	shrimp_god_slayer: 5018,
	vault_hunter: 5019,
	master_miner: 6001,
	castle: 6002,
	master_of_the_shrimp: 6003,
	claymore: 6004,
	its_definitely_spelled_mellon: 6005,
	destroying_history: 6006,
	feeling_lucky: 6007,
	runic_spires: 6008,
	legendary_roll: 7001,
	legendary_rerolls: 7002,
	prismatic_power: 7003,
	pity_prismatic: 7004,
	on_the_rainbow_road: 7005,
	burning_with_enthusiasm: 7006,
	burn_it_all: 7007,
	smithing_grandmaster: 7008,
	smithing_legend: 7009,
	cooking_grandmaster: 7010,
	cooking_legend: 7011,
	do_ya_membah: 7012,
	idling_like_a_champ: 8001
}
