import React, { useEffect, useState } from 'react';
import { socket } from '../../../services/socket.service';
import { Grid } from '@chakra-ui/react';
import { filterItem } from '../../../utils/helperFunctions';
import MarketplaceMyAuctionsOffer from './MarketplaceMyAuctionsOffer';
import { IMarketOffer } from '../../../../../game-server/src/repositories/MarketOffer.repository';
import { IItem, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { useRecoilValue } from 'recoil';
import { marketplaceFilterAtom } from '../../../atoms/marketplaceFilterAtom';

export default function MarketplaceOffers() {
	const [offerData, setOfferData] = useState<IMarketOffer[]>([]);
	const marketFilter = useRecoilValue(marketplaceFilterAtom);

	useEffect(() => {
		socket.emit('marketplace:offer:player:get');
		socket.on('marketplace:offer:player:send', (data) => {
			setOfferData(data);
		});
		return () => {
			socket.off('marketplace:offer:player:send');
		};
	}, []);

	function renderOffers() {
		const filteredList = offerData.filter((offer) => {
			const item: TPartialItemWithItemID<IItem> = {
				itemID: offer.itemID,
				augmentations: offer.augmentations ?? undefined,
				enchantmentID: offer.enchantment_id ?? undefined,
				enchantmentStrength: offer.enchantment_strength ?? undefined,
			};
			return filterItem(item, marketFilter);
		});
		return filteredList.map((offer) => {
			return <MarketplaceMyAuctionsOffer offer={offer} key={offer.offerId} />;
		});
	}

	return (
		<div className='marketplace-table anchor-buy-offers'>
			<Grid gridTemplateColumns='30% 15% 20% 20% 15%'>
				<div>Item</div>
				<div>Icon</div>
				<div>Amount</div>
				<div>Price (Total)</div>
				<div>Share</div>
			</Grid>
			{renderOffers()}
		</div>
	);
}
