import { List } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import { IItem, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { IStatTracker } from '../../../../../game-server/src/modules/stat-tracking/StatTracking.interface';
import { socket } from '../../../services/socket.service';
import { tabs } from '../../../utils/NavigationTabsList';
import FakeItem from '../../game/Inventory/FakeItem';

export const OfflineProgression = () => {
	const [showModal, setShowModal] = useState(false);
	const [offlineProgressItems, setOfflineProgressItems] = useState<IStatTracker['statValue'] | undefined>(undefined);
	const [offlineProgressExperience, setOfflineProgressExperience] = useState<IStatTracker['statValue'] | undefined>(
		undefined
	);
	const [offlineProgressTime, setOfflineProgressTime] = useState('Unspecified');

	useEffect(() => {
		socket.on('offline:progress', (data) => {
			setOfflineProgressItems(data.items);
			setOfflineProgressExperience(data.experience);
			setOfflineProgressTime(data.offlineTime);
			setShowModal(true);
		});
		return () => {
			socket.off('offline:progress');
		};
	}, []);

	function renderItems() {
		if (!offlineProgressItems || offlineProgressItems.length === 0) {
			return null;
		}
		// Sort offlineProgressItems by itemID
		offlineProgressItems.sort((a, b) => {
			const aID = Object.keys(a)[0];
			const bID = Object.keys(b)[0];
			return Number(aID) - Number(bID);
		});
		return (
			<div className='offline-progress-box all-items'>
				{offlineProgressItems &&
					offlineProgressItems.map((item, index) => {
						const itemID = Number(Object.keys(item)[0]);
						const stackSize = Object.values(item)[0];
						const fakeItem: TPartialItemWithItemID<IItem> = {
							itemID,
							stackSize,
						};
						return <FakeItem item={fakeItem} key={'offline-progress-item-' + index} />;
					})}
			</div>
		);
	}

	function renderExperience() {
		if (!offlineProgressExperience || offlineProgressExperience.length === 0) {
			return null;
		}
		return (
			<div className='offline-progress-box offline-progress-box-experience'>
				<List>
					{offlineProgressExperience &&
						offlineProgressExperience.map((skill, index) => {
							const skillName = Object.keys(skill)[0];
							const experience = Object.values(skill)[0] as number;
							return (
								<li key={'offline-progress-experience-' + index}>
									<img src={tabs[skillName]?.icon ?? ''} alt={skillName} />{' '}
									{Number(experience).toLocaleString('en-us')} experience
								</li>
							);
						})}
				</List>
			</div>
		);
	}

	return (
		<Dialog
			open={showModal}
			onClose={() => setShowModal(false)}
			className='donate-dialog feedback-dialog sell-item-dialog popup-dialog'
		>
			<DialogTitle>Offline Progress</DialogTitle>
			<DialogContentText> You were offline for {offlineProgressTime}.</DialogContentText>
			{renderItems()}
			{renderExperience()}
			<DialogActions>
				<IdlescapeButton
					onClick={() => setShowModal(false)}
					size={'medium'}
					variant={'red'}
					textAlign={'center'}
				>
					Close
				</IdlescapeButton>
			</DialogActions>
		</Dialog>
	);
};
