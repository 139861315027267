import { useLocalStorage } from 'usehooks-ts';
import { Resetter, useRecoilState, useResetRecoilState } from 'recoil';
import { IoRefresh } from 'react-icons/io5';
import { resetPlayAreaAtom } from '../../../atoms/resetPlayAreaAtom';
import { Box } from '@chakra-ui/react';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { combatZoneAtom } from '../../../atoms/combatZoneAtom';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import { marketplaceItemAtom } from '../../../atoms/marketplaceItemAtom';
import { drawerAtom } from '../../../atoms/drawerAtom';
import { Store } from 'react-notifications-component';

// Add more atoms that may need resetting
const atomList = [activeTabAtom, combatZoneAtom, drawerAtom, marketplaceItemAtom];

export const ResetUIButton = () => {
	const setMainGridColumns = useLocalStorage('main-grid-columns', '2fr 5px 1fr')[1];
	const setMainGridRows = useLocalStorage('main-grid-rows', '3fr 5px 1fr')[1];
	const setNavbarPinned = useLocalStorage('navbarPinned', false)[1];
	const [resetKey, setResetKey] = useRecoilState(resetPlayAreaAtom);
	const resetList: Resetter[] = [];
	for (const atom of atomList) {
		resetList.push(useResetRecoilState(atom));
	}

	function resetUI() {
		setMainGridColumns('2fr 5px 1fr');
		setMainGridRows('3fr 5px 1fr');
		setNavbarPinned(false);
		setResetKey(resetKey + 1);
		Store.removeAllNotifications();
		for (const resetAtom of resetList) {
			resetAtom();
		}
	}

	return (
		<IdlescapeWrappingTooltip content='Resets the UI to a default state'>
			<Box cursor='pointer' onClick={resetUI}>
				<IoRefresh />
			</Box>
		</IdlescapeWrappingTooltip>
	);
};
