import { AbsoluteCenter, Box, VStack, Image, Text, Grid } from '@chakra-ui/react';
import Tooltip from '../UI/Tooltip';
import Countdown from 'react-countdown';
import { IdlescapeButton, IdlescapePill } from '@idlescape/ui';
import { EnchantmentData } from '../../game/EnchantmentData';
import { IGlobalBuffExtraInfo } from '../../../../../game-server/src/modules/buffs/global/GlobalBuffManager';
import { usePlayerField } from '../../../hooks/hooks';
import { getSocket } from '../../../services/socket.service';

interface IBuffProps {
	enchantmentID: number;
	enchantmentStrength: number;
	stacks?: number;
	expires?: Date;
	source?: string;
	extraInfo?: IGlobalBuffExtraInfo;
}

function getFormattedSourceString(
	source: string | undefined,
	extraInfo: IGlobalBuffExtraInfo | undefined,
	shrineProgress: number
): { className: string; description: string } {
	switch (source) {
		case undefined:
			return { className: 'buff-personal', description: 'This buff came from you' };
		case 'shrine': {
			if (extraInfo?.shrineIndex !== undefined && extraInfo?.shrineIndex > shrineProgress) {
				return {
					className: 'buff-shrine-inactive',
					description: "You didn't contribute enough to the last shrine to get this effect",
				};
			}
			return { className: 'buff-shrine', description: 'The Shrine of Toucan has blessed you' };
		}
		case 'shrine-next':
			return { className: 'buff-shrine', description: 'The Shrine of Toucan will bless you' };
		case 'donation':
			return { className: 'buff-donation', description: 'A player donated for this buff' };
		case 'event':
			return { className: 'buff-event', description: 'This buff is from an event' };
		case 'Santa Claus':
			return { className: 'buff-christmas', description: source };
		case 'Madness':
			return { className: 'buff-madness', description: "Uncontrolled magic developed it's own will!" };
		default:
			return { className: 'buff-admin', description: source };
	}
}

export const Buff = (props: IBuffProps) => {
	const socket = getSocket();
	const shrineContribution = usePlayerField('shrineContribution');
	const settings = usePlayerField('settings');
	const alreadyPaused = settings.miscellaneous.blacklistedBuffs.includes(props.enchantmentID);
	const shrineProgress = shrineContribution.progress;
	const enchantmentData = EnchantmentData.findEnchantmentByID(props.enchantmentID);
	const { className, description } = getFormattedSourceString(props.source, props.extraInfo, shrineProgress);

	function pauseBuff() {
		socket.emit('settings:toggleBlacklistedBuff', props.enchantmentID);
	}

	function renderPauseButton() {
		// If the source includes 'League Buff' we can't pause it
		if (props.source?.includes('League Buff')) {
			return null;
		}
		return (
			<IdlescapeButton onClick={pauseBuff} mt={2} variant={alreadyPaused ? 'green' : 'red'}>
				{alreadyPaused ? 'Unpause' : 'Pause'}
			</IdlescapeButton>
		);
	}

	let filter = props.enchantmentStrength === 0 ? 'grayscale(75%)' : undefined;
	if (alreadyPaused) {
		filter = 'grayscale(100%)';
	}

	return (
		<Box
			borderRadius={'5px'}
			cursor={'pointer'}
			position={'relative'}
			padding={'2px'}
			width={'40px'}
			height={'40px'}
			border={'2px solid #573a6f'}
			backgroundColor={'#1e1829'}
			filter={filter}
			className={className}
			onClick={(e) => e.shiftKey && pauseBuff()}
		>
			<Image src={enchantmentData.buffIcon} height={'100%'} width={'100%'} />
			<AbsoluteCenter
				fontSize={'16px'}
				fontWeight={'bold'}
				bottom={'20%'}
				textShadow={'-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'}
			>
				{props.stacks?.toLocaleString('en-us', { notation: 'compact' })}
			</AbsoluteCenter>
			<Tooltip onClickHover={true}>
				<VStack textAlign={'center'}>
					<Text color={'rgb(201, 30, 207)'} fontSize={'1.5rem'} fontWeight={'bold'} margin={0}>
						{enchantmentData.name} {props.enchantmentStrength}
					</Text>
					<Text>{description}</Text>
					{props.stacks && <Text>{props.stacks.toLocaleString('en-us')} actions remaining</Text>}
					{props.expires && (
						<Countdown
							date={props.expires}
							renderer={({ days, hours, minutes, seconds }) => {
								return `${days}d ${hours}h ${minutes}min ${seconds}s`;
							}}
						/>
					)}
					<Text>
						{enchantmentData.getTooltip(props.enchantmentStrength, enchantmentData.strengthPerLevel)}
					</Text>
					{props.extraInfo?.donations && (
						<Grid>
							{Object.keys(props.extraInfo.donations).map((username) => (
								<IdlescapePill
									key={'platinum-shop-' + username}
									mt={'0 !important'}
									padding={'0 !important'}
									height={'20px'}
									minWidth={'150px'}
									lineHeight={'20px'}
									fontSize={'12px'}
									variant='dark'
									fontWeight={'bold'}
									textAlign={'left'}
									image='/images/premium/platinum_icon.png'
								>
									{props.extraInfo?.donations?.[username].toLocaleString('en-us')} {username}
								</IdlescapePill>
							))}
						</Grid>
					)}
					{renderPauseButton()}
				</VStack>
			</Tooltip>
		</Box>
	);
};
