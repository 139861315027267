import { useState } from 'react';
import { itemList } from '../../../utils/itemList';
import { locations } from '../../../utils/locationList';
import { Flex, Grid, Switch } from '@chakra-ui/react';
import { usePlayerEnchantmentStrength, usePlayerField } from '../../../hooks/hooks';
import {
	BASE_ACTIONS_COUNT,
	CLOTHWEAVING_CLOTH,
	PLUS_ACTIONS_COUNT,
	RUNECRAFTING_RUNES,
} from '../../../utils/constantsCollection';
import { forges } from '../../../utils/forgeList';
import { IdlescapeButton, IdlescapeNumberInput, IdlescapeSelect } from '@idlescape/ui';
import { getSocket } from '../../../services/socket.service';
import { enchantmentsIds } from '../../../utils/lookup-dictionaries/lookupEnchantmentList';
import './ActionQueue.css';
import ActionQueueItem from './ActionQueueItem';
import { IUserActionQueue } from '../../../../../game-server/src/modules/action-queue/UserActionQueue';

// Taken from Inspect.tsx
const skillIconLocations = {
	mining: '/images/mining/iron_pickaxe.svg',
	foraging: '/images/foraging/foraging_icon.png',
	fishing: '/images/fishing/fishing_logo.png',
	smithing: '/images/smithing/smithing_icon.png',
	//crafting: '/images/ui/crafting_icon.png',
	//cooking: '/images/cooking/cooking_icon.png',
	combat: '/images/combat/attack_icon.png',
	runecrafting: '/images/runecrafting/RuneCraftingIcon.png',
	enchanting: '/images/enchanting/enchanting_logo.png',
	//farming: '/images/farming/farming_icon.png',
	clothweaving: '/images/combat/materials/imbued_cloth.png',
	scrollcrafting: '/images/enchanting/enchanted_scroll.png',
};
const runecraftingIds = RUNECRAFTING_RUNES;
const clothweavingIds = CLOTHWEAVING_CLOTH;
const barIds = [201, 202, 203, 204, 205, 206, 207, 208, 9029];

export const ActionQueue = () => {
	const socket = getSocket();
	const subscription = usePlayerField('subscription');
	const isSubscribed = subscription?.active ?? false;
	const bonusActionQueueCount = usePlayerField('bonusActionQueueCount');
	const bonusActionQueueLength = usePlayerField('bonusActionQueueLength');
	const userActionQueueStart = usePlayerField('userActionQueueStart');
	const settings = usePlayerField('settings');
	const actionQueue = usePlayerField('actionQueue');

	const [selectedQueueIndex, setSelectedQueueIndex] = useState(0);

	const [selectedLength, setSelectedLength] = useState(0); // in minutes, it's converted to ms later by backend
	const [selectedCount, setSelectedCount] = useState(0);
	const [selectedLocation, setSelectedLocation] = useState(0);
	const [selectedOption1, setSelectedOption1] = useState(0);
	const [selectedOption2, setSelectedOption2] = useState(0);
	const [selectedOption3, setSelectedOption3] = useState(0);

	const metallurgyStrength = usePlayerEnchantmentStrength(enchantmentsIds.metallurgy, 'smithing');

	let numInputs = 1;
	function getMaxQueueCount() {
		if (isSubscribed) {
			return BASE_ACTIONS_COUNT + PLUS_ACTIONS_COUNT + bonusActionQueueCount + bonusActionQueueLength;
		}
		return BASE_ACTIONS_COUNT + bonusActionQueueCount + bonusActionQueueLength;
	}

	const queueList = settings.miscellaneous.userActionQueue;
	const queueCount = getMaxQueueCount();
	const usedCount = queueList.length;

	if (queueCount <= 1) {
		return (
			<div>
				You cannot use the Action Queue. You can unlock this feature by subscribing or with the relevant
				platinum tokens!
			</div>
		);
	}
	let index = 0;

	const optionList: Record<number, { label: string; image: string | undefined }>[] = [{}, {}, {}, {}];
	//Location
	for (const [skill, image] of Object.entries(skillIconLocations)) {
		const action = skill.charAt(0).toUpperCase() + skill.substring(1);
		optionList[0][index] = {
			label: action,
			image: image,
		};
		index++;
	}

	//Other options
	index = 0;
	const action = optionList[0][selectedLocation]?.label;
	switch (action) {
		case 'Mining':
		case 'Foraging':
		case 'Fishing':
			for (const location of Object.values(locations)) {
				if (action === location.actionType.replace('Action-', '')) {
					optionList[1][index] = {
						label: location.name,
						image: location.locationImage,
					};
					index++;
				}
			}
			numInputs = 2;
			break;
		case 'Combat':
			for (const location of Object.values(locations)) {
				if (action === location.actionType.replace('Action-', '') && location.locID >= 1000) {
					optionList[1][index] = {
						label: location.name,
						image: location.locationImage,
					};
					index++;
				}
			}
			numInputs = 2;
			break;
		case 'Smithing':
			{
				for (const forge in forges) {
					optionList[1][index] = { label: forges[forge].forgeName, image: forges[forge].image };
					index++;
				}
				numInputs = 2;

				const curForge = forgeByName(optionList[1][selectedOption1]?.label);
				if (curForge) {
					const maxIntensity = Math.floor(forges[curForge].forgeMaxIntensity + metallurgyStrength);
					index = 0;
					barIds.map((id) => {
						const barTier = Math.max(1, Math.round((itemList[id].level ?? 1) / 12.5));
						if (barTier <= maxIntensity) {
							optionList[2][index] = {
								label: itemList[id].name,
								image: itemList[id].itemImage,
							};
							index++;
						}
					});
					numInputs = 3;

					const curBar = itemByName(optionList[2][selectedOption2]?.label);
					if (curBar) {
						const barTier = Math.max(1, Math.round((itemList[curBar].level ?? 1) / 12.5));
						index = 0;
						for (let i = barTier; i <= maxIntensity; i++) {
							optionList[3][index] = {
								label: String(i),
								image: itemList[2].itemImage, //heat
							};
							index++;
						}
						numInputs = 4;
					}
				}
			}
			break;
		case 'Runecrafting':
			runecraftingIds.map((id) => {
				optionList[1][index] = {
					label: itemList[id].name,
					image: itemList[id].itemImage,
				};
				index++;
			});
			numInputs = 2;
			break;
		case 'Clothweaving':
			clothweavingIds.map((id) => {
				optionList[1][index] = {
					label: itemList[id].name,
					image: itemList[id].itemImage,
				};
				index++;
			});
			numInputs = 2;
			break;
		case 'Scrollcrafting':
			for (const id in itemList) {
				if (itemList[id].enchant && !itemList[id].hidden) {
					optionList[1][index] = {
						label: itemList[id].name,
						image: itemList[id].itemImage,
					};
					index++;
				}
			}
			numInputs = 2;
			break;
		default:
	}

	function validToAdd() {
		const currentCount = queueList.length ?? 0;
		return currentCount < queueCount;
	}
	function addCurrentToQueue() {
		socket.emit('action:queue:addCurrent', { length: selectedLength, count: selectedCount });
	}
	function addToQueue() {
		const action = optionList[0][selectedLocation].label;
		const option1 = optionList[1][selectedOption1] && optionList[1][selectedOption1].label;
		const option2 = optionList[2][selectedOption2] && optionList[2][selectedOption2].label;
		const option3 = optionList[3][selectedOption3] && optionList[3][selectedOption3].label;
		let location = 0;
		let forge = 0;
		let item = 0;
		let action2 = '';
		let options = {};
		switch (action) {
			case 'Mining':
			case 'Foraging':
			case 'Fishing':
			case 'Combat':
				location = locationByName(option1);
				break;
			case 'Smithing':
				if (!option1 || !option2 || !option3) return;
				location = locationByAction(action);
				forge = forgeByName(option1);
				item = itemByName(option2);
				options = {
					[action.toLowerCase()]: { ['forgeId']: forge, ['barId']: item, ['intensity']: option3 },
				};
				break;
			case 'Cooking':
			case 'Enchanting':
				location = locationByAction(action);
				break;
			case 'Runecrafting':
			case 'Clothweaving':
				if (!option1) return;
				action2 = 'Runecrafting';
				location = locationByAction(action2);
				item = itemByName(option1);
				options = { [action2.toLowerCase() + 'Id']: item };

				break;
			case 'Scrollcrafting':
				if (!option1) return;
				action2 = 'Enchanting';
				location = locationByAction(action2);
				item = itemByName(option1);
				options = { [action.toLowerCase() + 'Id']: item };

				break;
			default:
		}
		if (location < 0) {
			return;
		}
		const locationData = locations[location];
		if (!locationData) {
			return;
		}
		socket.emit('action:queue:add', { location, options, length: selectedLength, count: selectedCount });
	}
	function editQueue() {
		// If selectedQueueIndex is not in the queue range, return
		if (selectedQueueIndex < 0 || selectedQueueIndex >= queueList.length) {
			return;
		}
		socket.emit('action:queue:edit', { index: selectedQueueIndex, length: selectedLength, count: selectedCount });
	}
	function toggleQueue() {
		socket.emit('action:queue:toggle');
	}

	function locationByAction(action: string) {
		for (const loc in locations) {
			const locAction = locations[loc].actionType.replace('Action-', '');
			if (locAction === action) {
				return locations[loc].locID;
			}
		}
		return 0;
	}
	function locationByName(name: string) {
		for (const loc in locations) {
			if (locations[loc].name === name) {
				return locations[loc].locID;
			}
		}
		return 0;
	}
	function forgeByName(name: string) {
		for (const forge in forges) {
			if (forges[forge].forgeName === name) {
				return forges[forge].forgeID;
			}
		}
		return 0;
	}
	function itemByName(name: string) {
		for (const item in itemList) {
			if (itemList[item].name === name) {
				return itemList[item].id;
			}
		}
		return 0;
	}

	function select0(id: string) {
		setSelectedLocation(Number(id));
		return;
	}
	function select1(id: string) {
		setSelectedOption1(Number(id));
		return;
	}
	function select2(id: string) {
		setSelectedOption2(Number(id));
		return;
	}
	function select3(id: string) {
		setSelectedOption3(Number(id));
		return;
	}

	function showQueueButton() {
		return (
			<>
				<div className='settings-label'>Show queue button next to each skill</div>
				<Switch
					isChecked={settings.miscellaneous.showQueueButton}
					onChange={() =>
						socket.emit('settings:update', {
							value: !settings.miscellaneous.showQueueButton,
							path: 'miscellaneous.showQueueButton',
						})
					}
				/>
			</>
		);
	}

	function renderCurrentQueue() {
		if (!actionQueue) return null;
		const action: IUserActionQueue = {
			targetLocationID: actionQueue.location,
			timeToSpendHere: selectedLength * 60000,
			actionCount: selectedCount,
			additionalOptions: actionQueue.options,
		};
		return (
			<>
				Current Action:
				<Grid gridTemplateColumns='repeat(auto-fill, minmax(300px, 1fr))'>
					<ActionQueueItem
						action={action}
						index={-1}
						selected={false}
						addCurrent={usedCount < queueCount ? addCurrentToQueue : undefined}
					/>
				</Grid>
			</>
		);
	}

	function renderQueueList() {
		const repeat = settings.miscellaneous.queueRepeat;
		const actionList = queueList.map((action, index) => {
			return (
				<div key={index} onClick={() => setSelectedQueueIndex(index)}>
					<ActionQueueItem action={action} index={index} selected={selectedQueueIndex === index} />
				</div>
			);
		});
		return (
			<div>
				Repeating: {repeat ? 'Yes ' : 'No '}
				<Switch
					isChecked={settings.miscellaneous.queueRepeat}
					onChange={() =>
						socket.emit('settings:update', {
							value: !settings.miscellaneous.queueRepeat,
							path: 'miscellaneous.queueRepeat',
						})
					}
				/>
				<br />
				Queue Count: {usedCount} / {queueCount}
				<hr />
				<Grid gridTemplateColumns='repeat(auto-fill, minmax(300px, 1fr))' alignItems='stretch'>
					{actionList}
				</Grid>
			</div>
		);
	}

	return (
		<div>
			<Grid
				className='action-queue-selector'
				gridTemplateColumns={'repeat(auto-fill, 200px)'}
				alignItems={'center'}
			>
				<IdlescapeSelect options={optionList[0]} select={select0} selected={selectedLocation.toString()} />
				{numInputs >= 2 && (
					<IdlescapeSelect options={optionList[1]} select={select1} selected={selectedOption1.toString()} />
				)}
				{numInputs >= 3 && (
					<IdlescapeSelect options={optionList[2]} select={select2} selected={selectedOption2.toString()} />
				)}
				{numInputs >= 4 && (
					<IdlescapeSelect options={optionList[3]} select={select3} selected={selectedOption3.toString()} />
				)}
			</Grid>
			<Flex alignItems='center' gap='5px'>
				<IdlescapeButton
					variant={validToAdd() ? 'green' : 'disabled'}
					size='large'
					justifySelf={'center'}
					onClick={addToQueue}
				>
					Add to Queue
				</IdlescapeButton>
				{showQueueButton()}
			</Flex>
			<hr />
			<Grid gridTemplateColumns={'repeat(auto-fill, 200px)'} alignItems={'center'}>
				<Flex gap='10px' alignItems='center'>
					<IdlescapeNumberInput
						setValue={setSelectedLength}
						value={selectedLength}
						placeholder='∞'
						width='110px'
						min={0}
						maxLength={10}
					/>
					minutes
				</Flex>
				<Flex gap='10px' alignItems='center'>
					<IdlescapeNumberInput
						setValue={setSelectedCount}
						value={selectedCount}
						placeholder='∞'
						display='inline-block'
						width='110px'
						min={0}
						maxLength={10}
					/>
					amount
				</Flex>
				<IdlescapeButton
					variant={selectedCount + selectedLength ? 'orange' : 'disabled'}
					size='large'
					justifySelf={'center'}
					onClick={editQueue}
				>
					Change Duration
				</IdlescapeButton>
				<IdlescapeButton
					variant={userActionQueueStart ? 'red' : 'green'}
					size='large'
					justifySelf={'center'}
					onClick={toggleQueue}
				>
					{userActionQueueStart ? 'Stop Queue' : 'Start Queue'}
				</IdlescapeButton>
			</Grid>
			{renderCurrentQueue()}
			<hr />
			{renderQueueList()}
		</div>
	);
};

export default ActionQueue;
