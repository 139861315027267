import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeNumberInput, IdlescapeWrappingTooltip } from '@idlescape/ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';
import { IGameShopListData } from '../../../../../game-server/src/modules/game-shop/GameShop.interface';
import { socket } from '../../../services/socket.service';
import { itemList } from '../../../utils/itemList';

export default function GeneralShopItem(props: { gameShopItem: IGameShopListData; gold: number }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [amountToBuy, setAmountToBuy] = useState(1);
	const [disabled, setDisabled] = useState(false);

	const itemData = itemList[props.gameShopItem.itemID];
	const name = props.gameShopItem.customName || itemData.name;
	const maxAmount = Math.floor(
		Math.min(
			props.gameShopItem.purchaseLimits?.maxAmountPerReset || Infinity,
			props.gold / props.gameShopItem.price
		)
	);

	function buyUpgrade() {
		if (props.gameShopItem.price <= props.gold) {
			onOpen();
		}
	}

	function buyItems() {
		onClose();
		setDisabled(true);
		if (amountToBuy === 0) return;
		socket.emit('game-shop:buy:item', { id: props.gameShopItem.id, amount: amountToBuy });
	}

	return (
		<>
			<IdlescapeWrappingTooltip content={<Text textAlign='center'>{props.gameShopItem.description}</Text>}>
				<div className='game-shop-item' onClick={buyUpgrade} data-itemid={props.gameShopItem.itemID}>
					{name}
					<img className='game-shop-image' src={props.gameShopItem.image} alt={name}></img>
					<div className='game-shop-item-price'>
						{props.gameShopItem.price !== 0 && (
							<Text as='span' color={props.gameShopItem.price > props.gold ? 'red' : ''}>
								{props.gameShopItem.price.toLocaleString('en-us')}
								<img src='/images/gold_coin.png' alt='Price' className='icon16' />
							</Text>
						)}
					</div>
				</div>
			</IdlescapeWrappingTooltip>
			<Dialog
				open={isOpen}
				onClose={onClose}
				className='sell-item-dialog'
				TransitionProps={{ onExit: () => setDisabled(false) }}
			>
				<DialogTitle>Shop</DialogTitle>
				{props.gameShopItem.allowBulkPurchase ? (
					<DialogContent>
						<Box minWidth='300px' textAlign='center'>
							<Text>How many {itemData.name} do you want to buy?</Text>
							<IdlescapeNumberInput
								value={amountToBuy}
								setValue={setAmountToBuy}
								min={1}
								max={maxAmount}
								minWidth='100px'
								width='fit-content'
								margin='0 auto'
								onKeyUp={(e) => {
									if (e.key === 'Enter') {
										buyItems();
									}
								}}
							/>
						</Box>
						<Text textAlign={'center'}>
							This will cost you: {(amountToBuy * props.gameShopItem.price).toLocaleString('en-us')}{' '}
							<img src='/images/gold_coin.png' alt='Gold' className='icon16' />
						</Text>
					</DialogContent>
				) : (
					<DialogContentText>
						You are about to buy {name} for {props.gameShopItem.price.toLocaleString('en-us')}{' '}
						<img src='/images/gold_coin.png' alt='Gold' className='icon16' />
					</DialogContentText>
				)}
				<DialogActions>
					<IdlescapeButton variant='red' onClick={onClose}>
						Close
					</IdlescapeButton>
					<IdlescapeButton variant={disabled ? 'disabled' : 'green'} onClick={buyItems}>
						Buy
					</IdlescapeButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
