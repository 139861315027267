import { Image } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import React from 'react';
import {
	IChatMessage,
	ISystemMessage,
	IWhisperMessage,
} from '../../../../../game-server/src/modules/chat/Chat.interface';
import { chatIconList } from '../../../utils/chatIconList';

function ChatIcon({
	iconID,
	message,
}: {
	iconID: number | null;
	message: (IChatMessage | IWhisperMessage | ISystemMessage) & {
		read?: boolean;
		hasMention?: boolean;
	};
}) {
	if (iconID === null || message.visualClass === 'me-message') return null;

	const iconData = chatIconList[iconID];
	if (!iconData) return null;

	const tooltip = (
		<div style={{ textAlign: 'center' }}>
			{iconData.name}
			<hr />
			<Image
				className={iconData.css ?? ''}
				src={iconData.source}
				alt={iconData.name}
				maxWidth='100px'
				maxHeight='100px'
				display='block'
				margin='auto'
			/>
		</div>
	);
	return (
		<IdlescapeWrappingTooltip content={tooltip}>
			<Image
				className={iconData.css ?? undefined}
				src={iconData.source}
				alt={iconData.name}
				verticalAlign='top'
				height='1em'
			/>
		</IdlescapeWrappingTooltip>
	);
}

export default ChatIcon;
