import { BaseTooltip } from './BaseTooltip';
import { TTooltipStats } from '../Data/TooltipData';
import { IItem, IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { TSkillName } from '../../../../../../game-server/src/Atypes/Skills';
import { itemList } from '../../../../utils/itemList';
import { isMystSeed } from '../../../../utils/farmingFunctions';
import { getTimeString } from '../../../../utils/helperFunctions';
import { farmingLootTable } from '../../../../utils/lootTableFarming';

export class SeedTooltip extends BaseTooltip {
	protected getItemType(): string {
		return 'Seed';
	}

	protected getRequiredStatsLevel(item: IItem, itemResource: IItemData): Partial<Record<TSkillName, number>> {
		if (itemResource.farmingStats?.requiredLevel === undefined) return {};

		return { farming: itemResource.farmingStats.requiredLevel };
	}

	protected getStats(
		item: IItem,
		itemResource: IItemData
	): Partial<Record<TTooltipStats, Record<string, string | number>>> | undefined {
		const seedStats: Record<string, string | number> = {};
		if (!itemResource.farmingStats?.experience && !itemResource.farmingStats?.time) return { seedStats: seedStats };

		seedStats['seed.experience'] = itemResource.farmingStats.experience;
		let seedSize = `${itemResource.farmingStats.width}x${itemResource.farmingStats.height}`;
		if (itemResource.farmingStats.maxWidth && itemResource.farmingStats.maxHeight) {
			seedSize += ` - ${itemResource.farmingStats.maxWidth}x${itemResource.farmingStats.maxHeight}`;
		}
		seedStats['seed.plotSize'] = seedSize;
		seedStats['seed.harvestTime'] = getTimeString(itemResource.farmingStats.time * 60);

		return { seedStats: seedStats };
	}

	protected getYield(itemResource: IItemData) {
		const yieldStats: { name: string; productionMin: number; productionMax: number; chance: number }[] = [];

		const seedYield = farmingLootTable[itemResource.id];
		if (seedYield && !isMystSeed(itemResource.id)) {
			seedYield.forEach((production) => {
				yieldStats.push({
					name: itemList[production.id].name,
					productionMin: production.min,
					productionMax: production.max,
					chance: production.chance,
				});
			});
		}

		return yieldStats;
	}
}
