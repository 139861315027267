import React, { useEffect } from 'react';
import CombatGearInventory from './CombatGearInventory';
import CombatGear from './CombatGear';
import { CombatZones } from './CombatZones';
import './Combat.css';
import './ItemVisuals.css';
import { CombatFight } from './CombatFight';
import { usePlayerField } from '../../../hooks/hooks';
import { LoadoutEditor } from './Loadout/LoadoutEditor';
import { combatZoneAtom } from '../../../atoms/combatZoneAtom';
import { useRecoilState } from 'recoil';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';

export const Combat = () => {
	const actionQueue = usePlayerField('actionQueue');
	const [combatZone, setCombatZone] = useRecoilState(combatZoneAtom);
	const inCombat = actionQueue && actionQueue.actionType === 'Action-Combat';

	useEffect(() => {
		if (inCombat) {
			setCombatZone('combat');
		} else {
			if (combatZone === 'combat') {
				setCombatZone('equipment');
			}
		}
	}, [inCombat]);

	useEffect(() => {
		// If it's the initial render, change it back if it was changed in the above effect
		if (typeof combatZone === 'number') {
			setCombatZone(combatZone);
		}
		if (!inCombat && combatZone === 'combat') {
			setCombatZone('equipment');
		}
		return () => {
			if (inCombat) {
				setCombatZone('combat');
			} else {
				setCombatZone('equipment');
			}
		};
	}, []);

	if (combatZone === 'combat') {
		return (
			<>
				<IdlescapeButton
					position='absolute'
					variant='blue'
					right='10px'
					zIndex='999'
					onClick={() => {
						setCombatZone(0);
					}}
				>
					Back
				</IdlescapeButton>
				<CombatFight />
			</>
		);
	}

	return (
		<div className='core-container-combat'>
			<div className='combat-container-buttons'>
				<div
					className='combat-container-button idlescape-button idlescape-button-green'
					onClick={() => {
						setCombatZone('equipment');
					}}
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/equipment_icon.png' alt='Equipment' />
					Equipment
				</div>
				<div
					className='combat-container-button idlescape-button idlescape-button-orange'
					onClick={() => {
						setCombatZone('loadout');
					}}
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/equipment_icon.png' alt='Loadouts' />
					Loadouts
				</div>
				<div
					className='combat-container-button idlescape-button idlescape-button-blue'
					onClick={() => {
						setCombatZone('inventory');
					}}
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/inventory_icon.png' alt='Inventory' />
					Inventory
				</div>
				<div
					className='combat-container-button idlescape-button idlescape-button-gray'
					onClick={() => {
						setCombatZone('fight');
					}}
				>
					<img className='nav-tab-icon icon-border' src='/images/combat/combat_level.png' alt='Fight' />
					Fight
				</div>
				{inCombat && (
					<div
						className='combat-container-button idlescape-button idlescape-button-red'
						onClick={() => {
							setCombatZone('combat');
						}}
					>
						<img className='nav-tab-icon icon-border' src='/images/combat/combat_level.png' alt='Fight' />
						Back to Combat
					</div>
				)}
			</div>
			<div className='combat-container-content'>
				{combatZone === 'equipment' && (
					<IdlescapeContainer>
						<CombatGear />
					</IdlescapeContainer>
				)}
				{combatZone === 'inventory' && <CombatGearInventory />}
				{combatZone === 'loadout' && <LoadoutEditor />}
				{(combatZone === 'fight' || typeof combatZone === 'number') && <CombatZones />}
			</div>
		</div>
	);
};
