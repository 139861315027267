import React, { useEffect, useState } from 'react';
import MarketplaceMyAuctionsItem from './MarketplaceMyAuctionsItem';
import { socket } from '../../../services/socket.service';
import { Grid } from '@chakra-ui/react';
import { filterItem } from '../../../utils/helperFunctions';
import { IMarketListing } from '../../../../../game-server/src/repositories/MarketListing.repository';
import { useRecoilValue } from 'recoil';
import { marketplaceFilterAtom } from '../../../atoms/marketplaceFilterAtom';

export default function MarketplaceListings() {
	const [listingData, setListingData] = useState<IMarketListing[]>([]);
	const marketFilter = useRecoilValue(marketplaceFilterAtom);

	useEffect(() => {
		socket.emit('marketplace:listings:player:get');
		socket.on('marketplace:listings:player:send', (data) => {
			setListingData(data);
		});
		return () => {
			socket.off('marketplace:listings:player:send');
		};
	}, []);

	function renderAuctions() {
		const filteredList = listingData.filter((auction) => filterItem(auction.item, marketFilter));
		return filteredList.map((auction) => {
			return <MarketplaceMyAuctionsItem listing={auction} key={auction.listingId} />;
		});
	}

	return (
		<div className='marketplace-table anchor-sell-listings'>
			<Grid gridTemplateColumns='30% 15% 20% 20% 15%'>
				<div>Item</div>
				<div>Icon</div>
				<div>Amount</div>
				<div>Price</div>
				<div>Share</div>
			</Grid>
			{renderAuctions()}
		</div>
	);
}
