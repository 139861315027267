import { Heading, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { gatheringNodeAtom } from '../../../../atoms/gatheringNodeAtom';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { locations } from '../../../../utils/locationList';

export function ForagingInfo() {
	const actionQueue = usePlayerField('actionQueue');
	const [nodeData, setNodeData] = useRecoilState(gatheringNodeAtom);

	useEffect(() => {
		socket.on('animation:start', (data) => {
			setNodeData(data.node);
		});
		return () => {
			socket.off('animation:start');
		};
	}, []);

	useEffect(() => {
		if (!actionQueue) {
			setNodeData(undefined);
		}
	}, [!actionQueue]);

	function getCurrentNodeInformation() {
		if (!actionQueue || actionQueue.actionType !== 'Action-Foraging') {
			return (
				<>
					<Heading className='fishing-info-title-border' size='md'>
						Waiting for Node...
					</Heading>
					<p>Find a node!</p>
				</>
			);
		}
		return (
			<>
				<Heading className='fishing-info-title-border' size='md'>
					Current Node
				</Heading>
				<p>
					Zone: {locations[actionQueue.location].name}
					<br />
					Node:{' '}
					<Text as='span' color='green'>
						{nodeData?.nodeID ?? 'Searching...'}
					</Text>
					<br />
					Remaining: {nodeData?.resourcesLeft ?? 0}
				</p>
			</>
		);
	}

	return (
		<div className='foraging-info idlescape-container idlescape-container-green'>{getCurrentNodeInformation()}</div>
	);
}
