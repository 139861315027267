import { Grid } from '@chakra-ui/react';
import {
	defaultDropAnimationSideEffects,
	DndContext,
	DragEndEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { IdlescapeContainer } from '@idlescape/ui';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { TTrueInventoryType } from '../../../../../../../game-server/src/modules/items/Inventory.interface';
import { IItem } from '../../../../../../../game-server/src/modules/items/items.interface';
import { usePlayerField } from '../../../../../hooks/hooks';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { socket } from '../../../../../services/socket.service';
import { validToScrap } from '../../../../../utils/augmentingFunctions';
import { itemList } from '../../../../../utils/itemList';
import { leagueList } from '../../../../../utils/leagueList';
import { Item } from '../../../Inventory/Item';
import ScrappingItemInfo from './ScrappingItemInfo';
import ScrappingQueue from './ScrappingQueue';
import ScrappingStockpile from './ScrappingStockpile';

export function toggleScrapQueue(
	itemInventoryId: number,
	inventoryName: TTrueInventoryType,
	itemAmount: number,
	newIndex?: number
) {
	if (inventoryName !== 'stockpile' && inventoryName !== 'scrapQueue') {
		return;
	}
	socket.emit('inventory:scrapToggle', {
		inventoryItemID: itemInventoryId,
		sourceInventory: inventoryName,
		itemAmount: itemAmount,
		newIndex: newIndex,
	});
}

function Scrapping() {
	const originalScrapQueue = usePlayerField('scrapQueue');
	const [scrapQueue, setScrapQueue] = useState(originalScrapQueue);
	const scrapQueueIDs = scrapQueue.map((item) => item.id.toString());
	const originalStockpile = usePlayerField('stockpile');
	const [stockpile, setStockpile] = useState(originalStockpile.filter((item) => validToScrap(item, itemList)));
	const stockpileIDs = stockpile.map((item) => item.id.toString());

	const [activeItem, setActiveItem] = useState<IItem | null>(null);
	const [previewItem, setPreviewItem] = useState<IItem | null>(null);
	const [itemOrigin, setItemOrigin] = useState<null | 'stockpile' | 'scrapQueue'>(null);

	const isMobile = useIsMobile();

	const sensors = useSensors(
		useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
		useSensor(TouchSensor, { activationConstraint: { delay: 100, tolerance: 5 } })
	);

	useEffect(() => {
		setScrapQueue((queue) => {
			if (!activeItem) return originalScrapQueue;
			const activeInQueue = queue.some((item) => item.id === activeItem.id);
			const activeInOriginalQueue = originalScrapQueue.some((item) => item.id === activeItem.id);
			if (activeInQueue && !activeInOriginalQueue) {
				return [...originalScrapQueue, activeItem];
			} else if (!activeInQueue && activeInOriginalQueue) {
				return originalScrapQueue.filter((item) => item.id !== activeItem?.id);
			}
			return originalScrapQueue;
		});
	}, [activeItem, originalScrapQueue]);

	useEffect(() => {
		setStockpile((stockpile) => {
			const filteredOriginalStockpile = originalStockpile.filter((item) => validToScrap(item, itemList));
			if (!activeItem) return filteredOriginalStockpile;
			const activeInStockpile = stockpile.some((item) => item.id === activeItem.id);
			const activeInOriginalStockpile = filteredOriginalStockpile.some((item) => item.id === activeItem.id);
			if (activeInStockpile && !activeInOriginalStockpile) {
				return [...filteredOriginalStockpile, activeItem];
			} else if (!activeInStockpile && activeInOriginalStockpile) {
				return filteredOriginalStockpile.filter((item) => item.id !== activeItem?.id);
			}
			return filteredOriginalStockpile;
		});
	}, [activeItem, originalStockpile]);

	function handleOnClick(event: React.MouseEvent, item: IItem, inventoryName: TTrueInventoryType) {
		if (event.shiftKey) {
			toggleScrapQueue(item.id, inventoryName, 1);
			return true;
		}
		return false;
	}

	function handleDragStart({ active }: DragStartEvent) {
		let activeItem = stockpile.find((item) => item.id.toString() === active.id);
		if (activeItem) {
			setItemOrigin('stockpile');
			setActiveItem(activeItem);
			if (previewItem?.id === activeItem.id) {
				setPreviewItem(null);
			}
		}
		activeItem = scrapQueue.find((item) => item.id.toString() === active.id);
		if (activeItem) {
			setItemOrigin('scrapQueue');
			setActiveItem(activeItem);
			if (previewItem?.id === activeItem.id) {
				setPreviewItem(null);
			}
		}
	}

	function handleDragOver({ active, over }: DragOverEvent) {
		// We need to move the item in/out of the queue, when switching between the two lists
		// while still dragging, for the preview to work
		const activeId = String(active?.id);
		const overId = String(over?.id);
		if (overId === null) return;

		if (
			overId !== 'scrapQueue' &&
			scrapQueueIDs.includes(activeId) &&
			(overId === 'scrapQueue' || stockpileIDs.includes(overId))
		) {
			removeItem(activeId);
		} else if (
			overId !== 'scrapStockpile' &&
			!scrapQueueIDs.includes(activeId) &&
			(overId === 'scrapQueue' || scrapQueueIDs.includes(overId))
		) {
			addItem(activeId);
		}
	}

	function handleDragEnd({ active, over }: DragEndEvent) {
		setActiveItem(null);
		if (!over) {
			return;
		}
		const activeId = String(active.id);
		const overId = String(over?.id);
		if (!activeId || !overId || !activeItem) {
			return;
		}
		if (overId === 'scrapQueue' || scrapQueueIDs.includes(overId)) {
			const oldIndex = scrapQueueIDs.indexOf(activeId);
			let newIndex = scrapQueueIDs.indexOf(overId);
			if (newIndex === -1) {
				newIndex = scrapQueueIDs.length - 1;
			}
			if (itemOrigin === 'stockpile') {
				toggleScrapQueue(activeItem.id, 'stockpile', activeItem.stackSize, newIndex);
			} else if (itemOrigin === 'scrapQueue') {
				if (newIndex !== oldIndex) {
					const correctedIndex = oldIndex < newIndex ? newIndex + 1 : newIndex;
					socket.emit('inventory:scrap:move', {
						inventoryItemID: Number(activeId),
						newIndex: correctedIndex,
					});
				}
			}
			setScrapQueue((queue) => arrayMove(queue, oldIndex, newIndex));
		} else if (overId === 'scrapStockpile' || stockpileIDs.includes(overId)) {
			if (itemOrigin === 'scrapQueue') {
				toggleScrapQueue(activeItem.id, 'scrapQueue', activeItem.stackSize);
			}
		}
	}

	function addItem(itemId: string) {
		const numberId = Number(itemId);
		const item = stockpile.find((item) => item.id === numberId);
		if (!item) return;
		setScrapQueue((queue) => [...queue, item]);
		setStockpile((stockpile) => stockpile.filter((item) => item.id !== numberId));
	}

	function removeItem(itemId: string) {
		const numberId = Number(itemId);
		const item = scrapQueue.find((item) => item.id === numberId);
		if (!item) return;
		setScrapQueue((queue) => queue.filter((item) => item.id !== numberId));
		setStockpile((stockpile) => [...stockpile, item]);
	}

	return (
		<Grid gridTemplateColumns={isMobile ? '' : 'repeat(3,1fr)'} minHeight='calc(100% - 35px)'>
			<ScrappingItemInfo previewItem={previewItem} />
			<DndContext
				onDragStart={handleDragStart}
				onDragOver={handleDragOver}
				onDragEnd={handleDragEnd}
				sensors={sensors}
			>
				<SortableContext items={scrapQueueIDs}>
					<ScrappingQueue
						items={scrapQueue}
						onClick={(e, item) => handleOnClick(e, item, 'scrapQueue')}
						previewState={[previewItem, setPreviewItem]}
						isDragging={activeItem !== null}
					/>
				</SortableContext>
				<SortableContext items={stockpileIDs}>
					<ScrappingStockpile
						items={stockpile}
						onClick={(e, item) => handleOnClick(e, item, 'stockpile')}
						isDragging={activeItem !== null}
					/>
				</SortableContext>
				{createPortal(
					<DragOverlay
						dropAnimation={{
							sideEffects: defaultDropAnimationSideEffects({
								styles: {
									active: {
										opacity: '0.5',
									},
								},
							}),
						}}
					>
						{activeItem ? <Item item={activeItem} cursor='grabbing' showTooltip={false} /> : null}
					</DragOverlay>,
					document.getElementsByClassName('game-container')[0] as HTMLElement
				)}
			</DndContext>
		</Grid>
	);
}

export default Scrapping;
