import React, { ReactElement, useEffect, useState } from 'react';
import { usePlayerField } from '../../../../hooks/hooks';
import { LoadoutSlot } from './LoadoutSlot';
import './Loadout.css';
import { ILoadout } from '../../../../../../game-server/src/modules/loadout/loadout.interface';
import { socket } from '../../../../services/socket.service';
import { locations } from '../../../../utils/locationList';
import { IdlescapeButton, IdlescapeContainer, IdlescapeInput } from '@idlescape/ui';
import LoadoutView from './LoadoutView';
import { getLoadoutIcon } from '../../../../utils/helperFunctions';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Flex,
	FormLabel,
	Grid,
	Heading,
	Image,
	Switch,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { shareItemSetSelector } from '../../../../atoms/chatInputAtom';
import ConfirmationModal from '../../../layout/UI/FastModal';
import { baseLocations } from '../CombatZones';

const templateLoadoutData: ILoadout = {
	loadoutId: 0,
	loadoutName: 'Empty',
	loadoutRelatedZones: [],
	loadoutEquipment: [],
	loadoutInventory: [],
	loadoutAbilityRotation: [],
	loadoutIconItemId: 0,
	ignoresTools: false,
};

const loadoutButtonStyle = {
	minWidth: '100px',
	maxWidth: '200px',
	width: '100%',
	height: '45px',
};

export const LoadoutEditor = () => {
	const loadouts = usePlayerField('loadouts');
	const bonusLoadoutSlots = usePlayerField('bonusLoadoutSlots');
	const settings = usePlayerField('settings');
	const [selectedLoadoutId, setSelectedLoadoutId] = useState(loadouts[0]?.loadoutId || 0);
	const selectedLoadout = loadouts.find((loadout) => loadout.loadoutId === selectedLoadoutId);
	const [customName, setCustomName] = useState(undefined);
	const [ignoresTools, setIgnoresTools] = useState(selectedLoadout?.ignoresTools ?? false);
	const shareLoadout = useSetRecoilState(shareItemSetSelector);
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		socket.on('inventory:selectLoadout', (loadoutId: number) => {
			setSelectedLoadoutId(loadoutId);
		});

		return () => {
			socket.off('inventory:selectLoadout');
		};
	}, []);

	useEffect(() => {
		if (selectedLoadoutId !== 0) {
			setIgnoresTools(selectedLoadout?.ignoresTools ?? false);
		} else {
			setIgnoresTools(false);
		}
	}, [selectedLoadout]);

	const currentLoadoutsAmount = loadouts.length;

	let loadoutsUnlocked = 0;
	const subscribed = usePlayerField('subscription');
	if (subscribed.active) {
		loadoutsUnlocked = 5;
	}
	const bonusLoadouts = 0;
	loadoutsUnlocked += bonusLoadouts;

	// 5 is our base loadout count for everyone
	const maxLoadouts = 5 + loadoutsUnlocked + bonusLoadoutSlots;

	const templateLoadouts: ReactElement[] = [];
	for (let i = 0; i < maxLoadouts - currentLoadoutsAmount; i++) {
		templateLoadouts.push(
			<LoadoutSlot
				setSelectedLoadoutId={setSelectedLoadoutId}
				key={i}
				loadout={templateLoadoutData}
				icon={{ icon: '/images/premium/premium_icon.svg', alt: 'Empty Slot' }}
				isSelected={false}
			/>
		);
	}

	const selectedLoadoutData =
		loadouts.find((loadout) => loadout.loadoutId === selectedLoadoutId) || templateLoadoutData;

	function createLoadout() {
		socket.emit('inventory:createLoadout', {
			loadoutName: customName || 'Empty',
			ignoresTools: ignoresTools,
		});
		setCustomName(undefined);
	}

	const saveDialog = (
		<ConfirmationModal
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={saveLoadout}
			header={`Save ${selectedLoadout?.loadoutName}`}
		>
			Are you sure you want to overwrite this loadout?
		</ConfirmationModal>
	);

	function saveLoadout() {
		socket.emit('inventory:saveLoadout', {
			loadoutID: selectedLoadoutId,
			loadoutName: customName,
			saveEquipmentAndInventory: true,
			saveAbilityRotation: true,
			ignoresTools: ignoresTools,
		});
		setCustomName(undefined);
		onClose();
	}

	function loadLoadout() {
		socket.emit('inventory:equipLoadout', { loadoutID: selectedLoadoutId });
	}

	function deleteLoadout() {
		socket.emit('inventory:deleteLoadout', { loadoutID: selectedLoadoutId });
		setSelectedLoadoutId(loadouts[0]?.loadoutId || 0);
	}

	function markLoadout(locationID: number) {
		socket.emit('inventory:markLoadout', {
			loadoutID: selectedLoadoutId,
			newLocationIDs: [locationID],
		});
	}

	function markCategory(locationIDs: number[]) {
		socket.emit('inventory:markLoadout', {
			loadoutID: selectedLoadoutId,
			newLocationIDs: locationIDs,
		});
	}

	function toggleMercenary(inputID?: number) {
		socket.emit('settings:mercLoadout', inputID ?? selectedLoadoutId);
	}

	let locationsObj: ReactElement[] = [];
	if (selectedLoadoutId !== 0) {
		const validLocations: {
			category: string;
			locationID: number;
			locationName: string;
			duration: number;
			requiredLevel: number;
			bestiaryLocation: number;
		}[] = [];
		// iterate through all the location values
		const blacklistedLocations = [1, 2, 3, 4, 5, 200, 201, 202];
		const eliteRange = [2000, 2999];
		const dungeonRange = [3000, 3999];
		for (const locIdx in locations) {
			const location = locations[locIdx];
			if (blacklistedLocations.includes(location.locID)) continue;
			const recommendedLevel = Number(location.extraTooltipInfo?.split(/[-+]/)[0]);
			const validLoc = {
				category: location.actionType.replace('Action-', ''),
				locationID: location.locID,
				locationName: location.name,
				duration: location.baseDuration,
				requiredLevel:
					location.accessRequirements?.requiredSkills?.[0]?.level ??
					(isNaN(recommendedLevel) ? 0 : recommendedLevel),
				bestiaryLocation: location.bestiaryBaseLocationID ?? location.locID,
			};
			// if the location is an elite zone, change the category to 'Elite Combat'
			if (location.locID >= eliteRange[0] && location.locID <= eliteRange[1]) {
				validLoc.category = 'Elite Combat';
			}
			// if the location is a dungeon, change the category to 'Dungeon'
			if (location.locID >= dungeonRange[0] && location.locID <= dungeonRange[1]) {
				validLoc.category = 'Dungeon';
			}
			validLocations.push(validLoc);
		}
		// Sort the locations by category, then by level, then by duration
		validLocations.sort((a, b) => {
			if (a.category < b.category) return -1;
			if (a.category > b.category) return 1;
			if (a.category !== 'Combat' && a.category !== 'Elite Combat' && a.requiredLevel !== b.requiredLevel)
				return a.requiredLevel - b.requiredLevel;
			const indexA = baseLocations.findIndex((i) => i === a.bestiaryLocation);
			const indexB = baseLocations.findIndex((i) => i === b.bestiaryLocation);
			if (indexA !== indexB) return indexA - indexB;
			if (a.duration < b.duration) return -1;
			if (a.duration > b.duration) return 1;
			return 0;
		});

		const locationCategories = validLocations.reduce((acc, curr) => {
			if (!(curr.category in acc)) {
				acc[curr.category] = [];
			}
			acc[curr.category].push(curr);
			return acc;
		}, {} as { [key: string]: typeof validLocations });

		locationsObj = Object.keys(locationCategories).map((category) => {
			return (
				<React.Fragment key={category}>
					<Grid gap='5px' alignItems='center' justifyContent='center' gridTemplateColumns='100px 1fr 100px'>
						<Heading size='small' textAlign='center' gridColumn='2 / 3'>
							{category}
						</Heading>
						{locationCategories[category].length > 1 && (
							<IdlescapeButton
								variant='green'
								width='auto'
								onClick={() => markCategory(locationCategories[category].map((e) => e.locationID))}
								gridColumn='3 / 4'
							>
								Toggle All
							</IdlescapeButton>
						)}
					</Grid>
					<Grid
						key={`location-grid-${category}`}
						gridTemplateColumns='repeat(auto-fill, minmax(200px, 1fr))'
						columnGap='10px'
						justifyContent='center'
					>
						{locationCategories[category].map((e, i) => {
							const connectedLoadout = loadouts.find((l) => l.loadoutRelatedZones.includes(e.locationID));
							const variant = connectedLoadout
								? connectedLoadout.loadoutId === selectedLoadoutId
									? 'green'
									: 'red'
								: 'orange';
							const icon = connectedLoadout ? getLoadoutIcon(connectedLoadout) : undefined;
							return (
								<IdlescapeButton
									key={i}
									variant={variant}
									onClick={() => markLoadout(e.locationID)}
									size='large'
									justifyContent='start'
									width='100%'
								>
									{connectedLoadout && (
										<Image
											src={icon?.icon}
											alt={icon?.alt}
											width='20px'
											height='20px'
											objectFit='contain'
											filter='drop-shadow(2px 2px 2px black)'
											paddingRight='5px'
										/>
									)}
									<Text textOverflow='ellipsis' overflow='hidden'>
										{e.locationName}
									</Text>
								</IdlescapeButton>
							);
						})}
					</Grid>
				</React.Fragment>
			);
		});
	}
	const mercsObj: ReactElement[] = [];
	if (selectedLoadoutId !== 0) {
		let currentMercList = '';
		let mercCount = 0;
		for (const merc of settings.combat.mercLoadouts) {
			const loadout = loadouts.find((l) => l.loadoutId === merc);
			if (loadout) {
				mercCount++;
				if (currentMercList === '') {
					currentMercList = 'Mercenaries: ';
				} else {
					currentMercList += ', ';
				}
				currentMercList += `${mercCount}- ${loadout.loadoutName}`;
				mercsObj.push(
					<IdlescapeButton
						key={merc}
						variant={merc === selectedLoadoutId ? 'green' : 'blue'}
						onClick={() => toggleMercenary(loadout.loadoutId)}
						width='auto'
					>
						{mercCount} - {loadout.loadoutName}
					</IdlescapeButton>
				);
			}
		}
	}

	return (
		<div className='loadout-container'>
			<div className='loadout-slots'>
				{loadouts.map((loadout, index) => {
					const icon = getLoadoutIcon(loadout);
					return (
						<LoadoutSlot
							key={index}
							setSelectedLoadoutId={setSelectedLoadoutId}
							loadout={loadout}
							icon={icon}
							isSelected={selectedLoadoutId === loadout.loadoutId}
						/>
					);
				})}
				{templateLoadouts}
			</div>

			<IdlescapeInput
				type='text'
				textAlign='center'
				placeholder='Custom loadout name'
				value={customName}
				setValue={setCustomName}
				width='200px'
				margin='5px auto'
				maxLength={30}
			/>

			<IdlescapeContainer
				variant='primary'
				position='sticky'
				top='0'
				zIndex='1'
				width='fit-content'
				margin='auto'
				fontSize='28px'
				fontWeight='bold'
				textAlign='center'
			>
				{customName || selectedLoadoutData.loadoutName}
			</IdlescapeContainer>

			<LoadoutView loadoutId={selectedLoadoutId} editMode={true} />

			<div className='idlescape-container thin idlescape-container-light'>
				<div className='loadout-action-buttons'>
					{selectedLoadoutId === 0 ? (
						<IdlescapeButton variant='green' {...loadoutButtonStyle} onClick={createLoadout}>
							Create new Loadout
						</IdlescapeButton>
					) : (
						<IdlescapeButton variant='green' {...loadoutButtonStyle} onClick={onOpen}>
							Save
						</IdlescapeButton>
					)}
					<Flex justifyContent='center' alignItems='center'>
						<FormLabel htmlFor='loadout-ignore-tools' textAlign='center'>
							Ignore all tools
						</FormLabel>
						<Switch
							id='loadout-ignore-tools'
							isChecked={ignoresTools}
							onChange={(e) => setIgnoresTools(e.target.checked)}
						/>
					</Flex>
					{selectedLoadoutId !== 0 && (
						<>
							<IdlescapeButton variant='orange' {...loadoutButtonStyle} onClick={() => loadLoadout()}>
								Load
							</IdlescapeButton>
							<IdlescapeButton variant='red' {...loadoutButtonStyle} onClick={() => deleteLoadout()}>
								Delete
							</IdlescapeButton>
							<IdlescapeButton
								variant='blue'
								{...loadoutButtonStyle}
								onClick={() => shareLoadout(selectedLoadoutData.loadoutId.toString())}
							>
								Share
							</IdlescapeButton>
						</>
					)}
				</div>
			</div>
			{selectedLoadoutId !== 0 && (
				<Accordion allowToggle>
					<AccordionItem>
						<AccordionButton>
							Location Selection
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel>
							<div className='loadout-location-container'>{locationsObj}</div>
						</AccordionPanel>
					</AccordionItem>
					<AccordionItem>
						<AccordionButton>
							Mercenary Panel
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel>
							{' '}
							<div className='merc-info'>
								<p>
									Mercenaries will attempt to be summoned if there are missing group slots in a Combat
									activity according to priority.
								</p>
								<p>
									Every mercenary present in the group will reduce the amount of vendor junk loot
									received by the entire party.
								</p>
								<p>
									Each mercenary within the game is restricted from sharing equipment with others,
									similar to the limitations imposed on players. Therefore, the player and the
									mercenaries cannot wear the same equipment simultaneously.
								</p>
								<p>
									Mercenaries eat the food in your loadout consumable inventory immediately on
									spawning to boost their max HP by half of the total HP you would gain from eating
									that food.
								</p>
								<p>
									Mercenaries will only respawn when ALL mercenaries have been defeated. If you are
									defeated, you may still rejoin the combat as normal as long as at least one
									mercenary survives until your respawn timer expires.
								</p>
							</div>
							<IdlescapeButton
								variant='green'
								width='auto'
								maxWidth='500px'
								onClick={() => toggleMercenary()}
							>
								Toggle Current Loadout As Mercenary
							</IdlescapeButton>
							{mercsObj.length > 0 && (
								<>
									<p>Current Mercenary List</p>
									<div className='loadout-action-buttons'>{mercsObj}</div>
								</>
							)}
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			)}
			{saveDialog}
		</div>
	);
};
