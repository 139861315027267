import { BaseTooltip } from './BaseTooltip';
import { TEnchant, TTooltipEquipmentStats, TWeaponStats } from '../Data/TooltipData';
import { IItem, IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { stringCapitalize, toFixedLocale } from '../../../../utils/StringFormatter';
import { TSkillName } from '../../../../../../game-server/src/Atypes/Skills';
import { enchantmentsList } from '../../../../utils/enchantmentList';
import { abilities } from '../../../../utils/abilityList';
import { itemList } from '../../../../utils/itemList';
import { dotter } from '../../../../utils/helperFunctions';
import { TEquipments } from '../../../../../../game-server/src/modules/player/PlayerTypes';
import { TEquipmentStats } from '../UI/LegacyTooltipPresenter';
import { IAbilityData } from '../../../../../../game-server/src/modules/ability/ability.interface';
import { IEnchantmentData } from '../../../../../../game-server/src/modules/enchantment/enchantment.interface';

export class EquipmentTooltip extends BaseTooltip {
	protected getItemType(itemResource: IItemData): string {
		const slot = itemResource.equipmentStats?.slot.replace('arrows', 'Quiver / Pendant') ?? 'Unknown Slot';
		return stringCapitalize(slot);
	}

	protected getSecondaryType(itemResource: IItemData): string {
		if (itemResource.equipmentStats?.slot.toUpperCase() === 'WEAPON')
			return itemResource.equipmentStats.oneHanded ? 'Main Hand' : 'Two-Handed';
		return '';
	}

	protected getWeaponInfo(itemResource: IItemData): Partial<Record<TWeaponStats, string>> {
		const weaponInfo: Partial<Record<TWeaponStats, string>> = {};
		if (itemResource.equipmentStats?.slot !== 'weapon') return weaponInfo;

		if (itemResource.equipmentStats?.attackSpeed) {
			weaponInfo.speed = toFixedLocale(itemResource.equipmentStats.attackSpeed, 2, 2);
		}
		if (itemResource.equipmentStats?.offensiveDamageAffinity) {
			let weaponStyle = '';
			let maxStyle = -1;
			for (const [style, multiplier] of Object.entries(itemResource.equipmentStats.offensiveDamageAffinity)) {
				if (multiplier >= maxStyle) {
					weaponStyle = style;
					maxStyle = multiplier;
				}
			}
			weaponInfo.style = stringCapitalize(weaponStyle);
		}
		if (itemResource.equipmentStats?.hitMults) {
			weaponInfo.maxHitMults = `${(itemResource.equipmentStats?.hitMults.maximum ?? 1) * 100}%`;
			weaponInfo.minHitMults = `${(itemResource.equipmentStats?.hitMults.minimum ?? 0.25) * 100}%`;
		}
		return weaponInfo;
	}

	protected getRequiredStatsLevel(item: IItem, itemResource: IItemData): Partial<Record<TSkillName, number>> {
		const requiredStats: Partial<Record<TSkillName, number>> = {};
		if (itemResource.requiredLevel) {
			let weakeningStrength = 0;
			if (item.enchantmentID) {
				const enchantment = enchantmentsList[item.enchantmentID];
				const hasWeakening = enchantment?.name.toLowerCase() === 'weakening';
				if (hasWeakening) weakeningStrength = (item?.enchantmentStrength ?? 0) * enchantment.strengthPerLevel;
			}

			for (const [skill, level] of Object.entries(itemResource.requiredLevel)) {
				const effectiveLevel = level - weakeningStrength;
				if (effectiveLevel > 1) requiredStats[skill as TSkillName] = effectiveLevel;
			}
		}

		return requiredStats;
	}

	protected getEquipmentStats(
		item: IItem,
		itemResource: IItemData
	): Record<TTooltipEquipmentStats, Partial<Record<TEquipmentStats, number>>> | Record<string, never> {
		if (!itemResource.equipmentStats) return {};

		const ignoredStats = ['itemSet', 'slot', 'attackSpeed', 'grantedAbility', 'augmentationBonus', 'hitMults'];
		const indexedStats = dotter(itemResource.equipmentStats, ignoredStats);
		this.fixToolBoostStats(indexedStats);

		const augments = item.augmentations || 0;
		itemResource.equipmentStats.augmentationBonus?.forEach((augmentationBonus) => {
			if (!(augmentationBonus.stat in indexedStats)) indexedStats[augmentationBonus.stat] = 0;
			indexedStats[augmentationBonus.stat] += augmentationBonus.value * augments;
		});

		return this.splitStatsByType(indexedStats);
	}

	// ToolBoosts are stored as an array of objects, but augmenting bonus ask for them to be referenced by skill name
	private fixToolBoostStats(indexedStats: Record<string, number>) {
		let i = 0;
		do {
			if (indexedStats[`toolBoost.${i}.skill`]) {
				const key = `toolBoost.${indexedStats[`toolBoost.${i}.skill`]}`;
				indexedStats[key] = indexedStats[`toolBoost.${i}.boost`];
				delete indexedStats[`toolBoost.${i}.skill`];
				delete indexedStats[`toolBoost.${i}.boost`];
				i++;
			}
		} while (indexedStats[`toolBoost.${i}.skill`]);
	}

	private splitStatsByType(
		indexedStats: Record<string, number>
	): Record<TTooltipEquipmentStats, Partial<Record<TEquipmentStats, number>>> {
		const strengthStats: Partial<Record<TEquipmentStats, number>> = {};
		const strengthAffinities: Partial<Record<TEquipmentStats, number>> = {};
		const attackAffinities: Partial<Record<TEquipmentStats, number>> = {};
		const defenseStats: Partial<Record<TEquipmentStats, number>> = {};
		const defenseAffinities: Partial<Record<TEquipmentStats, number>> = {};
		const skillStats: Partial<Record<TEquipmentStats, number>> = {};
		for (const [statString, value] of Object.entries(indexedStats)) {
			const stat = statString as TEquipmentStats;
			if (stat.startsWith('weaponBonus') || stat.startsWith('offensiveCritical')) strengthStats[stat] = value;
			else if (stat.startsWith('offensiveDamageAffinity')) strengthAffinities[stat] = value;
			else if (stat.startsWith('offensiveAccuracyAffinityRating')) attackAffinities[stat] = value;
			else if (stat.startsWith('armorBonus') || stat.startsWith('defensiveCritical')) defenseStats[stat] = value;
			else if (stat.startsWith('defensiveDamageAffinity')) defenseAffinities[stat] = value;
			else if (stat.startsWith('toolBoost')) skillStats[stat] = value;
		}
		return { strengthStats, strengthAffinities, attackAffinities, defenseStats, defenseAffinities, skillStats };
	}

	protected getEnchant(item: IItem): Record<TEnchant, string | number> | undefined {
		if (!item.enchantmentID) return undefined;

		const enchantment = enchantmentsList[item.enchantmentID];
		return {
			enchantId: enchantment.id,
			enchantName: enchantment.name,
			enchantStrength: item.enchantmentStrength ?? 0,
			enchantDescription: enchantment.getTooltip(item.enchantmentStrength ?? 0, enchantment.strengthPerLevel),
			enchantType: enchantment.relatedSkills[0],
		};
	}

	protected getAbilities(item: IItem, itemResource: IItemData): { name: IAbilityData['abilityName']; id: number }[] {
		const abilityList: { name: IAbilityData['abilityName']; id: number }[] = [];
		const itemAbilities = itemResource.equipmentStats?.grantedAbility ?? [];
		itemAbilities.forEach((abilityId) => {
			const ability = abilities[abilityId];
			abilityList.push({ name: ability.abilityName, id: ability.id });
		});

		return abilityList;
	}

	protected getItemSets(
		itemResource: IItemData,
		equippedItems: TEquipments
	): Record<IEnchantmentData['name'], string | number>[] {
		if (!itemResource?.equipmentStats?.itemSet) return [];

		const tooltipItemSets = itemResource.equipmentStats.itemSet;
		const equippedSetPieces: Record<number, number> = {};
		Object.values(equippedItems).forEach((equippedItem) => {
			const equippedItemSets = itemList[equippedItem.itemID]?.equipmentStats?.itemSet ?? [];
			equippedItemSets.forEach((set) => {
				if (tooltipItemSets.includes(set)) {
					if (!equippedSetPieces[set]) equippedSetPieces[set] = 0;
					const isTwoHandedWeapon =
						itemList[equippedItem.itemID]?.equipmentStats?.slot.toUpperCase() === 'WEAPON' &&
						!itemList[equippedItem.itemID]?.equipmentStats?.oneHanded;
					const setBonus = isTwoHandedWeapon ? 2 : 1;
					equippedSetPieces[set] += setBonus;
				}
			});
		});

		const itemSetsInfo: Record<IEnchantmentData['name'], string | number>[] = [];
		tooltipItemSets.forEach((setEnchant) => {
			const itemSet = enchantmentsList[setEnchant];
			if (itemSet) {
				const highestActiveTier = this._getHighestActiveSetTier(
					equippedSetPieces[setEnchant],
					itemSet.setRequirements
				);
				(itemSet?.setRequirements ?? []).forEach((setRequirement) => {
					if (setRequirement.strength === 0 || setRequirement.count < highestActiveTier) return;
					itemSetsInfo.push({
						name: itemSet.name,
						equipped: equippedSetPieces[setEnchant] ?? 0,
						required: setRequirement.count,
						description: itemSet.getTooltip(setRequirement.strength, itemSet.strengthPerLevel),
					});
				});
			}
		});
		return itemSetsInfo;
	}

	_getHighestActiveSetTier(numEquipped: number, tiers: { count: number; strength: number }[] | undefined) {
		let highestTier = 0;
		if (!tiers) return highestTier;
		tiers.forEach((tier) => {
			if ((numEquipped ?? 0) >= tier.count) highestTier = tier.count;
		});
		return highestTier;
	}

	protected getAugmentCounter(item: IItem): number {
		return item.augmentCounter ?? 0;
	}
}
