// @@@ THIS FILE IS DYNAMICALLY GENERATED. MODIFICATIONS WILL NEVER BE SAVED.
// MODIFY THE MASTER LIST AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES TO LIST ITEMS HERE

import { TMonsterListClient } from '../../../game-server/src/modules/monster/monster.interface';
import { abilitiesIds } from '../utils/lookup-dictionaries/lookupAbilityList';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';

/**
 * MONSTER DESIGN NOTES:
 * Stamina does not increase max health of monsters like it does for players
 */

export const monsters: TMonsterListClient = {
	1: {
		id: 1,
		name: 'Chicken',
		image: '/images/combat/monsters/chicken.png',
		
	},
	2: {
		id: 2,
		name: 'Cow',
		image: '/images/combat/monsters/cow.png',
		
	},
	3: {
		id: 3,
		name: 'Goblin',
		image: '/images/combat/monsters/goblin.png',
		
	},
	4: {
		id: 4,
		name: 'Rat',
		image: '/images/combat/monsters/rat.png',
		
	},
	5: {
		id: 5,
		name: 'Guard',
		image: '/images/combat/monsters/guard.png',
		
	},
	6: {
		id: 6,
		name: 'Militia Crossbowman',
		image: '/images/combat/monsters/militia.png',
		
	},
	7: {
		id: 7,
		name: 'Hobgoblin',
		image: '/images/combat/monsters/goblin.png',
		
	},
	8: {
		id: 8,
		name: 'Greater Imp',
		image: '/images/combat/monsters/imp.png',
		
	},
	9: {
		id: 9,
		name: 'Deadly Spider',
		image: '/images/combat/monsters/deadly_spider.png',
		
	},
	10: {
		id: 10,
		name: 'Deadly Red Spider',
		image: '/images/combat/monsters/deadly_red_spider.png',
		
	},
	11: {
		id: 11,
		name: 'Lesser Demon',
		image: '/images/combat/monsters/lesser_demon_no_highlight.png',
		
	},
	12: {
		id: 12,
		name: 'Greater Demon',
		image: '/images/combat/monsters/greater_demon.png',
		
	},
	13: {
		id: 13,
		name: 'Black Knight',
		image: '/images/combat/monsters/black_knight.png',
		
	},
	14: {
		id: 14,
		name: 'Moss Giant',
		image: '/images/combat/monsters/moss_giant.png',
		
	},
	15: {
		id: 15,
		name: 'Fire Giant',
		image: '/images/combat/monsters/fire_giant.png',
		
	},
	16: {
		id: 16,
		name: 'Ice Giant',
		image: '/images/combat/monsters/ice_giant.png',
		
	},
	17: {
		id: 17,
		name: 'Bone Giant',
		image: '/images/combat/monsters/bone_giant.png',
		
	},
	18: {
		id: 18,
		name: 'Cursed Naga',
		image: '/images/combat/monsters/infected_naga.png',
		
	},
	19: {
		id: 19,
		name: 'Corrupted Tree',
		image: '/images/combat/monsters/corrupted_tree.png',
		
	},
	20: {
		id: 20,
		name: 'Chaos Giant',
		image: '/images/combat/monsters/chaos_giant.png',
		
	},
	21: {
		id: 21,
		name: 'Chaotic Abomination',
		image: '/images/combat/monsters/chaotic_abomination.png',
		
	},
	22: {
		id: 22,
		name: 'Swordfish',
		image: '/images/combat/monsters/swordfish.png',
		
	},
	23: {
		id: 23,
		name: 'Ascended',
		image: '/images/combat/monsters/ascendant.png',
		
	},
	24: {
		id: 24,
		name: 'Aberrant Shrimp',
		image: '/images/combat/monsters/shrimp_abberation.png',
		
	},
	25: {
		id: 25,
		name: 'Spriggan',
		image: '/images/combat/monsters/spriggan.png',
		
	},
	26: {
		id: 26,
		name: 'Elven Hunter',
		image: '/images/combat/monsters/klimpelimpe.png',
		
	},
	27: {
		id: 27,
		name: 'Elven Warrior',
		image: '/images/combat/monsters/elven_warrior.png',
		
	},
	28: {
		id: 28,
		name: 'Elven Mage',
		image: '/images/combat/monsters/desiccated_priest.png',
		
	},
	29: {
		id: 29,
		name: 'Elven Rider',
		image: '/images/combat/monsters/elven_rider.png',
		
	},
	30: {
		id: 30,
		name: 'Wild Boar',
		image: '/images/combat/monsters/wildboar.png',
		
	},
	31: {
		id: 31,
		name: 'Wolf',
		image: '/images/combat/monsters/wolf.png',
		
	},
	32: {
		id: 32,
		name: 'Corpse Eater',
		image: '/images/combat/monsters/corpse_eater.png',
		
	},
	33: {
		id: 33,
		name: 'Dark Mage',
		image: '/images/combat/monsters/dark_mage.png',
		
	},
	34: {
		id: 34,
		name: 'Reanimated Bones',
		image: '/images/combat/monsters/reanimated_bones.png',
		
	},
	35: {
		id: 35,
		name: 'Desiccated Priest',
		image: '/images/combat/monsters/desiccated_priest.png',
		
	},
	36: {
		id: 36,
		name: 'Glass Mage',
		image: '/images/combat/monsters/black_knight_magic.png',
		
	},
	37: {
		id: 37,
		name: 'Glass Knight',
		image: '/images/combat/monsters/black_knight_titan.png',
		
	},
	38: {
		id: 38,
		name: 'Glass Ranger',
		image: '/images/combat/monsters/black_knight_range.png',
		
	},
	39: {
		id: 39,
		name: 'Deep Pit Demon',
		image: '/images/combat/monsters/greater_demon.png',
		
	},
	500: {
		id: 500,
		name: "Ot_to's Prized Hen",
		image: '/images/combat/monsters/elite/chicken_elite.png',
		
	},
	501: {
		id: 501,
		name: 'Chocolate Cow',
		image: '/images/combat/monsters/elite/elite_cow.png',
		
	},
	502: {
		id: 502,
		name: 'Overseer Demon',
		image: '/images/combat/monsters/elite/greater_demon_elite.png',
		
	},
	503: {
		id: 503,
		name: 'Blood Spider',
		image: '/images/combat/monsters/elite/elite_red_spider.png',
		
	},
	504: {
		id: 504,
		name: 'Vassal Demon',
		image: '/images/combat/monsters/elite/lesser_demon_no_highlight_elite.png',
		
	},
	505: {
		id: 505,
		name: 'Marshall the Town Rat',
		image: '/images/combat/monsters/elite/marshall.png',
		
	},
	506: {
		id: 506,
		name: 'Marshall the Town Rat 2',
		image: '/images/combat/monsters/elite/marshall_gun.png',
		
	},
	507: {
		id: 507,
		name: 'Big Gobbo',
		image: '/images/combat/monsters/elite/goblin_elite.png',
		
	},
	508: {
		id: 508,
		name: 'Elder Spriggan',
		image: '/images/combat/monsters/elite/spriggan_elite.png',
		
	},
	509: {
		id: 509,
		name: 'Perennial Giant', // elite moss giant
		image: '/images/combat/monsters/elite/moss_giant_elite.png',
		
	},
	510: {
		id: 510,
		name: 'Volcanic Giant', // elite fire giant
		image: '/images/combat/monsters/elite/fire_giant_elite.png',
		
	},
	511: {
		id: 511,
		name: 'Glacial Giant', // elite ice giant
		image: '/images/combat/monsters/elite/ice_giant_elite.png',
		
	},
	512: {
		id: 512,
		name: 'Imbued Black Knight',
		image: '/images/combat/monsters/elite/black_knight_elite.png',
		
	},
	513: {
		id: 513,
		name: 'Ghastly Giant', // elite bone giant
		image: '/images/combat/monsters/elite/bone_giant_elite.png',
		
	},
	514: {
		id: 514,
		name: 'Corrupted Naga', // elite cursed naga
		image: '/images/combat/monsters/elite/infected_naga_elite.png',
		
	},
	515: {
		id: 515,
		name: 'Swordfish Champion',
		image: '/images/combat/monsters/elite/swordfish_elite.png',
		
	},
	516: {
		id: 516,
		name: 'Ancient Ascended',
		image: '/images/combat/monsters/elite/ascendant_elite.png',
		
	},
	517: {
		id: 517,
		name: 'Voidtouched Giant',
		image: '/images/combat/monsters/elite/chaos_giant_elite.png',
		
	},
	518: {
		id: 518,
		name: 'Obsidian Mage',
		image: '/images/combat/monsters/black_knight_magic.png',
		
	},
	519: {
		id: 519,
		name: 'Obsidian Knight',
		image: '/images/combat/monsters/black_knight_titan.png',
		
	},
	520: {
		id: 520,
		name: 'Obsidian Ranger',
		image: '/images/combat/monsters/black_knight_range.png',
		
	},
	521: {
		id: 521,
		name: 'Possessed Mage',
		image: '/images/combat/monsters/dark_mage.png',
		
	},
	522: {
		id: 522,
		name: 'Pactbound Sorcerer',
		image: '/images/combat/monsters/elite/pactbound_sorceror.png',
		
	},
	523: {
		id: 523,
		name: 'Nox Wolf',
		image: '/images/combat/monsters/elite/wolf_elite.png',
		
	},
	524: {
		id: 524,
		name: 'Royal Elven Hunter',
		image: '/images/combat/monsters/klimpelimpe.png',
		
	},
	525: {
		id: 525,
		name: 'Royal Elven Warrior',
		image: '/images/combat/monsters/elven_warrior.png',
		
	},
	526: {
		id: 526,
		name: 'Royal Elven Mage',
		image: '/images/combat/monsters/desiccated_priest.png',
		
	},
	527: {
		id: 527,
		name: 'Royal Elven Rider',
		image: '/images/combat/monsters/elven_rider.png',
		
	},
	528: {
		id: 528,
		name: 'Black Knight Titan',
		image: '/images/combat/monsters/elite/black_knight_elite.png',
		
	},
	529: {
		id: 529,
		name: 'Exiled Preceptor',
		image: '/images/combat/monsters/elite/exiled_preceptor.png',
		
	},
	530: {
		id: 530,
		name: 'Corrupted Elder Tree', // elite tree
		image: '/images/combat/monsters/elite/elite_corrupted_tree.png',
		
	},
	1000: {
		id: 1000,
		name: 'INFO',
		image: '/images/combat/monsters/elite/true_info.png',
		
	},
	1006: {
		id: 1006,
		name: 'Giant King',
		image: '/images/combat/monsters/Giant_King_Silver_C.png',
		
	},
	1007: {
		id: 1007,
		name: 'Giant King 2',
		image: '/images/combat/monsters/Giant_King_Copper_C.png',
		
	},
	1008: {
		id: 1008,
		name: 'Just a Chicken',
		image: '/images/combat/monsters/elite/chicken_elite.png',
		
	},
	1009: {
		id: 1009,
		name: 'Phoenix',
		image: '/images/combat/monsters/elite/flame_chicken_1.png',
		
	},
	1010: {
		id: 1010,
		name: 'Phoenix 2',
		image: '/images/combat/monsters/elite/flame_chicken_2.png',
		
	},
	1011: {
		id: 1011,
		name: 'Phoenix 3',
		image: '/images/combat/monsters/elite/flame_chicken_3.png',
		
	},
	1012: {
		id: 1012,
		name: 'Phoenix 4',
		image: '/images/combat/monsters/elite/flame_chicken_4.png',
		
	},
	1013: {
		id: 1013,
		name: 'The Advisor',
		image: '/images/combat/monsters/elite/bone_giant_elite.png',
		
	},
	1014: {
		id: 1014,
		name: 'Elven King',
		image: '/images/combat/monsters/elite/elven_king.png',
		
	},
	1015: {
		id: 1015,
		name: 'The Regent',
		image: '/images/combat/monsters/elite/regent.png',
		
	},
	1016: {
		id: 1016,
		name: 'ANGY WOLF',
		image: '/images/combat/monsters/elite/firewolf.png',
		
	},
	1017: {
		id: 1017,
		name: 'ANGY BOAR',
		image: '/images/combat/monsters/elite/hotham.png',
		
	},
	1018: {
		id: 1018,
		name: 'Storm Watcher',
		image: '/images/combat/monsters/elite/storm_watcher.png',
		
	},
	1019: {
		id: 1019,
		name: 'The Shrimp God - Phase 1',
		image: '/images/combat/monsters/elite/shrimp_god.png',
		
	},
	1020: {
		id: 1020,
		name: 'The Shrimp God - Phase 2',
		image: '/images/combat/monsters/elite/shrimp_god.png',
		
	},
	// P5 / Final, Magic focused, big stats overall
	1021: {
		id: 1021,
		name: 'Palaemagos, Avatar of the True Shrimp God',
		image: '/images/combat/monsters/elite/shrimp_god.png',
		
	},
	1022: {
		id: 1022,
		name: 'Goblin Chef',
		image: '/images/combat/monsters/elite/goblin_chef.png',
		
	},
	1023: {
		id: 1023,
		name: 'Wrath',
		image: '/images/combat/monsters/elite/wrath.png',
		
	},
	1024: {
		id: 1024,
		name: 'Loot Goblin',
		image: '/images/combat/monsters/elite/loot_goblin.png',
		
	},
	1025: {
		id: 1025,
		name: 'Ancient Dwarven Golem',
		image: '/images/combat/monsters/elite/dwarven_automaton2.png',
		
	},
	1026: {
		id: 1026,
		name: 'Living Wood Wolf',
		image: '/images/combat/monsters/elite/living_wood_wolf.png',
		
	},
	1027: {
		id: 1027,
		name: 'Water Elemental',
		image: '/images/combat/monsters/elite/water_elemental2.png',
		
	},
	1028: {
		id: 1028,
		name: 'Corrupted Titan',
		image: '/images/combat/monsters/elite/black_knight_elite.png',
		
	},
	// P3 Silent Immune, Melee focused, high prot, no agility
	1029: {
		id: 1029,
		name: 'Palaekratos, the Unbroken',
		image: '/images/combat/monsters/elite/shrimp_god.png',
		
	},
	// P4 Stun Immune, Range focused, low prot/res, high agility
	1030: {
		id: 1030,
		name: 'Palaedromos, the Untouched',
		image: '/images/combat/monsters/elite/shrimp_god.png',
		
	},
	// Special reinforcement monsters, should ALL ignore wave count
	2000: {
		id: 2000,
		name: 'Summoned Shrimp',
		image: '/images/combat/monsters/ascendant.png',
		
	},
	2001: {
		id: 2001,
		name: 'Corrupted Knight',
		image: '/images/combat/monsters/corrupted_knight.png',
		
	},
	2002: {
		id: 2002,
		name: 'Shrimp God Tentacle',
		image: '/images/combat/monsters/ascendant.png',
		
	},

	// DPS Dummy
	10000: {
		id: 10000,
		name: 'DPS Dummy',
		image: '/images/combat/monsters/dummy.png',
		
	},
};
