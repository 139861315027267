import React, { useEffect, useState } from 'react';
import { usePlayerField, usePlayerQuestCompleted, usePlayerQuestProgress } from '../../../../../hooks/hooks';
import { leagueList } from '../../../../../utils/leagueList';
import { affixList } from '../../../../../utils/affixList';
import { Box } from '@chakra-ui/react';
import { IItem } from '../../../../../../../game-server/src/modules/items/items.interface';
import { TAffixesPaths } from '../../../../../../../game-server/src/modules/affixes/affixes.interface';
import RunecraftingAffixItemSelection from './RunecraftingAffixItemSelection';
import AffixList from './AffixList';
import AffixSelection from './AffixSelection';
import { getAffixesSlotsForItem } from '../../../../../utils/affixFunctions';
import { questsIds } from '../../../../../utils/lookup-dictionaries/lookupQuestList';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import { socket } from '../../../../../services/socket.service';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { TooltipLiterals } from '../../../Tooltips/Data/TooltipLiterals';
import { formatEquipmentAffixStrength } from '../AffixTooltip';
import { Text } from '@chakra-ui/react';
import FakeItem from '../../../Inventory/FakeItem';
import { FaArrowRight } from 'react-icons/fa';
import { itemList } from '../../../../../utils/itemList';

export default function RunecraftingAffix() {
	const league = usePlayerField('league');
	const affixRerollSelected = usePlayerField('affixRerollSelected');
	const affixRerollOption = usePlayerField('affixRerollOption');

	const [selectedItemId, setSelectedItem] = useState(-1);
	const [selectedAffixes, setSelectedAffixes] = useState<TAffixesPaths[]>([]);

	const tutorialCurrentStep = usePlayerQuestProgress(questsIds.affixing_tutorial);
	const tutorialCompleted = usePlayerQuestCompleted(questsIds.affixing_tutorial);
	const stockpile = usePlayerField('stockpile');
	let selectedItem = stockpile.find((item) => item.id === selectedItemId);
	const isRerolling = selectedItem?.affixes !== undefined;
	const [showModal, setShowModal] = useState(false);
	const [selected, setSelected] = useState(false);

	// Clear selected item and affixes if the item is removed
	useEffect(() => {
		if (selectedItemId !== -1 && !stockpile.find((item) => item.id === selectedItemId)) {
			setSelectedItem(-1);
			setSelectedAffixes([]);
		}
	}, [stockpile]);

	function confirmSelection(selected: boolean) {
		setShowModal(true);
		setSelected(selected);
	}

	function selection() {
		if (selected) {
			// Clear the rolls
			socket.emit('runecrafting:takeOldAffixed');
		} else {
			// Select the new rolls
			socket.emit('runecrafting:takeNewAffixed');
		}
		setShowModal(false);
	}

	function canAffixLeague() {
		const leagueData = leagueList[league];
		if (!leagueData) {
			return false;
		}
		return leagueData.rules.affixes;
	}

	function tutorialCheck() {
		return tutorialCompleted || tutorialCurrentStep > 0;
	}

	function handleSelectItem(item: IItem) {
		if (selectedItem?.id === item.id) {
			setSelectedItem(-1);
			setSelectedAffixes([]);
		} else {
			setSelectedItem(item.id);

			// This is janky af, but it works!
			selectedItem = stockpile.find((i) => item.id === i.id);
			if (isRerolling || selectedItem?.affixes) {
				if (selectedItem?.affixes) {
					const affixes = selectedItem.affixes.map((affix) => affix.path);
					if (selectedAffixes.length !== affixes.length) {
						setSelectedAffixes(affixes as TAffixesPaths[]);
					}
				}
			}
		}
	}

	function handleAffixSelect(affix: TAffixesPaths) {
		// If we're rerolling, they can't add any paths that aren't already on the item
		if (isRerolling) {
			if (!selectedItem) {
				return;
			}
			const affixData = affixList[affix];
			if (!affixData) return;
			if (!selectedItem.affixes) {
				return;
			}
			const affixOnItem = selectedItem.affixes.find((a) => a.path === affix);
			if (!affixOnItem) {
				return;
			}
		}
		if (selectedAffixes.includes(affix)) {
			setSelectedAffixes(selectedAffixes.filter((a) => a !== affix));
		} else {
			// Check if we have too many selected
			if (selectedAffixes.length >= (selectedItem ? getAffixesSlotsForItem(itemList[selectedItem.itemID]) : 0)) {
				return;
			}
			setSelectedAffixes([...selectedAffixes, affix]);
		}
	}

	if (!canAffixLeague()) {
		const now = new Date();
		const activeAffixingLeagues = Object.values(leagueList).filter((leagueData) => {
			if (!leagueData) {
				return false;
			}
			if (leagueData.rules.endDate && new Date(leagueData.rules.endDate) < now) {
				return false;
			}
			return leagueData.rules.affixes && leagueData.active;
		});
		const leagueNames = activeAffixingLeagues.map((league) => leagueList[league.id].name);
		const leagueString = leagueNames.join(', ');

		return (
			<div className='enchanting-main'>
				<div className='enchanting-items-container'>
					<h1 className='enchanting-title'>Affixing is not available in your league.</h1>
					<h3 className='enchanting-title'>
						Active Affixing Leagues/Seasons: {leagueString.length > 0 ? leagueString : 'Currently none.'}
					</h3>
				</div>
			</div>
		);
	}
	if (!tutorialCheck()) {
		return (
			<IdlescapeContainer>
				<h1 className='enchanting-title'>
					You must start or complete the Affixing Tutorial to use this feature.
				</h1>
			</IdlescapeContainer>
		);
	}

	const shouldRenderEquipment = !selectedItem;
	const shouldRenderAffixes = selectedItem !== undefined && !isRerolling;
	const shouldRenderSelected = selectedItem || selectedAffixes.length > 0;
	const nothingRendered = !shouldRenderEquipment && !shouldRenderAffixes && !shouldRenderSelected;
	if (nothingRendered) {
		return (
			<div className='enchanting-main'>
				<h1 className='enchanting-title'>Well this is not supposed to happen.</h1>
			</div>
		);
	}

	// This was its own component but it kept fucking crashing
	if (affixRerollSelected !== null || affixRerollOption !== null) {
		// If either one IS null, put the clear button in
		if (affixRerollSelected === null || affixRerollOption === null) {
			// This is an error case, display a button to force clear the reroll options
			return (
				<IdlescapeContainer>
					<h1>Possible Error Case Encountered, Attempt Clear?</h1>
					<IdlescapeButton
						size={'large'}
						variant={'blue'}
						className={'enchanting-glowing-button'}
						onClick={() => socket.emit('runecrafting:takeOldAffixed')}
						marginY='15px'
					>
						CLEAR SELECTION
					</IdlescapeButton>
				</IdlescapeContainer>
			);
		}
		if (!affixRerollSelected) {
			return <div className={'enchanting-window-item'}>None selected</div>;
		}
		const renderedItem = (
			<div className={'enchanting-window-item'}>
				<FakeItem item={affixRerollSelected} />
			</div>
		);

		return (
			<IdlescapeContainer>
				<h1 style={{ textAlign: 'center' }}>Reroll Selection</h1>
				<div style={{ placeItems: 'center', width: '100%' }}>
					<IdlescapeContainer
						style={{
							width: '100%',
							textAlign: 'center',
						}}
					>
						<div
							style={{ display: 'flex', margin: 'auto', alignItems: 'center', justifyContent: 'center' }}
						>
							{renderedItem}
						</div>
						<h2 style={{ textAlign: 'center' }}>Rolls</h2>
						{affixRerollSelected?.affixes?.map((affix, index) => {
							const affixData = affixList[affix.path];
							const foundOption = affixRerollOption?.affixes?.find((a) => a.path === affix.path);
							let color = 'white';
							if (foundOption) {
								if (affix.value < foundOption.value) {
									color = 'green';
								} else if (affix.value > foundOption.value) {
									color = 'red';
								}
							}
							return (
								<div key={`${affix.path}:${index}:selected`}>
									<Text>{TooltipLiterals.affixesLiterals[affix.path]}: </Text>
									<Text>
										{formatEquipmentAffixStrength(
											affixData,
											affix.value,
											affixRerollSelected?.itemID || 0
										)}{' '}
										{<FaArrowRight />}{' '}
										<span style={{ color: color }}>
											{formatEquipmentAffixStrength(
												affixData,
												foundOption?.value || 0,
												affixRerollOption?.itemID || 0
											)}
										</span>
									</Text>
								</div>
							);
						}) || []}
						<div style={{ width: '100%', alignContent: 'center' }}>
							<IdlescapeButton
								size={'large'}
								variant={'purple'}
								className={'enchanting-glowing-button'}
								onClick={() => confirmSelection(true)}
								margin='20px'
							>
								Keep Current
							</IdlescapeButton>
							<IdlescapeButton
								size={'large'}
								variant={'purple'}
								className={'enchanting-glowing-button'}
								onClick={() => confirmSelection(false)}
								margin='20px'
							>
								Use New Rolls
							</IdlescapeButton>
						</div>
					</IdlescapeContainer>
				</div>

				<Dialog open={showModal} onClose={() => setShowModal(false)} className='sell-item-dialog'>
					<DialogTitle>Selection Confirmation</DialogTitle>
					<DialogContent>
						<Text>Are you sure you want to select {selected ? 'the old roll' : 'the new roll'}?</Text>
					</DialogContent>
					<DialogActions>
						<IdlescapeButton variant='red' onClick={() => setShowModal(false)}>
							Close
						</IdlescapeButton>
						<IdlescapeButton
							variant={'green'}
							onClick={() => {
								selection();
							}}
						>
							Confirm
						</IdlescapeButton>
					</DialogActions>
				</Dialog>
			</IdlescapeContainer>
		);
	}

	return (
		<Box className='enchanting-main' height='auto'>
			{shouldRenderEquipment && <RunecraftingAffixItemSelection onSelect={handleSelectItem} />}
			{shouldRenderSelected && (
				<AffixSelection
					selectedItem={selectedItem}
					selectedAffixes={selectedAffixes}
					setSelectedItem={setSelectedItem}
					setSelectedAffixes={setSelectedAffixes}
					handleAffixSelect={handleAffixSelect}
				/>
			)}
			{shouldRenderAffixes && (
				<AffixList
					selectedItem={selectedItem}
					handleAffixSelect={handleAffixSelect}
					selectedAffixes={selectedAffixes}
				/>
			)}
		</Box>
	);
}
