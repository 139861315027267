import React, { useEffect, useState } from 'react';

export default function Timer(props: { time: Date; countdown?: boolean; class?: string }) {
	const [time, setTime] = useState(timeSince);
	useEffect(() => {
		const timer = setInterval(() => setTime(timeSince), 1000);
		return () => clearInterval(timer);
	}, []);

	function timeSince() {
		const dateNow = props.countdown ? new Date() : props.time;
		const dateFuture = props.countdown ? props.time : new Date();
		let seconds = Math.floor((dateFuture.getTime() - dateNow.getTime()) / 1000);
		seconds = seconds < 0 ? 0 : seconds;
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);

		hours = hours - days * 24;
		minutes = minutes - days * 24 * 60 - hours * 60;
		seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

		let string = '';

		if (days > 0) string += (string !== '' ? ' ' : '') + days + 'd';
		if (hours > 0) string += (string !== '' ? ' ' : '') + hours + 'h';
		if (minutes > 0) string += (string !== '' ? ' ' : '') + minutes + 'm';
		if (seconds > 0) string += (string !== '' ? ' ' : '') + seconds + 's';
		if (string === '') string += '0s';

		return string;
	}

	return <div className={props.class}>{time}</div>;
}
