import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { smithingActiveForgeAtom } from '../../../../atoms/smithingActiveForgeAtom';
import { smithingActiveBarAtom } from '../../../../atoms/smithingActiveBarAtom';
import { smithingIntensityAtom } from '../../../../atoms/smithingIntensityAtom';
import { itemList } from '../../../../utils/itemList';
import { forges } from '../../../../utils/forgeList';
import ItemTooltip from '../../Tooltips/ItemTooltip';
import { ISmithingOutput } from '../../../../../../game-server/src/modules/skills/smithing/Smithing.interface';
import { formatNumberToString, getTimeString, infernoSpeed } from '../../../../utils/helperFunctions';
import { usePlayerAffixStrength, usePlayerEnchantmentStrength, usePlayerField } from '../../../../hooks/hooks';
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined';
import { Box, Flex, Text } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import ResourceList from '../../ResourceList';
import { enchantmentsIds } from '../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { CraftingAugmentingData } from '../../CraftingAugmenting/CraftingAugmentingData';
import { cloneDeep } from 'lodash';
import { ILootWithFrequency } from '../../../../../../game-server/src/modules/items/LootInterface';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import ResourceCost from '../ResourceCost';
import {
	FORGE_FIELDS_TO_ENHANCE_OR_MAINTAIN,
	FORGE_REFINING_BASE_TIME_ADJUSTMENT,
	FORGE_REFINING_PROBABILITY_ADJUSTMENT,
} from '../../../../utils/constantsCollection';
import { dwarvenElvenBonus, dwarvenElvenHaste } from '../../../../utils/itemFunctions';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import { smithingStartAtom } from '../../../../atoms/smithingStartAtom';

export default function SmithingInformation() {
	const activeForge = useRecoilValue(smithingActiveForgeAtom);
	const activeBar = useRecoilValue(smithingActiveBarAtom);
	const intensity = useRecoilValue(smithingIntensityAtom);
	const setCanStart = useSetRecoilState(smithingStartAtom);
	const stockpile = usePlayerField('stockpile');

	const skills = usePlayerField('skills');
	const skillEquipmentStats = usePlayerField('skillEquipmentStats');

	const level = skills.smithing.level;
	const mastery = skills.smithing.masteryLevel;
	const gearLevel = skillEquipmentStats.smithing;
	const total = level + mastery + gearLevel;

	const hasteStrength = usePlayerEnchantmentStrength(enchantmentsIds.haste, 'smithing');
	const efficiencyStrength = usePlayerEnchantmentStrength(enchantmentsIds.efficiency, 'smithing');
	const pyromancyStrength = usePlayerEnchantmentStrength(enchantmentsIds.pyromancy, 'smithing');
	const pureMetalsStrength = usePlayerEnchantmentStrength(enchantmentsIds.pure_metals, 'smithing');
	const dwarvenManufacturing = usePlayerEnchantmentStrength(enchantmentsIds.dwarven_manufacturing, 'smithing');
	const elvenLogistics = usePlayerEnchantmentStrength(enchantmentsIds.elven_logistics, 'smithing');
	let refiningStrength = usePlayerEnchantmentStrength(enchantmentsIds.refining, 'smithing');
	const dwarvenRefinementStrength = usePlayerEnchantmentStrength(enchantmentsIds.dwarven_refinement, 'smithing');
	if (activeForge === 2) {
		refiningStrength += dwarvenRefinementStrength;
	}
	const infernoStrength = usePlayerEnchantmentStrength(enchantmentsIds.inferno, 'smithing');
	const crucibleStrength = usePlayerEnchantmentStrength(enchantmentsIds.crucible, 'smithing');
	const obsidianForgeryStrength = usePlayerEnchantmentStrength(enchantmentsIds.obsidian_forgery, 'smithing');

	const enhancementStrength = usePlayerEnchantmentStrength(enchantmentsIds.forge_enhancement, 'smithing');
	const maintenanceStrength = usePlayerEnchantmentStrength(enchantmentsIds.forge_maintenance, 'smithing');
	const wealthStrength = usePlayerEnchantmentStrength(enchantmentsIds.wealth, 'smithing');
	const scholarStrength = usePlayerEnchantmentStrength(enchantmentsIds.scholar, 'smithing');

	const affixHeatCostReduction = 1 - usePlayerAffixStrength('smithing.heat_cost_reduction');
	const affixMaterialCostReduction = 1 - usePlayerAffixStrength('smithing.material_cost_reduction');

	const activeForgeData = cloneDeep(forges[activeForge]);

	// Applying enhance/maintain
	const forgeFields = Object.keys(FORGE_FIELDS_TO_ENHANCE_OR_MAINTAIN);
	for (const [keyString, value] of Object.entries(activeForgeData)) {
		const key = keyString as keyof typeof FORGE_FIELDS_TO_ENHANCE_OR_MAINTAIN;
		if (forgeFields.includes(key)) {
			const forgeModifier = FORGE_FIELDS_TO_ENHANCE_OR_MAINTAIN[key];
			if (!forgeModifier || forgeModifier.skipField) {
				continue;
			}
			const neutral = forgeModifier.neutral ?? 1;
			if (value > neutral) {
				if (forgeModifier.enhanceOnPositive) {
					// Make it bigger
					activeForgeData[key] *= 1 + enhancementStrength;
				}
				if (forgeModifier.maintainOnPositive) {
					// Make it smaller
					activeForgeData[key] *= 1 - maintenanceStrength;
				}
			} else if (value < neutral) {
				if (forgeModifier.enhanceOnNegative) {
					// Make it smaller
					activeForgeData[key] *= 1 - enhancementStrength;
				}
				if (forgeModifier.maintainOnNegative) {
					// Make it bigger
					activeForgeData[key] *= 1 + maintenanceStrength;
				}
			}
		}
	}
	const activeBarData = itemList[activeBar];

	const smithingRecipe = CraftingAugmentingData.getSmithingByID(activeBarData.id) ?? {};

	const barTier = activeBarData.smithingTier ?? Math.max(1, Math.round((activeBarData.level ?? 1) / 10.0));

	const powerMult = 100 / (99 + total);

	const dwarvenElvenTimeMult = 1 + dwarvenElvenHaste(dwarvenManufacturing, elvenLogistics);
	const timeWithIntensityMultiplier =
		((activeBarData.time ?? 0) *
			activeForgeData.forgeSpeedMult *
			Math.pow(activeForgeData.forgeIntensitySpeedMult, intensity - barTier) *
			powerMult) /
		(1 + hasteStrength) /
		dwarvenElvenTimeMult /
		1000;

	const dwarvenElvenMatMult = dwarvenElvenBonus(dwarvenManufacturing, elvenLogistics);

	const heatMultiplier =
		Math.pow(activeForgeData.forgeIntensityHeatCostMult, intensity - barTier) *
		activeForgeData.forgeHeatCost *
		(1 - pyromancyStrength) *
		dwarvenElvenMatMult *
		affixHeatCostReduction *
		(1 + crucibleStrength * 1.5);

	const materialMultiplier =
		Math.pow(activeForgeData.forgeIntensityMaterialCostMult, intensity - barTier) *
		activeForgeData.forgeMaterialCost *
		(1 - pureMetalsStrength) *
		dwarvenElvenMatMult *
		affixMaterialCostReduction *
		(1 + crucibleStrength);
	let experience = (activeBarData.experience ?? 0) * activeForgeData.forgeXPMult;
	if (scholarStrength && !wealthStrength) {
		experience *= 1 + scholarStrength;
	}

	const outputAmount =
		(1 +
			activeForgeData.forgeBonusBars * Math.pow(activeForgeData.forgeIntensityBonusBars, intensity - barTier) +
			efficiencyStrength) *
		(1 + crucibleStrength);

	const activeBarResourceInput = Object.keys(smithingRecipe);

	const owned: number[] = [];
	for (const input of activeBarResourceInput) {
		owned.push(stockpile.find((item) => item.itemID === Number(input))?.stackSize ?? 0);
	}

	const minAmount = Math.floor(
		Math.min(
			...activeBarResourceInput.map((resourceID, index) => {
				const itemAmount = smithingRecipe[Number(resourceID)];
				const multiplier = resourceID === '2' ? heatMultiplier : materialMultiplier;
				const cost = Math.ceil(itemAmount * multiplier);
				return owned[index] / cost;
			})
		)
	);

	const infSpeed = infernoSpeed(infernoStrength);

	useEffect(() => {
		setCanStart(minAmount > 0);
	}, [minAmount]);

	function refiningTooltip() {
		if (refiningStrength === 0 || !activeForgeData.refiningTable) {
			return null;
		}
		let table = [] as ILootWithFrequency[];
		const normalizedDuration =
			((activeBarData.time ?? 0) * activeForgeData.forgeIntensitySpeedMult ** (intensity - barTier)) /
			FORGE_REFINING_BASE_TIME_ADJUSTMENT;
		const refiningStrengthMult = activeForgeData.refiningStrengthMult ?? 1;
		const probability =
			(normalizedDuration * refiningStrengthMult * refiningStrength) / FORGE_REFINING_PROBABILITY_ADJUSTMENT;
		const chancePower = (1 + refiningStrength / 8) * (1 + intensity / 16);
		const chanceAdd = (barTier + intensity) / 666;
		for (const loot of activeForgeData.refiningTable) {
			if (loot.chance !== undefined) {
				const refiningItem = cloneDeep(loot);
				let bonusChance = 0;
				if (refiningItem.id === itemsIds.obsidian_glass && activeForge === 1) {
					bonusChance = obsidianForgeryStrength;
				}
				refiningItem.frequency = Math.max(
					0,
					Math.min(1, 1 - (1 - loot.chance - chanceAdd - bonusChance) ** chancePower)
				);
				delete refiningItem.chance;
				table.push(refiningItem as ILootWithFrequency);
			}
		}
		const maxChance = table.reduce((acc, curr) => Math.max(acc, curr.frequency ?? 0), 0);
		// Sort so lowest chances are first
		table.sort((a, b) => {
			return a.frequency - b.frequency;
		});
		// Compute individual chances
		for (let i = table.length - 1; i > 0; i--) {
			table[i].frequency -= table[i - 1].frequency;
		}
		table = table.filter((loot) => loot.frequency !== 0);
		// Add filler sapphire
		if (maxChance < 1) {
			const existingSapphire = table.find((loot) => loot.id === itemsIds.sapphire);
			if (existingSapphire) {
				existingSapphire.frequency += 1 - maxChance;
			} else {
				table.push({
					id: itemsIds.sapphire,
					frequency: 1 - maxChance,
				});
			}
		}

		const displayItem = itemList[table[0].id];

		return (
			<IdlescapeWrappingTooltip
				content={
					<div className='resource-container-tooltip'>
						<div className='resource-container-loot-title'>Refining Loot</div>
						<div>{(100 * probability).toFixed(2)}% Chance per action</div>
						<Box className='resource-container-resource-list'>
							<ResourceList resources={table} />
						</Box>
					</div>
				}
			>
				<div className='smithing-information-input'>
					<img src={displayItem.itemImage} alt={displayItem.name} />
					<div></div>
					<Flex className='smithing-information-input-amount' alignItems='center'>
						Refining <InfoOutlineIcon />
					</Flex>
				</div>
			</IdlescapeWrappingTooltip>
		);
	}

	const resourceCosts = activeBarResourceInput.map((resourceID) => {
		const itemData = itemList[Number(resourceID)];

		const itemAmount = smithingRecipe[Number(resourceID)];

		const heatMultiplier =
			Math.pow(activeForgeData.forgeIntensityHeatCostMult, intensity - barTier) *
			activeForgeData.forgeHeatCost *
			(1 - pyromancyStrength) *
			affixHeatCostReduction *
			(1 + crucibleStrength * 1.5);
		const materialMultiplier =
			Math.pow(activeForgeData.forgeIntensityMaterialCostMult, intensity - barTier) *
			activeForgeData.forgeMaterialCost *
			(1 - pureMetalsStrength) *
			affixMaterialCostReduction *
			(1 + crucibleStrength);

		const multiplier = itemData.id === 2 ? heatMultiplier : materialMultiplier;

		const cost = Math.ceil(itemAmount * multiplier * dwarvenElvenMatMult);

		return { resource: itemData, count: cost };
	});
	return (
		<div className='smithing-information idlescape-container'>
			{intensity < barTier && (
				<div className='smithing-furnace-locked-cover'>
					<img src='images/heat_icon.png' className='icon60' alt='Required intensity' />
					<div className='smithing-furnace-locked-cover-level'>{barTier}</div>
				</div>
			)}
			<div className='smithing-information-general'>
				<div className='smithing-information-general-section'>
					<div className='smithing-information-general-section-title'>Forge</div>
					<div className='smithing-information-general-section-content'>{activeForgeData.forgeName}</div>
				</div>
				<div className='smithing-information-general-section'>
					<div className='smithing-information-general-section-title'>Bar</div>
					<div className='smithing-information-general-section-content'>{activeBarData.name}</div>
				</div>
				<div className='smithing-information-general-section'>
					<div className='smithing-information-general-section-title'>Intensity</div>
					<div className='smithing-information-general-section-content'>{intensity}</div>
				</div>
			</div>
			<div className='smithing-information-inputs'>
				<div className='smithing-information-general-section-title'>Input</div>
				<ResourceCost resourceCosts={resourceCosts} amount={minAmount} />
			</div>
			<div className='smithing-information-output'>
				<div className='smithing-information-general-section-title'>
					Output <div className='smithing-totals'>(x{minAmount.toLocaleString('en-us')})</div>
				</div>
				{renderOutputList(
					stockpile,
					{ resourceID: activeBar, amount: outputAmount },
					minAmount,
					wealthStrength,
					scholarStrength
				)}
			</div>
			<div className='smithing-information-calculations'>
				<div className='smithing-information-input'>
					<img src='/images/clock.png' alt='Time per Action' />
					<div></div>
					<div className='smithing-information-input-amount'>
						{getTimeString(timeWithIntensityMultiplier)}
						<div className='smithing-totals'>
							(~
							{getTimeString((timeWithIntensityMultiplier * Math.ceil(minAmount)) / (1 + infSpeed), 3)})
						</div>
					</div>
				</div>
				<div className='smithing-information-input'>
					<img src='/images/total_level.png' alt='Experience per Action' />
					<div></div>
					<div className='smithing-information-input-amount'>
						{Math.floor(experience * outputAmount).toLocaleString('en-us')}xp
						<div className='smithing-totals'>
							(~{Math.floor(experience * outputAmount * minAmount).toLocaleString('en-us')}xp)
						</div>
					</div>
				</div>
				{refiningTooltip()}
			</div>
		</div>
	);
}

function renderOutputList(
	stockpile: IItem[],
	output: ISmithingOutput,
	action: number,
	wealthStrength: number,
	scholarStrength: number
) {
	let itemData = itemList[output.resourceID];
	let outputAmount = Math.floor(output.amount);
	let chanceAmount = 1;
	if (wealthStrength) {
		chanceAmount = itemData.value ?? 0;
		outputAmount *= itemData.value ?? 0;
		chanceAmount *= 1 + wealthStrength;
		outputAmount *= 1 + wealthStrength;
		itemData = itemList[itemsIds.gold];
	}
	const ownedItem = stockpile.find((item) => item.itemID === itemData.id);
	const owned = ownedItem?.stackSize ?? 0;
	if (scholarStrength && !wealthStrength) {
		return <Text textAlign='center'>Scholar destroys bars!</Text>;
	}
	return (
		<>
			<OutputMaterial
				itemID={itemData.id}
				outputAmount={outputAmount}
				owned={owned}
				totalAmount={action * output.amount}
			/>
			{output.amount % 1 > 0 && (
				<OutputMaterialChance itemID={itemData.id} outputAmount={chanceAmount} chance={output.amount % 1} />
			)}
		</>
	);
}

function OutputMaterial({
	itemID,
	outputAmount,
	owned,
	totalAmount,
}: {
	itemID: number;
	outputAmount: number;
	owned: number;
	totalAmount: number;
}) {
	const itemData = itemList[itemID];

	const ownedMinified = formatNumberToString(owned);

	return (
		<div className='smithing-information-input' data-itemid={itemID}>
			<img src={itemData.itemImage} className='smithing-information-input-icon' alt={itemData.name} />
			<div className='smithing-information-input-amount'>{formatNumberToString(Math.round(outputAmount))}</div>
			<div className='smithing-information-input-owned'>
				<img src='/images/ui/inventory_icon.png' alt='Inventory amount' className='icon16' />
				{ownedMinified}
				<div className='smithing-totals'>(~{formatNumberToString(Math.round(totalAmount))})</div>
			</div>
			<ItemTooltip item={{ itemID }} />
		</div>
	);
}

function OutputMaterialChance({
	itemID,
	outputAmount,
	chance,
}: {
	itemID: number;
	outputAmount: number;
	chance: number;
}) {
	const itemData = itemList[itemID];

	const percentage = Math.round(chance * 100);

	return (
		<div className='smithing-information-input' data-itemid={itemID}>
			<img src={itemData.itemImage} className='smithing-information-input-icon' alt={itemData.name} />
			<div className='smithing-information-input-amount'>{formatNumberToString(Math.round(outputAmount))}</div>
			<div className='smithing-information-input-owned'>
				<img src='/images/fishing/chance_icon.png' alt='Chance for extra output' className='icon16' />~
				{percentage}%
			</div>
			<ItemTooltip item={{ itemID }} />
		</div>
	);
}
