import React, { useState } from 'react';
import { EnchantmentData } from '../EnchantmentData';
import { abilities as abilityList } from '../../../utils/abilityList';
import Tooltip from '../../layout/UI/Tooltip';
import ResourceList from '../ResourceList';
import { Box, BoxProps, Grid } from '@chakra-ui/react';
import { ICombatStatsData } from '../../../../../game-server/src/modules/combat/combatstats.interface';
import { ILoot } from '../../../../../game-server/src/modules/items/LootInterface';
import { IEnchantment } from '../../../../../game-server/src/modules/enchantment/enchantment.interface';
import { IdlescapeTooltip } from '@idlescape/ui';
import useIsMobile from '../../../hooks/useIsMobile';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { enchantmentOrder } from '../Tooltips/UI/LegacyTooltipPresenter';

function CombatStats({
	stats,
	enchants,
	loot,
	showMoreLootUnlockable,
	...props
}: {
	stats: ICombatStatsData;
	enchants?: IEnchantment[];
	loot?: ILoot[];
	showMoreLootUnlockable?: boolean;
} & BoxProps) {
	const [activeContainer, setActiveContainer] = useState('offence');
	const isMobile = useIsMobile();

	function getEnchantInfo() {
		return enchants?.filter((enchant) => enchantmentsList[enchant.id]) ?? [];
	}

	function getAbilityInfo() {
		let dps = 0;
		let timeLeft = 1;
		const attackSpeed = stats.attackSpeed;
		const str = stats.strength * 2 + (stats.masteryStrength ?? 0) + stats.weapon.strength * 2;
		const mgc = stats.magic * 2 + (stats.masteryMagic ?? 0) + stats.weapon.intellect * 2;
		const rng = stats.range * 2 + (stats.masteryRange ?? 0) + stats.weapon.dexterity * 2;
		const minHitMult = stats.hitMults.minimum;
		const maxHitMult = stats.hitMults.maximum;
		let meleeAbilityCount = 0;
		let magicAbilityCount = 0;
		let rangeAbilityCount = 0;
		let bestOffensiveScaling = ['none', 0];
		const critChance = stats.offensiveCritical.chance;
		const critDamage = stats.offensiveCritical.damageMultiplier;
		for (const ability in stats.abilities) {
			if (abilityList[stats.abilities[ability].id]) {
				const abilityHere = abilityList[stats.abilities[ability].id];
				const minHere = minHitMult * abilityHere.baseMinimumDamageCoeff;
				const maxHere = maxHitMult * abilityHere.baseMaximumDamageCoeff;
				let damageHere = 0;
				if (abilityHere.damageType === 'Magic') {
					damageHere = (minHere * mgc + maxHere * mgc) / 2;
					magicAbilityCount++;
				} else if (abilityHere.damageType === 'Melee') {
					damageHere = (minHere * str + maxHere * str) / 2;
					meleeAbilityCount++;
				} else if (abilityHere.damageType === 'Range') {
					damageHere = (minHere * rng + maxHere * rng) / 2;
					rangeAbilityCount++;
				}
				let damageBonus = 0;
				for (const damScale of abilityHere.damageScaling) {
					const scalingHere = (stats.offensiveDamageAffinity[damScale.affinity] ?? 1) * damScale.scaling;
					if (scalingHere > 1) {
						damageBonus += scalingHere;
					}
					if (scalingHere > bestOffensiveScaling[1]) {
						bestOffensiveScaling = [damScale.affinity, scalingHere];
					}
				}
				// Affinities are averaged
				damageBonus /= abilityHere.damageScaling.length ?? 1;
				damageHere *= damageBonus;
				damageHere *= critChance * critDamage + (1 - critChance);
				const dot = abilityHere.healthChangeEvent;
				if (dot && !dot.targetsSelf && !abilityHere.targetFriendly) {
					damageHere += dot.dotCount * -dot.healthChange;
				}
				const abilitySpeed = attackSpeed * abilityHere.baseSpeedCoeff;
				const cooldown = (abilityHere.cooldown ?? 0) / 1000;
				// If the next ability has no cooldown (auto attack) or is longer than the time left
				if (!cooldown || abilitySpeed / cooldown > timeLeft) {
					dps += (damageHere / abilitySpeed) * timeLeft;
					timeLeft = 0;
					break;
				}
				dps += damageHere / cooldown;
				timeLeft -= abilitySpeed / cooldown;
			}
		}

		let rotationFocus: string;
		if (meleeAbilityCount > magicAbilityCount + rangeAbilityCount) {
			rotationFocus = 'Melee';
		} else if (magicAbilityCount > meleeAbilityCount + rangeAbilityCount) {
			rotationFocus = 'Magic';
		} else if (rangeAbilityCount > meleeAbilityCount + magicAbilityCount) {
			rotationFocus = 'Range';
		} else {
			rotationFocus = 'Hybrid';
		}
		/* if (dps != undefined) {
			abilityRender = (
				<>
					<p>Primary Damage: {rotationFocus}</p>
					<p>
						Primary Scaling: {bestOffensiveScaling[0]}: {bestOffensiveScaling[1].toFixed(2)}
					</p>
					<p>~{dps.toFixed(2)} dps @ 100% accuracy vs. neutral affinities</p>
				</>
			);
		} */

		const dataToReturn = { dps, primaryDamageType: rotationFocus };

		return dataToReturn;
	}

	function renderOffence() {
		const offensiveAffinities = Object.entries(stats.offensiveDamageAffinity).map(
			([affinityName, affinityValue]) => {
				return { affinityName, affinityValue };
			}
		);
		offensiveAffinities.sort((a, b) => b.affinityValue - a.affinityValue);

		const damageInfo = getAbilityInfo();

		return (
			<>
				<div className='combat-stat-group'>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Attack Speed</div>
						<div className='combat-stat-value'>{stats.attackSpeed}s</div>
					</div>
					<div className='combat-stat-group'>
						<div className='combat-stat'>
							<div className='combat-stat-name'>
								Damage Per Second
								<span
									className={`combat-attack-type ${damageInfo.primaryDamageType.toLowerCase()}-text`}
								>
									{' '}
									{damageInfo.primaryDamageType.toUpperCase()}
								</span>
							</div>
							<div className={`combat-stat-value ${damageInfo.primaryDamageType.toLowerCase()}-text`}>
								{damageInfo.dps.toFixed(0)}
							</div>
						</div>
					</div>
				</div>
				<div className='combat-stat-group'>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Crit Chance</div>
						<div className='combat-stat-value'>{(stats.offensiveCritical.chance * 100).toFixed(2)}%</div>
					</div>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Crit Multiplier</div>
						<div className='combat-stat-value'>
							{(stats.offensiveCritical.damageMultiplier * 100).toFixed(2)}%
						</div>
					</div>
				</div>
				<div className='combat-stat-group'>
					<div className='combat-stat-group-heading'>Offensive Affinities</div>
					{offensiveAffinities.length == 0 ? (
						<div className='combat-stat-sub-name'>{'None'}</div>
					) : (
						offensiveAffinities.map((affinity) => {
							return (
								<div className='combat-stat' key={'CombatStats-OffAff-' + affinity.affinityName}>
									<div className='combat-stat-sub-name'>{affinity.affinityName}</div>
									<div
										className={`combat-stat-value ${
											affinity.affinityValue - 1 >= 0 ? 'green-text' : 'red-text'
										}`}
									>
										{((affinity.affinityValue - 1) * 100).toFixed(1)}%
									</div>
								</div>
							);
						})
					)}
				</div>
			</>
		);
	}

	function renderDefence() {
		const defensiveAffinities = Object.entries(stats.defensiveDamageAffinity).map(
			([affinityName, affinityValue]) => {
				return { affinityName, affinityValue };
			}
		);
		defensiveAffinities.sort((a, b) => b.affinityValue - a.affinityValue);

		const targetCombatStats = stats;
		// Default monster threat values
		const damageThreat = 2;
		const weaponThreat = 3;
		const armorThreat = 5;
		const attackSpeedThreat = 3;
		const attackSpeedThreatLevel = 2.4 / targetCombatStats.attackSpeed;
		const attackSpeedThreatFinal = attackSpeedThreatLevel * attackSpeedThreat;
		const potentialDamageThreatFinal =
			(targetCombatStats.attack +
				targetCombatStats.strength +
				targetCombatStats.magic +
				targetCombatStats.range) *
			damageThreat;
		const weaponThreatFinal =
			(targetCombatStats.weapon.dexterity +
				targetCombatStats.weapon.intellect +
				targetCombatStats.weapon.strength) *
			weaponThreat;
		const targetArmorRating =
			targetCombatStats.armor.protection +
			targetCombatStats.armor.resistance +
			targetCombatStats.armor.agility * 1.5;
		const armorThreatFinal = (targetArmorRating + targetCombatStats.defense * 10) * armorThreat;
		const baseThreat = (potentialDamageThreatFinal + weaponThreatFinal) * attackSpeedThreatFinal + armorThreatFinal;
		const critReduction =
			stats.defensiveCritical.damageMultiplier === 0 ? 0 : (1 - stats.defensiveCritical.damageMultiplier) * 100;

		return (
			<>
				<div className='combat-stat-group'>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Base Threat</div>
						<div className='combat-stat-value'>{Math.round(baseThreat)}</div>
						<Tooltip>
							<div className='resource-container-tooltip'>
								<p>
									Weapon Threat: {Math.round(potentialDamageThreatFinal + weaponThreatFinal)}
									<br />* Attack Speed Threat: {attackSpeedThreatFinal.toFixed(1)}
									<br />+ Armor Threat: {Math.round(armorThreatFinal)}
								</p>
							</div>
						</Tooltip>
					</div>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Crit Avoidance</div>
						<div className='combat-stat-value'>{(stats.defensiveCritical.chance * 100).toFixed(2)}%</div>
					</div>
					<div className='combat-stat'>
						<div className='combat-stat-name'>Crit Reduction</div>
						<div className='combat-stat-value'>{critReduction.toFixed(2)}%</div>
					</div>
				</div>
				<div className='combat-stat-group'>
					<div className='combat-stat-group-heading'>Defensive Affinities</div>
					{defensiveAffinities.length == 0 ? (
						<div className='combat-stat-sub-name'>{'None'}</div>
					) : (
						defensiveAffinities.map((affinity) => {
							return (
								<div className='combat-stat' key={'CombatStats-DefAff-' + affinity.affinityName}>
									<div className='combat-stat-sub-name'>{affinity.affinityName}</div>
									<div
										className={`combat-stat-value ${
											affinity.affinityValue - 1 >= 0 ? 'green-text' : 'red-text'
										}`}
									>
										{((affinity.affinityValue - 1) * 100).toFixed(1)}%
									</div>
								</div>
							);
						})
					)}
				</div>
			</>
		);
	}

	function renderEnchantments() {
		const enchantInfo = getEnchantInfo();
		enchantInfo.sort((a, b) => {
			const aData = enchantmentsList[a.id];
			const bData = enchantmentsList[b.id];
			const aType = enchantmentOrder[aData.relatedSkills[0]];
			const bType = enchantmentOrder[bData.relatedSkills[0]];
			if (aType - bType) return aType - bType;
			return aData.name > bData.name ? 1 : -1;
		});

		return (
			<div className='combat-stat-group'>
				{enchantInfo.length > 0 ? (
					<div className='combat-stat-group-heading'>Active Enchantments</div>
				) : (
					<div className='combat-stat-group-heading'>No Active Enchantments</div>
				)}

				{enchantInfo.map((enchant) => {
					const enchantData = EnchantmentData.findEnchantmentByID(enchant.id);
					return (
						<div className={'combat-stat '} key={'CombatStats-Ench-' + enchant.id}>
							<div className={`combat-stat-sub-name enchanted-text-${enchantData.relatedSkills[0]}`}>
								{enchantData.name}
							</div>
							<div className='combat-stat-value'>{enchant.strength}</div>
							<Tooltip>{enchantData.getTooltip(enchant.strength, enchantData.strengthPerLevel)}</Tooltip>
						</div>
					);
				})}
			</div>
		);
	}

	function renderLoot() {
		if (!loot) return null;
		return (
			<div className='combat-stat-group'>
				<Grid
					templateColumns={isMobile ? 'repeat(auto-fit, 50px)' : 'repeat(auto-fit, 60px)'}
					gap='5px'
					padding='5px 0'
					justifyContent='center'
				>
					<ResourceList resources={loot} />
					{showMoreLootUnlockable && (
						<div className='item'>
							<img className='item-icon' src='/images/misc/unknown_buff.png' alt='More Loot?' />
							<IdlescapeTooltip>{"But wait. There's more."}</IdlescapeTooltip>
						</div>
					)}
				</Grid>
			</div>
		);
	}

	let tabSelection = (
		<>
			<div
				onClick={() => setActiveContainer('offence')}
				className='combat-stats-button idlescape-button idlescape-button-red'
			>
				<img className='nav-tab-icon icon-border' src='/images/combat/combat_level.png' alt='Offence' />
			</div>
			<div
				onClick={() => setActiveContainer('defence')}
				className='combat-stats-button idlescape-button idlescape-button-blue'
			>
				<img className='nav-tab-icon icon-border' src='/images/combat/defense_icon.png' alt='Defense' />
			</div>
		</>
	);

	if (enchants !== undefined) {
		tabSelection = (
			<>
				{tabSelection}
				<div
					onClick={() => setActiveContainer('enchantments')}
					className='combat-stats-button idlescape-button idlescape-button-green'
				>
					<img className='nav-tab-icon icon-border' src='/images/enchanting/scroll.png' alt='Enchantments' />
				</div>
			</>
		);
	}

	if (loot !== undefined) {
		tabSelection = (
			<>
				{tabSelection}
				<div
					onClick={() => setActiveContainer('loot')}
					className='combat-stats-button idlescape-button idlescape-button-orange'
				>
					<img className='nav-tab-icon icon-border' src='/images/ui/inventory_icon.png' alt='Loot' />
				</div>
			</>
		);
	}

	return (
		<Box {...props}>
			<div className='combat-stats-buttons combat-stat-group'>{tabSelection}</div>
			{activeContainer === 'offence' && renderOffence()}
			{activeContainer === 'defence' && renderDefence()}
			{activeContainer === 'enchantments' && renderEnchantments()}
			{activeContainer === 'loot' && renderLoot()}
		</Box>
	);
}

export default CombatStats;
