import React, { useEffect } from 'react';
import {
	defaultDropAnimationSideEffects,
	DndContext,
	DragEndEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { abilities as masterAbilityList } from '../../../../utils/abilityList';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { IAbilityData } from '../../../../../../game-server/src/modules/ability/ability.interface';
import AbilityList from './AbilityList';
import { socket } from '../../../../services/socket.service';
import { usePlayerField } from '../../../../hooks/hooks';
import { IdlescapeButton, IdlescapeTooltip } from '@idlescape/ui';
import { Box } from '@chakra-ui/react';
import CombatAbility from './CombatAbility';
import { createPortal } from 'react-dom';
import { isEqual } from 'lodash';

export default function AbilityEditor() {
	const learned = usePlayerField('learnedAbilities');
	const known = usePlayerField('knownAbilities');
	const stats = usePlayerField('combatStats');
	const skills = usePlayerField('skills');

	const [filter, setFilter] = React.useState<string[]>(['Melee', 'Range', 'Magic', 'Support']);
	const [activeAbility, setActiveAbility] = React.useState<IAbilityData | null>(null);

	const [rotation, setRotation] = React.useState<IAbilityData['id'][]>(stats.abilities.map((ability) => ability.id));
	const [oldRotation, setOldRotation] = React.useState<IAbilityData['id'][]>(
		stats.abilities.map((ability) => ability.id)
	);
	useEffect(() => {
		setRotation(stats.abilities.map((ability) => ability.id));
		setOldRotation(stats.abilities.map((ability) => ability.id));
	}, [stats.abilities]);

	const filterOptions = [
		{ name: 'Melee', img: '/images/combat/strength_icon.png' },
		{ name: 'Range', img: '/images/combat/range_icon.png' },
		{ name: 'Magic', img: '/images/magic/magic_logo.png' },
		{ name: 'Support', img: '/images/combat/defense_icon.png' },
	];

	const settings = usePlayerField('settings');
	const autoSortEnabled = settings.combat.autoRotation ?? false;

	const attack = skills.attack.level;
	const strength = skills.strength.level;
	const defense = skills.defense.level;
	const range = skills.range.level;
	const magic = skills.magic.level;
	const constitution = skills.constitution.level;
	const sum = attack + strength + defense + range + magic + constitution;
	const max = Math.max(2, Math.round(sum / 50));
	const rotationString = `Priority ${rotation.length}/${max}`;

	const abilities: IAbilityData[] = known.map((abilityId: number) => masterAbilityList[abilityId]);
	// TODO: maxHit calculation probably copied from backend, needs to be refactored
	const maxHits = { melee: { min: 0, max: 1 }, range: { min: 0, max: 1 }, magic: { min: 0, max: 1 } };
	const str = stats.strength * 2 + (stats.masteryStrength ?? 0) + stats.weapon.strength * 2;
	const mgc = stats.magic * 2 + (stats.masteryMagic ?? 0) + stats.weapon.intellect * 2;
	const rng = stats.range * 2 + (stats.masteryRange ?? 0) + stats.weapon.dexterity * 2;
	const minHitMult = stats.hitMults.minimum;
	const maxHitMult = stats.hitMults.maximum;
	if (maxHitMult * str > 0 && minHitMult * str > 0) {
		maxHits.melee.min = minHitMult * str;
		maxHits.melee.max = maxHitMult * str;
	}
	if (maxHitMult * mgc > 0 && minHitMult * mgc > 0) {
		maxHits.magic.min = minHitMult * mgc;
		maxHits.magic.max = maxHitMult * mgc;
	}
	if (maxHitMult * rng > 0 && minHitMult * rng > 0) {
		maxHits.range.min = minHitMult * rng;
		maxHits.range.max = maxHitMult * rng;
	}
	maxHits.melee.min = Math.floor(maxHits.melee.min);
	maxHits.melee.max = Math.floor(maxHits.melee.max);
	maxHits.magic.min = Math.floor(maxHits.magic.min);
	maxHits.magic.max = Math.floor(maxHits.magic.max);
	maxHits.range.min = Math.floor(maxHits.range.min);
	maxHits.range.max = Math.floor(maxHits.range.max);

	function maxHit(ability: IAbilityData) {
		if (!ability || ability.dealsNoDamage) {
			return 0;
		}
		let sum = ability.maxTargets * ability.baseSpeedCoeff;
		switch (ability.damageType) {
			case 'Melee':
				sum *= (maxHits.melee.max + maxHits.melee.min) / 2;
				break;
			case 'Range':
				sum *= (maxHits.range.max + maxHits.range.min) / 2;
				break;
			case 'Magic':
				sum *= (maxHits.magic.max + maxHits.magic.min) / 2;
				break;
		}
		return Math.floor(sum);
	}

	abilities.sort((a: IAbilityData, b: IAbilityData) => {
		if (!a || !b) {
			return 0;
		}
		const damageTypeOrder = ['Melee', 'Range', 'Magic'];
		if (a.damageType !== b.damageType) {
			return damageTypeOrder.indexOf(a.damageType) - damageTypeOrder.indexOf(b.damageType);
		}
		return maxHit(a) - maxHit(b);
	});

	let unselectedAbilities = abilities.filter((ability) => !rotation.includes(ability.id));
	unselectedAbilities = unselectedAbilities.filter((ability: IAbilityData) => {
		if (filter.includes('Support') && ability.dealsNoDamage) {
			return true;
		}
		return filter.includes(ability.damageType);
	});
	const selectedAbilities = rotation.map((id) => masterAbilityList[id]);

	const sensors = useSensors(
		useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
		useSensor(TouchSensor, { activationConstraint: { delay: 100, tolerance: 5 } })
	);

	function handleDragStart({ active }: DragStartEvent) {
		const activeAbility = abilities.find((ability) => ability.id.toString() === active.id);
		if (activeAbility) setActiveAbility(activeAbility);
	}

	function handleDragOver({ active, over }: DragOverEvent) {
		// We need to move the ability in/out of the rotation, when switching between the two lists
		// while still dragging, for the preview to work
		const activeId = Number(active.id);
		const overId = over?.id;
		if (!overId) return;

		if (
			overId !== 'selectedAbilities' &&
			rotation.includes(activeId) &&
			(overId === 'unselectedAbilities' || !rotation.includes(Number(overId)))
		) {
			removeAbility(activeId);
		} else if (
			overId !== 'unselectedAbilities' &&
			!rotation.includes(activeId) &&
			(overId === 'selectedAbilities' || rotation.includes(Number(overId)))
		) {
			addAbility(activeId);
		}
	}

	function handleDragEnd({ active, over }: DragEndEvent) {
		setActiveAbility(null);
		if (!over) {
			return;
		}
		const activeId = Number(active.id);
		const overId = Number(over.id);
		if (!activeId || !overId) {
			return;
		}
		if (rotation.includes(activeId) && rotation.includes(overId)) {
			const oldIndex = rotation.indexOf(activeId);
			const newIndex = rotation.indexOf(overId);
			setRotation((rotation) => {
				let newRotation = [...rotation];
				newRotation = arrayMove(newRotation, oldIndex, newIndex);
				saveRotation(newRotation);
				return newRotation;
			});
		} else {
			saveRotation(rotation);
		}
	}

	function addAbility(abilityId: number, save = false) {
		if (save) {
			// insert ability at second last position -> filler ability gets overwritten
			const newRotation = [...rotation.slice(0, -1), abilityId, rotation[rotation.length - 1]];
			saveRotation(newRotation);
		} else {
			setRotation((rotation) => [...rotation.slice(0, -1), abilityId, rotation[rotation.length - 1]]);
		}
	}

	function removeAbility(abilityId: number, save = false) {
		if (save) {
			const newRotation = rotation.filter((id) => id !== abilityId);
			saveRotation(newRotation);
		} else {
			setRotation((rotation) => rotation.filter((id) => id !== abilityId));
		}
	}

	function saveRotation(rotation: number[]) {
		if (isEqual(rotation, oldRotation)) return;
		socket.emit('combat:setAbilityRotation', { rotation });
	}

	function autoSort() {
		socket.emit('combat:requestRotationSort');
	}

	function handleFilterButtons(filterInput: string) {
		if (filter.includes(filterInput)) {
			setFilter(filter.filter((filter) => filter !== filterInput));
		} else {
			setFilter([...filter, filterInput]);
		}
	}

	return (
		<DndContext
			onDragStart={handleDragStart}
			onDragOver={handleDragOver}
			onDragEnd={handleDragEnd}
			sensors={sensors}
		>
			<SortableContext items={selectedAbilities.map((ability) => ability.id.toString())}>
				<div className='combat-abilities'>
					<div className='combat-abilities-header'>
						{rotationString}
						{!autoSortEnabled && (
							<IdlescapeButton variant='blue' onClick={autoSort}>
								Auto-Sort
								<IdlescapeTooltip>
									Automatically tries to bring your selected abilities in a good order. Might not be
									perfect, but is a good starting point.
								</IdlescapeTooltip>
							</IdlescapeButton>
						)}
					</div>
					<AbilityList
						abilities={selectedAbilities}
						learnedAbilities={learned}
						onClick={(abilityId) => typeof abilityId === 'number' && removeAbility(abilityId, true)}
						dropAreaID='selectedAbilities'
					/>
				</div>
			</SortableContext>
			<SortableContext items={unselectedAbilities.map((ability) => ability.id.toString())}>
				<div className='combat-abilities'>
					<div className='combat-abilities-header'>
						Abilities
						<Box paddingLeft='20px'>
							{filterOptions.map((option) => {
								return (
									<span
										key={option.name}
										onClick={() => {
											handleFilterButtons(option.name);
										}}
									>
										<img
											className={`scrollcrafting-filter-image ${
												filter.includes(option.name) && 'clicked'
											}`}
											src={option.img}
											alt={option.name}
										/>
									</span>
								);
							})}
						</Box>
					</div>
					<AbilityList
						abilities={unselectedAbilities}
						learnedAbilities={learned}
						onClick={(abilityId) => typeof abilityId === 'number' && addAbility(abilityId, true)}
						dropAreaID='unselectedAbilities'
					/>
				</div>
			</SortableContext>
			{createPortal(
				<DragOverlay
					dropAnimation={{
						sideEffects: defaultDropAnimationSideEffects({
							styles: {
								active: {
									opacity: '0.5',
								},
							},
						}),
					}}
				>
					{activeAbility ? (
						<CombatAbility
							ability={activeAbility}
							learned={learned.includes(activeAbility.id)}
							noTooltip={true}
							cursor='grabbing'
						/>
					) : null}
				</DragOverlay>,
				document.getElementsByClassName('game-container')[0] as HTMLElement
			)}
		</DndContext>
	);
}
