export const monstersIds = {
	chicken: 1,
	cow: 2,
	goblin: 3,
	rat: 4,
	guard: 5,
	militia_crossbowman: 6,
	hobgoblin: 7,
	greater_imp: 8,
	deadly_spider: 9,
	deadly_red_spider: 10,
	lesser_demon: 11,
	greater_demon: 12,
	black_knight: 13,
	moss_giant: 14,
	fire_giant: 15,
	ice_giant: 16,
	bone_giant: 17,
	cursed_naga: 18,
	corrupted_tree: 19,
	chaos_giant: 20,
	chaotic_abomination: 21,
	swordfish: 22,
	ascended: 23,
	aberrant_shrimp: 24,
	spriggan: 25,
	elven_hunter: 26,
	elven_warrior: 27,
	elven_mage: 28,
	elven_rider: 29,
	wild_boar: 30,
	wolf: 31,
	corpse_eater: 32,
	dark_mage: 33,
	reanimated_bones: 34,
	desiccated_priest: 35,
	glass_mage: 36,
	glass_knight: 37,
	glass_ranger: 38,
	deep_pit_demon: 39,
	ot_tos_prized_hen: 500,
	chocolate_cow: 501,
	overseer_demon: 502,
	blood_spider: 503,
	vassal_demon: 504,
	marshall_the_town_rat: 505,
	marshall_the_town_rat_2: 506,
	big_gobbo: 507,
	elder_spriggan: 508,
	perennial_giant: 509,
	volcanic_giant: 510,
	glacial_giant: 511,
	imbued_black_knight: 512,
	ghastly_giant: 513,
	corrupted_naga: 514,
	swordfish_champion: 515,
	ancient_ascended: 516,
	voidtouched_giant: 517,
	obsidian_mage: 518,
	obsidian_knight: 519,
	obsidian_ranger: 520,
	possessed_mage: 521,
	pactbound_sorcerer: 522,
	nox_wolf: 523,
	royal_elven_hunter: 524,
	royal_elven_warrior: 525,
	royal_elven_mage: 526,
	royal_elven_rider: 527,
	black_knight_titan: 528,
	exiled_preceptor: 529,
	corrupted_elder_tree: 530,
	info: 1000,
	giant_king: 1006,
	giant_king_2: 1007,
	just_a_chicken: 1008,
	phoenix: 1009,
	phoenix_2: 1010,
	phoenix_3: 1011,
	phoenix_4: 1012,
	the_advisor: 1013,
	elven_king: 1014,
	the_regent: 1015,
	angy_wolf: 1016,
	angy_boar: 1017,
	storm_watcher: 1018,
	the_shrimp_god_phase_1: 1019,
	the_shrimp_god_phase_2: 1020,
	palaemagos_avatar_of_the_true_shrimp_god: 1021,
	goblin_chef: 1022,
	wrath: 1023,
	loot_goblin: 1024,
	ancient_dwarven_golem: 1025,
	living_wood_wolf: 1026,
	water_elemental: 1027,
	corrupted_titan: 1028,
	palaekratos_the_unbroken: 1029,
	palaedromos_the_untouched: 1030,
	summoned_shrimp: 2000,
	corrupted_knight: 2001,
	shrimp_god_tentacle: 2002,
	dps_dummy: 10000
}
