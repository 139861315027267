import { Grid } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeInput } from '@idlescape/ui';
import React, { useRef } from 'react';
import { BiArrowBack, BiRefresh } from 'react-icons/bi';
import { useRecoilState } from 'recoil';
import { marketplaceFilterAtom } from '../../../atoms/marketplaceFilterAtom';
import { marketplaceItemAtom } from '../../../atoms/marketplaceItemAtom';
import { marketplaceTabAtom } from '../../../atoms/marketplaceTabAtom';
import useIsMobile from '../../../hooks/useIsMobile';
import { socket } from '../../../services/socket.service';
import MarketplaceBuy from './MarketplaceBuy';
import MarketplaceHistory from './MarketplaceHistory';
import MarketplaceInventory from './MarketplaceInventory';
import MarketplaceListings from './MarketplaceListings';
import MarketplaceOffers from './MarketplaceOffers';

export const Marketplace = () => {
	const [marketTab, setMarketTab] = useRecoilState(marketplaceTabAtom);
	const [marketFilter, setMarketFilter] = useRecoilState(marketplaceFilterAtom);
	const [itemToBuy, setItemToBuy] = useRecoilState(marketplaceItemAtom);
	const isMobile = useIsMobile();

	const refreshButtonRef = useRef<HTMLButtonElement>(null);
	const historyButtonRef = useRef<HTMLButtonElement>(null);

	const historyButton = {
		search: (filter: string) => {
			/** */
		},
	};

	function refreshListing() {
		if (!refreshButtonRef.current) return;
		refreshButtonRef.current.disabled = true;
		switch (marketTab) {
			case 'buy':
				socket.emit('marketplace:manifest:get');
				break;
			case 'listings':
				socket.emit('marketplace:listings:player:get');
				break;
			case 'offers':
				socket.emit('marketplace:offer:player:get');
				break;
		}
		if (itemToBuy) {
			socket.emit('marketplace:listing:item:get', itemToBuy);
			socket.emit('marketplace:offer:items:get', itemToBuy);
		}
		setTimeout(() => {
			if (refreshButtonRef.current) refreshButtonRef.current.disabled = false;
		}, 1000);
	}
	function filterKeyPress(e: React.KeyboardEvent) {
		if (e.key === 'Enter') handleHistoryButton();
	}

	function handleHistoryButton() {
		if (!historyButtonRef.current) return;
		historyButtonRef.current.disabled = true;
		historyButton.search(marketFilter);
		setTimeout(() => {
			if (historyButtonRef.current) historyButtonRef.current.disabled = false;
		}, 1000);
	}

	return (
		<div className='marketplace-container'>
			<Grid className='marketplace-navigation' gridTemplateColumns={'repeat(4, 150px) 1fr'}>
				{marketTab === 'buy' && itemToBuy ? (
					<IdlescapeButton
						filter={marketTab === 'buy' ? 'brightness(1.3)' : ''}
						variant='red'
						width='100%'
						onClick={() => {
							setItemToBuy(null);
						}}
					>
						<BiArrowBack />
						Back
					</IdlescapeButton>
				) : (
					<IdlescapeButton
						filter={marketTab === 'buy' ? 'brightness(1.3)' : ''}
						variant='green'
						width='100%'
						onClick={() => {
							setMarketTab('buy');
						}}
					>
						<img
							className='nav-tab-icon icon-border'
							src='/images/ui/marketplace_icon.png'
							alt='Marketplace'
						/>
						Market
					</IdlescapeButton>
				)}
				<IdlescapeButton
					filter={marketTab === 'listings' ? 'brightness(1.3)' : ''}
					variant='orange'
					width='100%'
					onClick={() => {
						setMarketTab('listings');
					}}
				>
					<img className='nav-tab-icon icon-border' src='/images/money_icon.png' alt='Listings' />
					Listings
				</IdlescapeButton>
				<IdlescapeButton
					filter={marketTab === 'offers' ? 'brightness(1.3)' : ''}
					variant='purple'
					width='100%'
					onClick={() => {
						setMarketTab('offers');
					}}
				>
					<img className='nav-tab-icon icon-border' src='/images/tutorial/quest_point.png' alt='Offers' />
					Offers
				</IdlescapeButton>
				<IdlescapeButton
					filter={marketTab === 'history' ? 'brightness(1.3)' : ''}
					width='100%'
					onClick={() => {
						setMarketTab('history');
					}}
				>
					<img className='nav-tab-icon icon-border' src='/images/enchanting/scroll.png' alt='History' />
					History
				</IdlescapeButton>
				{isMobile && (
					<IdlescapeButton
						filter={marketTab === 'inventory' ? 'brightness(1.3)' : ''}
						variant='blue'
						width='100%'
						onClick={() => {
							setMarketTab('inventory');
						}}
					>
						Inventory
					</IdlescapeButton>
				)}
				<div className='runecrafting-info'>
					{marketTab === 'buy' && (
						<>
							Only the items that are <b className='runecrafting-text'>currently being sold</b> will be
							listed here.
						</>
					)}
					{marketTab === 'listings' && 'Listings older than 30 days will be returned to your inventory.'}
					{marketTab === 'offers' && 'Offers older than 30 days will be returned to your inventory.'}
					{marketTab === 'history' && 'List of all your previous market transactions'}
					{marketTab === 'inventory' && (
						<>
							There is a <b className='runecrafting-text'>5% fee</b> on items sold in the marketplace.
						</>
					)}
				</div>
			</Grid>
			<Grid
				className='marketplace-search'
				gridTemplateColumns={marketTab === 'history' ? '35px 1fr 80px' : '35px 1fr'}
				alignItems='center'
			>
				<IdlescapeButton
					ref={refreshButtonRef}
					variant='blue'
					width='20px'
					fontSize='20px'
					padding='0'
					onClick={refreshListing}
				>
					<BiRefresh />
				</IdlescapeButton>
				<IdlescapeInput
					variant='search'
					setValue={setMarketFilter}
					value={marketFilter}
					onKeyUp={filterKeyPress}
					type='search'
					className='anchor-marketplace-filter'
				/>
				{marketTab === 'history' && (
					<IdlescapeButton ref={historyButtonRef} width='80px' variant='green' onClick={handleHistoryButton}>
						Search
					</IdlescapeButton>
				)}
			</Grid>
			<div className='marketplace-content'>
				{marketTab === 'buy' && <MarketplaceBuy />}
				{marketTab === 'listings' && isMobile && <MarketplaceListings />}
				{marketTab === 'listings' && !isMobile && (
					<Grid gridTemplateColumns='1fr 1fr' overflowY='hidden' height='100%'>
						<MarketplaceInventory />
						<div className='marketplace-content'>
							<MarketplaceListings />
						</div>
					</Grid>
				)}
				{marketTab === 'offers' && <MarketplaceOffers />}
				{marketTab === 'history' && <MarketplaceHistory button={historyButton} />}
				{marketTab === 'inventory' && <MarketplaceInventory />}
			</div>
		</div>
	);
};
