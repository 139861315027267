import React from 'react';
import { useSetRecoilState } from 'recoil';
import { activeTabAtom } from '../../atoms/activeTabAtom';
import { shareItemSelector } from '../../atoms/chatInputAtom';
import { usePlayerField } from '../../hooks/hooks';
import { formatNumberToString, getGoldColorFromNumber, getHeatColorFromNumber } from '../../utils/helperFunctions';
import { itemList } from '../../utils/itemList';
import { leagueList } from '../../utils/leagueList';
import { itemsIds } from '../../utils/lookup-dictionaries/lookupItemList';
import Tooltip from '../layout/UI/Tooltip';
import './NavigationTabs.css';
import './RightPanel.css';

export const CurrencyPanel = ({ setFilter }: { setFilter: React.Dispatch<React.SetStateAction<string>> }) => {
	const league = usePlayerField('league');
	const stockpile = usePlayerField('stockpile');
	const gold = stockpile.find((item) => item.itemID === 1);
	const heat = stockpile.find((item) => item.itemID === 2);
	const platinum = usePlayerField('platinum');
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const shareItem = useSetRecoilState(shareItemSelector);

	const heatRealSize = heat?.stackSize ?? 0;
	const goldRealSize = gold?.stackSize ?? 0;
	const goldMinified = formatNumberToString(goldRealSize);
	const goldColor = getGoldColorFromNumber(goldRealSize);
	const heatMinified = formatNumberToString(heatRealSize);
	const heatColor = getHeatColorFromNumber(heatRealSize);

	function goToMarketplace() {
		// Only go to marketplace if it is enabled in the league
		if (leagueList[league].rules.marketplace) {
			setActiveTab('marketplace');
		} else {
			setActiveTab('general-shop');
		}
	}

	return (
		<div className='right-panel-currency'>
			<div
				className={'right-panel-currency-item ' + goldColor}
				onClick={(e) => (e.ctrlKey || e.metaKey ? shareItem(gold?.id.toString() ?? '') : goToMarketplace())}
			>
				<img
					src='/images/money_icon.png'
					alt='Gold coins icon'
					className='right-panel-currency-item-icon icon75'
				/>
				<div id='gold' className='right-panel-currency-item-text'>
					{goldMinified}
				</div>
				<Tooltip>
					<div className='text-center'>
						<img src='/images/money_icon.png' alt='Gold coins icon' className='icon16' />
						<span>{Number(goldRealSize).toLocaleString('en-us') || 0}</span>
					</div>
					<div className='text-center'>
						<p>
							Gold is used to purchase items from the market and shops.
							<br />
							You can get more gold by selling resources or fighting monsters.
						</p>
					</div>
				</Tooltip>
			</div>
			<div
				className={'right-panel-currency-item ' + heatColor}
				onClick={(e) => (e.ctrlKey || e.metaKey ? shareItem(heat?.id.toString() ?? '') : setFilter('^heat'))}
			>
				<img src='/images/heat_icon.png' alt='Heat icon' className='right-panel-currency-item-icon icon75' />
				<span id='heat' className='right-panel-currency-item-text'>
					{heatMinified}
				</span>
				<Tooltip>
					<div className='text-center'>
						<img src='/images/heat_icon.png' alt='Heat icon' className='icon16' />
						<span>{Number(heatRealSize).toLocaleString('en-us') || 0}</span>
					</div>
					<div className='text-center'>
						<p>
							Heat is used to smelt bars, cook food. <br />
							You can get heat by burning various items such as logs, branches and coal.
						</p>
					</div>
				</Tooltip>
			</div>
			<div
				className='right-panel-currency-item right-panel-currency-item-platinum'
				onClick={() =>
					// e.ctrlKey || e.metaKey ? shareItem(platinum) :
					setActiveTab('platinum-shop')
				}
			>
				<img
					src='/images/premium/platinum_icon.png'
					alt='Platinum icon'
					className='right-panel-currency-item-icon icon75'
				/>
				<span id='platinum' className={'right-panel-currency-item-text '}>
					{platinum.toLocaleString('en-us')}
				</span>
				<Tooltip>
					<div className='text-center'>
						<img src='/images/premium/platinum_icon.png' alt='Platinum icon' className='icon16' />
						<span>{Number(platinum).toLocaleString('en-us') || 0}</span>
					</div>
					<p>{itemList[itemsIds.platinum].extraTooltipInfo}</p>
				</Tooltip>
			</div>
		</div>
	);
};
