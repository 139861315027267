import React from 'react';
import { useRecoilState } from 'recoil';
import { enchantingTabAtom } from '../../../../atoms/enchantingTabAtom';
import Augmenting from './Augmenting/Augmenting';
import Enchanting from './Enchanting';
import './Enchanting.css';
import Scrapping from './Scrapping/Scrapping';
import Scrollcrafting from './Scrollcrafting';

export default function EnchantingContainer() {
	const [tab, setTab] = useRecoilState(enchantingTabAtom);
	return (
		<div className='enchanting-container'>
			<div className={'enchanting-tabs'}>
				<div
					onClick={() => setTab('scrollcrafting')}
					className={tab === 'scrollcrafting' ? 'enchanting-tab-selected' : ''}
				>
					<img
						src='/images/enchanting/enchanted_scroll.png'
						alt='Scrollcrafting logo'
						className='enchanting-tab-icon'
					></img>
					<span>Scrollcrafting</span>
				</div>
				<div
					onClick={() => setTab('augmenting')}
					className={tab === 'augmenting' ? 'enchanting-tab-selected' : ''}
				>
					<img
						src='/images/enchanting/augmenting_logo.png'
						alt='Augmenting logo'
						className='enchanting-tab-icon'
					></img>
					<span>Augmenting</span>
				</div>
				<div
					onClick={() => setTab('scrapping')}
					className={tab === 'scrapping' ? 'enchanting-tab-selected' : ''}
				>
					<img
						src='/images/runecrafting/dust/gear_scrap_common.png'
						alt='Scrapping logo'
						className='enchanting-tab-icon'
					></img>
					<span>Item Research</span>
				</div>
				<div
					onClick={() => setTab('enchanting')}
					className={tab === 'enchanting' ? 'enchanting-tab-selected' : ''}
				>
					<img
						src='/images/enchanting/enchanting_logo.png'
						alt='Enchanting logo'
						className='enchanting-tab-icon'
					></img>
					<span>Enchanting</span>
				</div>
			</div>
			{tab === 'scrollcrafting' && <Scrollcrafting />}
			{tab === 'augmenting' && <Augmenting />}
			{tab === 'enchanting' && <Enchanting />}
			{tab === 'scrapping' && <Scrapping />}
		</div>
	);
}
