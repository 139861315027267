import { Heading } from '@chakra-ui/react';
import AllItems from '../AllItems2';

export default function CombatAmmoInventory() {
	return (
		<div className='combat-gear-inventory-wrapper idlescape-container idlescape-container-light'>
			<Heading textAlign='center' size={'md'}>
				Ammo Pouch
			</Heading>
			<div className='fishing-info-title-border'></div>
			<AllItems hideCurrency hideSorting inventoryName='ammo' />
		</div>
	);
}
