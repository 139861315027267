import { useDisclosure } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { TTrueInventoryType } from '../../../../../game-server/src/modules/items/Inventory.interface';
import { IItem } from '../../../../../game-server/src/modules/items/items.interface';
import { shareItemSelector } from '../../../atoms/chatInputAtom';
import { usePlayerField } from '../../../hooks/hooks';
import useIsMobile from '../../../hooks/useIsMobile';
import { itemList } from '../../../utils/itemList';
import { Item, ItemProps } from './Item';
import './Item.css';
import MainItemDialog from './ItemDialogs/MainItemDialog';

export interface IItemOverwriteDialogProps {
	item: IItem;
	onClose: () => void;
	inventoryName: TTrueInventoryType;
}

export default function InventoryItem({
	item,
	renameTokenId,
	overwriteDialog: OverwriteDialog,
	overrideOnClick,
	inventoryName,
	...props
}: {
	item: IItem;
	renameTokenId?: IItem['id'];
	overwriteDialog?: React.ComponentType<IItemOverwriteDialogProps>;
	overrideOnClick?: (event: React.MouseEvent) => boolean; // return true to prevent default behavior
	inventoryName?: TTrueInventoryType;
	allowAnimation?: boolean;
} & ItemProps) {
	const isMobile = useIsMobile();
	const showCurrency = usePlayerField('settings').miscellaneous.showCurrencyAsItems;
	const shareItem = useSetRecoilState(shareItemSelector);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const doAction = useDisclosure();
	const [counter, setCounter] = useState(0);

	const itemData = itemList[item.itemID];

	// Drag and Drop stuff
	const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
		id: item.id.toString(),
	});
	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
		zIndex: active ? 1 : undefined,
		opacity: active && active.id === item.id.toString() ? 0.5 : undefined,
	} as React.CSSProperties;

	const isInVault = inventoryName === 'vault';
	if (itemData.id <= 49 && !showCurrency && !isInVault && !isMobile) return null;

	function handleOnClick(e: React.MouseEvent) {
		if (overrideOnClick !== undefined) {
			const preventDefault = overrideOnClick(e);
			if (preventDefault) return;
		}
		if (e.ctrlKey || e.metaKey) {
			shareItem(item.id.toString());
		} else if (e.shiftKey) {
			// Counter to render a new empty dialog when going too fast
			setCounter(counter + 1);
			doAction.onOpen();
		} else {
			onOpen();
		}
	}

	return (
		<>
			<Item
				item={item}
				onClick={handleOnClick}
				ref={setNodeRef}
				allowAnimation
				style={style}
				showTooltip={!isMobile && !OverwriteDialog}
				mobileModal={false}
				{...listeners}
				{...attributes}
				{...props}
			/>
			{isOpen &&
				(OverwriteDialog !== undefined ? (
					<OverwriteDialog item={item} onClose={onClose} inventoryName={inventoryName ?? 'stockpile'} />
				) : (
					<MainItemDialog
						item={item}
						inventoryName={inventoryName ?? ''}
						renameTokenId={renameTokenId}
						onClose={onClose}
					/>
				))}
			{doAction.isOpen && (
				<React.Fragment key={counter}>
					<MainItemDialog
						item={item}
						inventoryName={inventoryName ?? ''}
						onClose={doAction.onClose}
						doAction={true}
					/>
				</React.Fragment>
			)}
		</>
	);
}
