import { Box, Grid, Text } from '@chakra-ui/react';
import { IdlescapeFrameBox, IdlescapeWrappingTooltip } from '@idlescape/ui';
import React, { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { socket } from '../../../services/socket.service';
import { PERSONAL_CONTRIBUTION_THRESHOLDS } from '../../../utils/constantsCollection';
import { formatNumberToString } from '../../../utils/helperFunctions';
import { ProgressBar } from '../../layout/UI/ProgressBar';

export default function ShrinePersonalProgress() {
	const [donated, setDonated] = useState(0);
	const index = PERSONAL_CONTRIBUTION_THRESHOLDS.findIndex((threshold) => donated < threshold);

	useEffect(() => {
		// triggered by the parent and on donation
		socket.on('shrine:personal:contribution', (progress) => {
			setDonated(progress);
		});
		socket.emit('shrine:personal:contribution');
		return () => {
			socket.off('shrine:personal:contribution');
		};
	}, []);

	function tooltipContent() {
		if (index === PERSONAL_CONTRIBUTION_THRESHOLDS.length - 1) {
			return 'You have completed all personal thresholds! You will get all the buffs once the shrine activated.';
		}
		return `Donate ${(PERSONAL_CONTRIBUTION_THRESHOLDS[index] - donated).toLocaleString(
			'en-us'
		)} more to gain access to one more buff from each altar once the shrine activates. There are ${
			PERSONAL_CONTRIBUTION_THRESHOLDS.length - index - 1
		} thresholds left.`;
	}

	function renderThresholds() {
		const thresholds: React.ReactElement[] = [];
		for (let i = 0; i < PERSONAL_CONTRIBUTION_THRESHOLDS.length - 1; i++) {
			thresholds.push(
				<div className='item-enchant-slot-slot' key={i}>
					{index > i && (
						<div className='item-enchant-slot-slot'>
							<img src='/images/16px/enchantment_filled.png' alt='Completed personal threshold' />
						</div>
					)}
					<img
						className='item-enchant-slot-image'
						src='/images/16px/enchantmentslot_socket.png'
						alt='Personal threshold'
					/>
				</div>
			);
		}
		return (
			<Box className='item-enchant-slot-container' gridColumn='1 / -1' marginTop='8px' marginBottom='0px'>
				{thresholds}
			</Box>
		);
	}

	return (
		<IdlescapeFrameBox
			as={Grid}
			gridTemplateColumns='3fr max-content'
			width='250px'
			justifySelf='right'
			padding='8px 15px'
			_hover={{}}
		>
			<Text margin='0' textAlign='center'>
				{index === 3
					? 'Completed'
					: `${donated.toLocaleString('en-us')} / ${formatNumberToString(
							PERSONAL_CONTRIBUTION_THRESHOLDS[index]
					  )}`}
			</Text>
			<IdlescapeWrappingTooltip content={tooltipContent()} activateOnClick>
				<Text fontSize='20px' cursor='pointer' margin='0' justifySelf='right'>
					<AiOutlineInfoCircle />
				</Text>
			</IdlescapeWrappingTooltip>
			<ProgressBar
				value={donated}
				max={PERSONAL_CONTRIBUTION_THRESHOLDS[index]}
				theme='shrine'
				gridColumn='1 / -1'
				height='15px'
			/>
			{renderThresholds()}
		</IdlescapeFrameBox>
	);
}
