import React from 'react';
import { Box, Flex, Image, useDisclosure } from '@chakra-ui/react';
import { AiOutlineInfoCircle, AiOutlinePushpin } from 'react-icons/ai';
import { useRecoilState } from 'recoil';
import { TActiveTab } from '../../../../game-server/src/Atypes/Skills';
import { activeTabAtom } from '../../atoms/activeTabAtom';
import { usePlayerField } from '../../hooks/hooks';
import { socket } from '../../services/socket.service';
import { tabs } from '../../utils/NavigationTabsList';
import GuideModal from './GuideModal';
import './NavigationTabs.css';
import { PlayArea } from './PlayArea';

export const NavigationTabs = () => {
	const [activeTab, setActiveTab] = useRecoilState(activeTabAtom);
	const settings = usePlayerField('settings');
	const usePinnedTabs = settings.miscellaneous.usePinnedTabs;
	const pinnedTabs = settings.miscellaneous.pinnedTabs;

	const { isOpen, onOpen, onClose } = useDisclosure();

	function togglePinnedTab(event: React.BaseSyntheticEvent, tab: TActiveTab) {
		event.stopPropagation();
		updatePinnedTabs(tab, !tabIsPinned(tab));
	}

	function updatePinnedTabs(pin: TActiveTab, add: boolean) {
		socket.emit('settings:pinnedTabs', { pin, add });
	}

	function tabIsPinned(tab: TActiveTab) {
		return pinnedTabs.includes(tab);
	}

	const Tabs = ({ tabs, asPins }: { tabs: TActiveTab[]; asPins: boolean }) => {
		return (
			<Flex>
				{tabs.map((tab) => {
					return <Tab key={tab} tab={tab} asPin={asPins}></Tab>;
				})}
			</Flex>
		);
	};

	const Tab = (props: { tab: TActiveTab; asPin: boolean; className?: string }) => {
		const tab = tabs[props.tab];
		const isPinned = tabIsPinned(tab.id);

		return (
			<Box
				className={`nav-tab ${props.className}`}
				onClick={() => {
					if (activeTab !== tab.id) {
						setActiveTab(tab.id);
					}
				}}
			>
				<Image className={`nav-tab-icon icon-border ${tab.name}`} src={tab.overrideTabIcon || tab.icon} />
				<Box className='nav-tab-name'></Box>
				{tab.overrideTabName || tab.name}
				{!props.asPin && (
					<Box fontSize={20} marginLeft={'5px'} marginTop={'4px'} cursor={'pointer'} onClick={onOpen}>
						<AiOutlineInfoCircle />
					</Box>
				)}
				{usePinnedTabs && (
					<Box
						fontSize={20}
						marginLeft={'5px'}
						marginTop={'4px'}
						cursor={'pointer'}
						onClick={(event) => togglePinnedTab(event, tab.id)}
						color={isPinned ? 'white' : 'gray'}
						_hover={{ color: !isPinned ? 'white' : 'gray' }}
					>
						<AiOutlinePushpin />
					</Box>
				)}
			</Box>
		);
	};

	return (
		<Box className='play-area-container' gridArea='play-area'>
			<Box className='nav-tab-container' justifyContent='space-between'>
				<Tab tab={activeTab} asPin={false} className='selected-tab' />
				{usePinnedTabs && <Tabs tabs={pinnedTabs} asPins={true} />}
			</Box>
			<PlayArea />
			<GuideModal isOpen={isOpen} onClose={onClose} tab={activeTab} />
		</Box>
	);
};
