import { Box, BoxProps, Flex, Grid, Modal, ModalContent, useDisclosure } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { AiFillLock, AiFillUnlock } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';
import { MdOutlineRemoveCircle } from 'react-icons/md';
import { TPartialItemWithItemID, IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import { IInspectData } from '../../../../../../game-server/src/modules/profile/Profile';
import { usePlayerField } from '../../../../hooks/hooks';
import useIsMobile from '../../../../hooks/useIsMobile';
import { socket } from '../../../../services/socket.service';
import { filterItem } from '../../../../utils/helperFunctions';
import { doItemsMatch } from '../../../../utils/itemFunctions';
import FakeItem from '../../../game/Inventory/FakeItem';

export function ItemDisplay({
	item,
	allowRemove,
	...props
}: {
	item: IInspectData['items'][0];
	allowRemove?: boolean;
} & BoxProps) {
	function removeItem(item: TPartialItemWithItemID<IItem>) {
		socket.emit('profile:item:remove', item);
	}

	return (
		<Box position='relative'>
			<FakeItem item={item} {...props} />
			{allowRemove && (
				<Flex
					onClick={() => removeItem(item)}
					position='absolute'
					top='0'
					right='0'
					fontSize='20px'
					color='red.500'
					cursor='pointer'
					zIndex='1000'
					_hover={{ transform: 'scale(1.1)' }}
				>
					<MdOutlineRemoveCircle />
				</Flex>
			)}
		</Box>
	);
}

export function ItemEditor({ activeItems }: { activeItems: IInspectData['items'] }) {
	const stockpile = usePlayerField('stockpile');
	const vault = usePlayerField('vault');
	const isMobile = useIsMobile();
	const [showVault, setShowVault] = useState(stockpile.length === 0);
	const [filter, setFilter] = useState('');

	const { isOpen, onOpen, onClose } = useDisclosure();

	function addItem(itemId: number) {
		socket.emit('profile:item:add', itemId);
		onClose();
	}

	function itemPicker() {
		const items = (showVault ? vault : stockpile).filter(
			(item) => filterItem(item, filter) && !activeItems.some((activeItem) => doItemsMatch(item, activeItem))
		);
		// Replace with modal
		return (
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalContent>
					<Grid gridTemplateColumns='9fr 1fr'>
						<IdlescapeInput value={filter} setValue={setFilter} placeholder='Filter' />{' '}
						<IdlescapeButton
							margin='auto'
							width='100%'
							height='100%'
							padding='0 1px'
							size='large'
							fontSize='1.8rem'
							onClick={() => setShowVault(!showVault)}
						>
							{showVault ? <AiFillLock /> : <AiFillUnlock />}
						</IdlescapeButton>
					</Grid>
					<Grid templateColumns={`repeat(auto-fill, ${isMobile ? '50px' : '64px'})`} gap='3px'>
						{items.map((item) => (
							<FakeItem key={item.id} item={item} onClick={() => addItem(item.id)} />
						))}
					</Grid>
				</ModalContent>
			</Modal>
		);
	}

	return (
		<>
			<Flex
				height={isMobile ? '50px' : '60px'}
				width={isMobile ? '50px' : '60px'}
				border='3px solid white'
				borderRadius='15px'
				borderStyle='dashed'
				justifyContent='center'
				alignItems='center'
				cursor='pointer'
				_hover={{ backgroundColor: 'rgba(255,255,255,0.3)' }}
				onClick={onOpen}
			>
				<FaPlus />
			</Flex>
			{itemPicker()}
		</>
	);
}
