import { TAffixesPaths } from '../../../../../../game-server/src/modules/affixes/affixes.interface';

export class TooltipLiterals {
	static affixesLiterals: Record<TAffixesPaths, string> = {
		'offense.strength': 'Strength',
		'offense.dexterity': 'Dexterity',
		'offense.intellect': 'Intellect',
		'offense.attack_speed': 'Attack Speed',
		'offense.critical_chance': 'Critical Chance',
		'offense.critical_damage': 'Critical Damage',
		'offense.additional_damage': 'Additional Damage',
		'offense.damage_multiplier': 'Damage Multiplier',
		'offense.elite_damage_multiplier': 'Elite Monsters Damage Multiplier',
		'offense.melee_affinities': 'Offensive Melee Affinities',
		'offense.magic_affinities': 'Offensive Magic Affinities',
		'offense.ranged_affinities': 'Offensive Ranged Affinities',
		'offense.chance_on_hit_stun': 'Chance On Hit: Stun',
		'offense.chance_on_hit_silence': 'Chance On Hit: Silence',
		'offense.chance_on_hit_blind': 'Chance On Hit: Blind',
		'offense.chance_on_hit_vulnerability': 'Chance On Hit: Vulnerability',
		'offense.chance_on_hit_weakness': 'Chance On Hit: Weakness',
		'defense.protection_and_resistance': 'Protection/Resistance',
		'defense.agility': 'Agility',
		'defense.melee_affinities': 'Defensive Melee Affinities',
		'defense.magic_affinities': 'Defensive Magic Affinities',
		'defense.ranged_affinities': 'Defensive Ranged Affinities',
		'defense.stamina': 'Stamina',
		'defense.health': 'Additional Health',
		'defense.health_multiplier': 'Health %',
		'defense.health_regen': 'Health Regen %',
		'defense.health_per_resource_spent': 'Health Per Resource Spent',
		'defense.health_per_kill': 'Health Per Kill',
		'defense.life_steal': 'Life Steal %',
		'defense.elite_damage_reduction': 'Elite Monster Damage Reduction',
		'defense.thorns': 'Thorns',
		'wealth.junk_multiplier': 'Junk Multiplier',
		'experience.skill_experience_multiplier': 'Experience Multiplier',
		'experience.item_experience_multiplier': 'Soulbound Experience Multiplier',
		'offense.strength_rare': 'Strength',
		'offense.dexterity_rare': 'Dexterity',
		'offense.intellect_rare': 'Intellect',
		'offense.attack_speed_rare': 'Attack Speed',
		'offense.critical_chance_epic': 'Critical Chance',
		'offense.critical_damage_epic': 'Critical Damage Multiplier',
		'offense.additional_damage_epic': 'Additional Damage',
		'offense.elite_damage_multiplier_epic': 'Elite Monsters Damage Multiplier',
		'offense.melee_affinities_uncommon': 'Offensive Melee Affinities',
		'offense.magic_affinities_uncommon': 'Offensive Magic Affinities',
		'offense.ranged_affinities_uncommon': 'Offensive Ranged Affinities',
		'defense.protection_and_resistance_rare': 'Protection and Resistance',
		'defense.agility_rare': 'Agility',
		'defense.melee_affinities_rare': 'Defensive Melee Affinities',
		'defense.magic_affinities_rare': 'Defensive Magic Affinities',
		'defense.ranged_affinities_rare': 'Defensive Ranged Affinities',
		'defense.stamina_rare': 'Enduring Stamina',
		'defense.health_rare': 'Vital Health',
		'defense.health_multiplier_legendary': 'Health Multiplier',
		'defense.elite_damage_reduction_epic': 'Elite Damage Reduction',
		'offense.melee_affinity': 'Offensive Melee Affinity',
		'offense.magic_affinity': 'Offensive Magic Affinity',
		'offense.ranged_affinity': 'Offensive Ranged Affinity',
		'offense.slashing_affinity': 'Offensive Slashing Affinity',
		'offense.piercing_affinity': 'Offensive Piercing Affinity',
		'offense.blunt_affinity': 'Offensive Blunt Affinity',
		'offense.fire_affinity': 'Offensive Fire Affinity',
		'offense.ice_affinity': 'Offensive Ice Affinity',
		'offense.lightning_affinity': 'Offensive Lightning Affinity',
		'offense.chaos_affinity': 'Offensive Chaos Affinity',
		'offense.nature_affinity': 'Offensive Nature Affinity',
		'defense.melee_affinity': 'Defensive Melee Affinity',
		'defense.magic_affinity': 'Defensive Magic Affinity',
		'defense.ranged_affinity': 'Defensive Ranged Affinity',
		'defense.slashing_affinity': 'Defensive Slashing Affinity',
		'defense.piercing_affinity': 'Defensive Piercing Affinity',
		'defense.blunt_affinity': 'Defensive Blunt Affinity',
		'defense.fire_affinity': 'Defensive Fire Affinity',
		'defense.ice_affinity': 'Defensive Ice Affinity',
		'defense.lightning_affinity': 'Defensive Lightning Affinity',
		'defense.chaos_affinity': 'Defensive Chaos Affinity',
		'defense.nature_affinity': 'Defensive Nature Affinity',
		'mining.effective_level': 'Mining Level',
		'mining.minimum_yield': 'Mining Chance +1 Min Yield',
		'mining.maximum_yield': 'Mining Chance +1 Max Yield',
		'mining.yield_bonus': 'Mining Chance +1 Yield',
		'mining.original_superheat': 'Original Superheat',
		'mining.inferno_effect': 'Inferno Effect in Mining',
		'mining.trash_heap': 'Increased Non-Metal Materials Chance',
		'foraging.effective_level': 'Foraging Level',
		'foraging.minimum_yield': 'Foraging Chance +1 Min Yield',
		'foraging.maximum_yield': 'Foraging Chance +1 Max Yield',
		'foraging.yield_bonus': 'Foraging Chance +1 Yield',
		'foraging.instant_node_clear': 'Foraging Near-Instant Node Clear',
		'foraging.node_bonus_size': 'Foraging Node Size',
		'foraging.original_nature': 'Find Random Logs',
		'fishing.effective_level': 'Fishing Level',
		'fishing.fishing_stats': 'Fishing Stats',
		'fishing.fishing_stats_reel': 'Fishing Reel',
		'fishing.fishing_stats_bait': 'Fishing Bait',
		'fishing.fishing_stats_bonus': 'Fishing Bonus',
		'fishing.success_chance': 'Fishing Success Chance',
		'fishing.minimum_yield': 'Fishing Chance +1 Min Yield',
		'fishing.maximum_yield': 'Fishing Chance +1 Max Yield',
		'fishing.yield_bonus': 'Fishing Chance +1 Yield',
		'fishing.instant_node_clear': 'Fishing Near-Instant Node Clear',
		'fishing.instant_node_search': 'Fishing Instant Node Search',
		'fishing.node_bonus_size': 'Fishing Node Size',
		'farming.effective_level': 'Farming Level',
		'enchanting.effective_level': 'Enchanting Level',
		'enchanting.augmenting_success_chance': 'Augmenting Success Chance',
		'enchanting.augmenting_transform_chance_bonus': 'Augmenting Transform Chance',
		'enchanting.chance_to_preserve': 'Scrollcrafting Mat Preserve',
		'enchanting.critical_augmenting': 'Chance for 2 Augmentation Levels',
		'enchanting.chance_to_save_item': 'Chance to Save Augmented Item',
		'enchanting.chance_to_save_item_but_downgrade':
			'Chance to Save Augmented Item but Downgrade Augmentation Level',
		'enchanting.soul_wisdom': 'Soulbound Experience Multiplier',
		'runecrafting.effective_level': 'Runecrafting Level',
		'runecrafting.chance_to_double': 'Runecrafting: Chance to Double',
		'runecrafting.chance_to_preserve': 'Runecrafting: Chance to Preserve',
		'runecrafting.bonus_gathering_essence': 'Runecrafting Bonus Gathering Essence',
		'runecrafting.bonus_combat_essence': 'Bonus Combat Essence',
		'runecrafting.bonus_production_essence': 'Bonus Production Essence',
		'smithing.effective_level': 'Smithing Effective Level',
		'smithing.chance_to_double': 'Smithing: Chance to Double',
		'smithing.chance_to_preserve': 'Smithing: Chance to Preserve',
		'smithing.heat_cost_reduction': 'Smithing Heat Cost Reduction',
		'smithing.material_cost_reduction': 'Smithing Material Cost Reduction',
		'cooking.effective_level': 'Cooking Level',
		'cooking.chance_to_double': 'Cooking: Chance to Double',
		'cooking.chance_to_preserve': 'Cooking: Chance to Preserve',
		'cooking.food_quality_bonus': 'Food Quality Bonus',
		'cooking.food_quality_bonus_chance': 'Food Quality Bonus Chance',
		'cooking.alchemy_quality_bonus': 'Alchemy Quality Bonus',
		'cooking.alchemy_quality_bonus_chance': 'Alchemy Quality Bonus Chance',
		'cooking.heat_cost_reduction': 'Cooking Heat Cost Reduction',
		'cooking.miracle_chance': 'Chance to Unburn Cooking',
		'gathering.bonus_shard_chance': 'Gathering Bonus Shard Chance',
		'gathering.yield_bonus': 'Gathering Yield Bonus',
		'gathering.effective_level_mult': 'Gathering Bonus Level Multiplier',
		'gathering.greater_chest_chance': 'Greater Chest Chance',
		'gathering.arch_bonus_chance': 'Arch Bonus Chance',
		'gathering.chance_to_double': 'Gathering Chance to Double',
		'gathering.haste': 'Gathering Haste',
		'gathering.dangerous_zone_chance': 'Dangerous Zone Increased Chance',
		'gathering.dangerous_zone_chance_inverted': 'Dangerous Zone Decreased Chance',
		'production.bonus_shard_chance': 'Production Bonus Shard Chance',
		'production.effective_level_mult': 'Production Bonus Level Multiplier',
		'production.chance_to_double': 'Production Chance to Double',
		'production.chance_to_preserve': 'Production Chance to Preserve',
		'production.haste': 'Production Haste',
		'farming.water_totem': 'Water Totem Enhanced',
		'farming.earth_totem': 'Earth Totem Enhanced',
		'farming.nature_totem': 'Nature Totem Enhanced',
		'farming.coalescing_totem': 'Coalescing Totem Enhanced',
	};
}
