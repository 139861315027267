import CombatAmmoInventory from './CombatAmmoInventory';
import CombatFoodInventory from './CombatFoodInventory';

export default function CombatGearInventory() {
	return (
		<div className='combat-inventory-container'>
			{CombatFoodInventory()}
			{CombatAmmoInventory()}
		</div>
	);
}
