import {
	Heading,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React from 'react';
import { ITab, tabs } from '../../utils/NavigationTabsList';

function GuideModal({ isOpen, onClose, tab }: { isOpen: boolean; onClose: () => void; tab: ITab['id'] }) {
	const tabData = tabs[tab];

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
			<ModalOverlay></ModalOverlay>
			<ModalContent
				backgroundColor={'unset'}
				style={{
					borderImageSource: 'url(/images/ui/stone-9slice-dark-gray.png)',
					borderImageSlice: '100 fill',
					borderImageWidth: '100px',
					borderImageOutset: '20px',
				}}
			>
				<ModalHeader as={Heading} size={'lg'} my={3}>
					{tabData.overrideTabName ?? tabData.name} Guide
					<Image
						src={tabData.overrideTabIcon ?? tabData.icon}
						width='40px'
						height='40px'
						marginLeft={2}
						marginBottom={1}
						filter={'drop-shadow(0 0 5px rgba(255,255,255,0.5))'}
					/>
				</ModalHeader>
				<ModalCloseButton></ModalCloseButton>
				<ModalBody>{tabData.guide}</ModalBody>
				<ModalFooter>
					<IdlescapeButton onClick={onClose} variant={'red'}>
						Close
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default GuideModal;
