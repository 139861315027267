import React, { useState } from 'react';
import chisel from '../../../../assets/img/enchantingIcons/chisel.png';
import cape from '../../../../assets/img/enchantingIcons/cloak.png';
import weapon from '../../../../assets/img/enchantingIcons/infernal_lance_icon.png';
import helm from '../../../../assets/img/enchantingIcons/kings_crown.png';
import ladle from '../../../../assets/img/enchantingIcons/ladle.png';
import ring from '../../../../assets/img/enchantingIcons/prismatic_necklace.png';
import necklace from '../../../../assets/img/enchantingIcons/prismatic_ring.png';
import boots from '../../../../assets/img/enchantingIcons/stygian_boots_icon.png';
import gloves from '../../../../assets/img/enchantingIcons/stygian_gloves.png';
import hatchet from '../../../../assets/img/enchantingIcons/stygian_hatchet.png';
import hoe from '../../../../assets/img/enchantingIcons/stygian_hoe.png';
import pickaxe from '../../../../assets/img/enchantingIcons/stygian_pickaxe.png';
import body from '../../../../assets/img/enchantingIcons/stygian_platebody.png';
import leg from '../../../../assets/img/enchantingIcons/stygian_platelegs.png';
import shield from '../../../../assets/img/enchantingIcons/stygian_shield_icon.png';
import tongs from '../../../../assets/img/enchantingIcons/Stygian_Tongs.png';
import tacklebox from '../../../../assets/img/enchantingIcons/Supreme_Tacklebox.png';
import { itemList } from '../../../../utils/itemList';
import './Scrollcrafting.css';
import ScrollcraftingRecipe from './ScrollcraftingRecipe';

export default function Scrollcrafting() {
	const filterButtons = {
		hatchet: hatchet,
		pickaxe: pickaxe,
		hoe: hoe,
		helm: helm,
		body: body,
		legs: leg,
		gloves: gloves,
		boots: boots,
		weapon: weapon,
		shield: shield,
		ring: ring,
		necklace: necklace,
		tacklebox: tacklebox,
		ladle: ladle,
		tongs: tongs,
		cape: cape,
		chisel: chisel,
	};
	const [filter, setFilter] = useState<keyof typeof filterButtons | null>('gloves');
	const enchantments = Object.values(itemList)
		.filter((item) => item.enchant && !item.hidden && (!filter || item.categories?.includes(filter)))
		.sort((a, b) => (a.level ?? 0) - (b.level ?? 0));

	function handleFilterButtons(itemType: keyof typeof filterButtons) {
		if (itemType === filter) {
			setFilter(null);
		} else {
			setFilter(itemType);
		}
	}

	return (
		<div className='scrollcrafting-main'>
			<div className='scrollcrafting-info'>
				<div>
					{Object.entries(filterButtons).map(([button, imageUrl]) => {
						return (
							<span
								key={button}
								onClick={() => handleFilterButtons(button as keyof typeof filterButtons)}
							>
								<img
									className={`scrollcrafting-filter-image ${button === filter && 'clicked'}`}
									src={imageUrl}
									alt={button}
								/>
							</span>
						);
					})}
				</div>
			</div>

			<div className='scrollcrafting-list'>
				{enchantments.map((enchantment) => (
					<ScrollcraftingRecipe key={'scrollcrafting' + enchantment.id} scroll={itemList[enchantment.id]} />
				))}
			</div>
			<span></span>
		</div>
	);
}
