import { Box, Image, Text } from '@chakra-ui/react';
import { IItem, IItemData, TPartialItemWithItemID } from '../../../game-server/src/modules/items/items.interface';
import { itemList } from './itemList';
import { formatNumberToString, getAugmentNameColor, getItemColorFromNumber } from './helperFunctions';
import { EnchantmentData } from '../components/game/EnchantmentData';
import { AFFIX_DUST_PER_RARITY } from './constantsCollection';
import { getEnchantmentStrength } from './itemFunctions';
import { ReactElement } from 'react';

export function itemImage(itemData: IItemData) {
	return <Image src={itemData.itemIcon || itemData.itemImage} alt={itemData.name} className={'item-icon'}></Image>;
}

export function augmentationGlow(item: IItem | TPartialItemWithItemID<IItem> | undefined) {
	if (item && item.augmentations && item.augmentations > 0) {
		return (
			<Image
				className='augmentation-glow-background'
				position='absolute'
				top='0'
				left='0'
				src='/images/augmentation_glow.png'
			/>
		);
	}
}

export function itemAugment(item: IItem | TPartialItemWithItemID<IItem> | undefined) {
	if (item && item.augmentations && item.augmentations > 0) {
		return (
			<div className='item-augment' style={getAugmentNameColor(item.augmentations)}>
				+{item.augmentations}
			</div>
		);
	}
}

export function itemEnchant(item: IItem | TPartialItemWithItemID<IItem> | undefined, any?: boolean) {
	if (any) {
		return (
			<Box fontSize='12px' position='absolute' top='0' left='0' height='30%' fontWeight='bold' color='#eaf'>
				Any
			</Box>
		);
	}
	if (item && item.enchantmentID && item.enchantmentID > 0) {
		const enchant = EnchantmentData.findEnchantmentByID(item.enchantmentID);
		return (
			<Image
				src={enchant.buffIcon}
				objectFit='contain'
				position='absolute'
				top='0'
				left='0'
				height='30%'
				width='30%'
				className='anchor-item-enchant'
			/>
		);
	}
}

export function itemAffix(item: IItem | TPartialItemWithItemID<IItem> | undefined) {
	if (item && item.affixes && item.affixes.length > 0) {
		const itemData = itemList[item.itemID];
		const affixRarityItem = AFFIX_DUST_PER_RARITY[itemData.rarity ?? 'common'];
		const rarityItemImage = itemList[affixRarityItem].itemImage;
		return (
			<Image
				src={rarityItemImage}
				objectFit='contain'
				position='absolute'
				bottom='0'
				right='0'
				height='30%'
				width='30%'
				style={{ opacity: 0.85 }}
			/>
		);
	}
}

export function itemAmounts(itemAmount: number, min?: number) {
	if (itemAmount >= (min ?? 0)) {
		const itemClass = ['centered'];
		itemClass.push(getItemColorFromNumber(itemAmount));
		const itemAmountString = formatNumberToString(itemAmount);
		return <div className={itemClass.join(' ')}>{itemAmountString}</div>;
	}
}

export function itemHigherAugment(higherAugment: boolean | undefined) {
	if (higherAugment) {
		return (
			<Box fontSize='25px' position='absolute' left='50%' top='50%' transform='translate(-50%, -50%)'>
				≥
			</Box>
		);
	}
}

export function textAugment(item: TPartialItemWithItemID<IItem>) {
	let augLevel = 0;
	let augmentations: ReactElement | null = null;
	if (item.augmentations !== undefined) {
		augLevel = item.augmentations;
		augmentations = <b className='augmented-text'>+{item.augmentations}</b>;
	}
	return (
		<Box style={getAugmentNameColor(augLevel)}>
			<Text as='span' textOverflow='ellipsis' whiteSpace='nowrap'>
				{item.name}
			</Text>
			<span>{augmentations}</span>
		</Box>
	);
}

export function textEnchant(item: TPartialItemWithItemID<IItem>, any?: boolean) {
	if (any) {
		return (
			<Text fontWeight='bold' color='#eaf' padding='0' margin='0'>
				Any
			</Text>
		);
	}
	if (item.enchantmentID) {
		const enchantmentStrength = getEnchantmentStrength(item, itemList);
		return (
			<Text fontWeight='bold' color='#eaf' padding='0' margin='0'>
				{EnchantmentData.findEnchantmentByID(item.enchantmentID).name} {enchantmentStrength}
			</Text>
		);
	}
}

export function coinIcon(size: number) {
	return (
		<Image
			src='/images/gold_coin.png'
			alt='Gold coins'
			height={size + 'px'}
			width={size + 'px'}
			verticalAlign={'text-bottom'}
		/>
	);
}

export function questPointIcon(size: number) {
	return (
		<Image
			src='/images/tutorial/quest_point.png'
			alt='Quest Points'
			height={size + 'px'}
			width={size + 'px'}
			verticalAlign={'text-bottom'}
		/>
	);
}
