import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { usePlayerField } from '../../../hooks/hooks';
import { StatusBar } from '../StatusBar/StatusBar';
import './SmallWidth.css';
import { useSetRecoilState } from 'recoil';
import StatusAction from '../StatusBar/StatusAction';

export default function SmallWidth() {
	const skills = usePlayerField('skills');
	const totalLevel = skills.total.level;
	const masteryLevel = skills.total.masteryLevel;
	const setActiveTab = useSetRecoilState(activeTabAtom);
	return (
		<div className='levelbar-small'>
			<div className='small-status-action'>
				<StatusAction />
			</div>
			<div className='small-status'>
				<div className='small-total-level' data-tip data-for='total-level-tooltipHeader'>
					<img src='/images/total_level.png' alt='Total level icon' className='icon25' />
					<span>{totalLevel}</span>
				</div>
				<div className='small-skill-levels-total'>
					<StatusBar />
				</div>
				<div
					className='small-combat-level'
					id='totalCombatLevelHeader'
					data-tip
					data-for='combat-level-tooltipHeader'
					onClick={() => setActiveTab('talents')}
				>
					<img src='/images/total_level_mastery_icon.png' alt='Total Mastery Level' className='icon25' />
					<span>{masteryLevel}</span>
				</div>
			</div>
		</div>
	);
}
