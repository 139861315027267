import React from 'react';
import { getAugmentNameColor, getDateOfNextMonth, getTimeString } from '../../../utils/helperFunctions';
import { stringCapitalize, toFixedLocale } from '../../../utils/StringFormatter';
import { IItem, IItemData, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { BaseTooltip } from './Types/BaseTooltip';
import { TSkills } from '../../../../../game-server/src/Atypes/Skills';
import {
	formatEquipmentEnchant,
	formatEquipmentStats,
	formatFoodEnchant,
	formatHealing,
	formatItemAbilities,
	formatItemSets,
	formatJunkSection,
	formatRequirements,
	formatSeedYield,
	formatStats,
	wrapTooltipItem,
	formatAffixes,
	halfSpace,
	formatAugmentCounter,
} from './UI/LegacyTooltipPresenter';
import { Text } from '@chakra-ui/react';
import { TEquipments } from '../../../../../game-server/src/modules/player/PlayerTypes';
import { getItemTier } from '../../../utils/augmentingFunctions';
import { CraftingAugmentingData } from '../CraftingAugmenting/CraftingAugmentingData';

export class LegacyTooltip extends React.Component<{
	item: TPartialItemWithItemID<IItem>;
	itemResource: IItemData;
	equipment: TEquipments;
	tooltipManager: BaseTooltip;
	skills: TSkills;
	compactVersion: boolean;
	listingDate?: Date;
}> {
	render() {
		const tooltipData = this.props.tooltipManager.generateTooltipData(
			this.props.item,
			this.props.itemResource,
			this.props.equipment
		);
		const itemResource = this.props.itemResource;
		const stats = formatStats(tooltipData.stats);
		const equipmentStats = formatEquipmentStats(tooltipData.equipmentStats);
		const itemSets = formatItemSets(tooltipData.itemSet, this.props.compactVersion);
		const seedYield = formatSeedYield(tooltipData.yield);
		const healing = formatHealing(tooltipData.healing);
		const requirements = formatRequirements(tooltipData.requiredStats, this.props.skills);
		const enchant = formatEquipmentEnchant(tooltipData.enchant, itemResource.enchantmentTier);
		const foodEnchant = formatFoodEnchant(tooltipData.foodEnchant);
		const itemAbilities = formatItemAbilities(tooltipData.itemAbilities);
		const junkSection = formatJunkSection(itemResource.tags);
		const augmentCounter = formatAugmentCounter(tooltipData.augmentCounter, this.props.item);
		const affixes = formatAffixes(tooltipData.affixes, this.props.item.itemID);

		const remainingTime = getDateOfNextMonth(new Date(this.props.listingDate ?? 0)) - Date.now();
		const remainingString = getTimeString(remainingTime / 1000, 2);

		return (
			<div className='legacy-tooltip'>
				{wrapTooltipItem(
					<span style={getAugmentNameColor(tooltipData.augment)}>
						<span className={`${tooltipData.premiumName ? 'tooltip-premium-name' : ''}`}>
							{tooltipData.customName.length > 0 ? tooltipData.customName : tooltipData.name}
						</span>

						{tooltipData.augment > 0 ? <b className='augmented-text'>+{tooltipData.augment}</b> : ''}
						{tooltipData.rarity && (
							<Text as='span' className={`item-rarity rarity-${tooltipData.rarity}`} float='right'>
								{stringCapitalize(tooltipData.rarity)}
							</Text>
						)}
					</span>
				)}
				{tooltipData.customName.length > 0 && (
					<div>
						<span className='tooltip-original-name'>{tooltipData.name}</span>
					</div>
				)}
				{wrapTooltipItem(<hr />)}
				{junkSection}
				<span>
					<span className='item-stat-block'>
						<div className='item-tooltip-image-container'>
							<img className='item-tooltip-image' src={tooltipData.image} alt={tooltipData.name} />
						</div>
						{wrapTooltipItem(
							<>{tooltipData.weaponSubtype ? tooltipData.weaponSubtype : tooltipData.type}</>,
							!tooltipData.isEquipment
						)}
						{wrapTooltipItem(
							tooltipData.type,
							!(tooltipData.type.includes('Ingredient') || tooltipData.type.includes('Consumable'))
						)}
						{wrapTooltipItem(
							<>Tier: {getItemTier(itemResource)}</>,
							!CraftingAugmentingData.getAugmentingByID(itemResource.id) &&
								!CraftingAugmentingData.getScrappingByID(itemResource.id)
						)}
						{wrapTooltipItem(
							<>Attack Speed: {tooltipData.weaponStats.speed}s</>,
							tooltipData.weaponStats?.speed === undefined
						)}
						{wrapTooltipItem(
							<>Attack Style: {stringCapitalize(tooltipData.weaponStats.style)}</>,
							tooltipData.weaponStats?.style === undefined
						)}
						{wrapTooltipItem(
							<>Min Hit: {tooltipData.weaponStats.minHitMults}</>,
							tooltipData.weaponStats?.minHitMults === undefined
						)}
						{wrapTooltipItem(
							<>Max Hit: {tooltipData.weaponStats.maxHitMults}</>,
							tooltipData.weaponStats?.maxHitMults === undefined
						)}
						{wrapTooltipItem(
							<>Slot: {tooltipData.enchantmentScrollCategories.join(', ')}</>,
							tooltipData.enchantmentScrollCategories.length === 0
						)}
						{stats}
						{wrapTooltipItem('Can be opened.', !tooltipData.canBeOpened)}
						{wrapTooltipItem('Cannot be traded.', tooltipData.premiumName || tooltipData.tradeable)}
						{healing}
						{requirements}
						<div className='flavor-text' hidden={this.props.compactVersion}>
							<br />
							{tooltipData.flavor}
						</div>
					</span>
					{halfSpace}
				</span>
				<span hidden={this.props.item.christmasSpirit === undefined}>
					{wrapTooltipItem(<hr />)}
					{wrapTooltipItem(
						<span>Christmas Spirit: {toFixedLocale(this.props.item.christmasSpirit ?? 0)}</span>
					)}
				</span>
				{equipmentStats}
				{affixes}
				{seedYield}
				{foodEnchant}
				{enchant}
				{itemAbilities}
				{itemSets}
				{augmentCounter}
				{wrapTooltipItem(<hr />)}
				<div>
					<span>
						<span className='tooltip-text-image left' hidden={tooltipData.quantity === 0}>
							{'Quantity: ' + tooltipData.quantity.toLocaleString('en-us')}
						</span>
						<span className='tooltip-text-image right' hidden={tooltipData.heat === 0}>
							{tooltipData.heat.toLocaleString('en-us')}{' '}
							<img src='/images/heat_small_icon.png' alt='heat' className='icon16' />
						</span>
						<span className='tooltip-text-image right' hidden={tooltipData.vendor === 0}>
							{' ' + tooltipData.vendor.toLocaleString('en-us')}{' '}
							<img src='/images/gold_coin.png' alt='coins' className='icon16' />
						</span>
						<br />
						{remainingTime > 0 ? <span>Expires in: {remainingString}</span> : ''}
					</span>
				</div>
			</div>
		);
	}
}
