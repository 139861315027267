import {
	TEnchant,
	TFoodEnchant,
	THealingStats,
	TooltipData,
	TTooltipEquipmentStats,
	TTooltipStats,
	TWeaponStats,
} from '../Data/TooltipData';
import {
	IItem,
	IItemData,
	TPartialItemWithItemID,
} from '../../../../../../game-server/src/modules/items/items.interface';
import { TSkillName } from '../../../../../../game-server/src/Atypes/Skills';
import { TEquipments } from '../../../../../../game-server/src/modules/player/PlayerTypes';
import { stringCapitalize } from '../../../../utils/StringFormatter';
import { IAbilityData } from '../../../../../../game-server/src/modules/ability/ability.interface';
import { IEnchantmentData } from '../../../../../../game-server/src/modules/enchantment/enchantment.interface';

export abstract class BaseTooltip {
	private readonly tooltipData: TooltipData;
	constructor() {
		this.tooltipData = new TooltipData();
	}

	public generateTooltipData(
		item: TPartialItemWithItemID<IItem>,
		itemResource: IItemData,
		equippedItems: TEquipments
	): TooltipData {
		this.initializeGenericData(item, itemResource);
		this.tooltipData.type = this.getItemType(itemResource);
		this.tooltipData.weaponSubtype = this.getSecondaryType(itemResource);
		this.tooltipData.weaponStats = this.getWeaponInfo(itemResource);
		this.tooltipData.requiredStats = this.getRequiredStatsLevel(item, itemResource);
		this.tooltipData.equipmentStats = this.getEquipmentStats(item, itemResource);
		this.tooltipData.stats = this.getStats(item, itemResource);
		this.tooltipData.itemSet = this.getItemSets(itemResource, equippedItems);
		this.tooltipData.enchant = this.getEnchant(item, itemResource);
		this.tooltipData.itemAbilities = this.getAbilities(item, itemResource);
		this.tooltipData.effects = this.getEffects(item, itemResource);
		this.tooltipData.use = this.getUse(item, itemResource);
		this.tooltipData.yield = this.getYield(itemResource);
		this.tooltipData.healing = this.getHealing(item, itemResource);
		this.tooltipData.foodEnchant = this.getFoodEnchant(item, itemResource);
		this.tooltipData.augmentCounter = this.getAugmentCounter(item);
		return this.tooltipData;
	}

	private initializeGenericData(item: TPartialItemWithItemID<IItem>, itemResource: IItemData) {
		if (!itemResource) {
			this.tooltipData.name = 'Unknown Item';
			return;
		}
		this.tooltipData.name = itemResource.name;
		this.tooltipData.customName = item?.name && item.name !== itemResource.name ? item.name : '';
		this.tooltipData.premiumName = item.forceUntradeable ?? false;
		this.tooltipData.image = (itemResource.itemIcon || itemResource.itemImage) ?? '';
		this.tooltipData.rarity = itemResource?.rarity ?? 'common';
		this.tooltipData.augment = item?.augmentations ?? 0;
		this.tooltipData.quantity = item.christmasSpirit ? item.christmasSpirit ?? 0 : item?.stackSize ?? 0;
		this.tooltipData.heat = itemResource?.heat ?? 0;
		this.tooltipData.vendor = itemResource?.value ?? 0;
		this.tooltipData.market = 0;
		this.tooltipData.flavor = itemResource?.extraTooltipInfo ?? '';
		this.tooltipData.tradeable = itemResource?.tradeable ?? false;
		this.tooltipData.canBeOpened = itemResource?.canBeOpened ?? false;
		this.tooltipData.enchantmentScrollCategories =
			itemResource?.categories?.map((category) => stringCapitalize(category)) ?? [];
		this.tooltipData.isEquipment = itemResource.equipmentStats?.slot !== undefined;
		this.tooltipData.affixes = item.affixes ?? [];
		this.tooltipData.augmentCounter = item.augmentCounter ?? 0;
	}

	/* eslint-disable @typescript-eslint/no-unused-vars */
	protected abstract getItemType(itemResource: IItemData): string;

	protected getSecondaryType(itemResource: IItemData): string {
		return '';
	}
	protected getWeaponInfo(itemResource: IItemData): Partial<Record<TWeaponStats, string>> {
		return {};
	}
	protected getUse(item: TPartialItemWithItemID<IItem>, itemResource: IItemData): string {
		return '';
	}
	protected getYield(
		itemResource: IItemData
	): { name: string; productionMin: number; productionMax: number; chance: number }[] {
		return [];
	}
	protected getAbilities(
		item: TPartialItemWithItemID<IItem>,
		itemResource: IItemData
	): { name: IAbilityData['abilityName']; id: number }[] {
		return [];
	}
	protected getEffects(item: TPartialItemWithItemID<IItem>, itemResource: IItemData): Record<string, string> {
		return {};
	}
	protected getStats(
		item: TPartialItemWithItemID<IItem>,
		itemResource: IItemData
	): Partial<Record<TTooltipStats, Record<string, string | number>>> | undefined {
		return undefined;
	}
	protected getEquipmentStats(
		item: TPartialItemWithItemID<IItem>,
		itemResource: IItemData
	): Partial<Record<TTooltipEquipmentStats, Record<string, number>>> | undefined {
		return undefined;
	}
	protected getRequiredStatsLevel(
		item: TPartialItemWithItemID<IItem>,
		itemResource: IItemData
	): Partial<Record<TSkillName, number>> {
		return {};
	}
	protected getEnchant(
		item: TPartialItemWithItemID<IItem>,
		itemResource: IItemData
	): Record<TEnchant, string | number> | undefined {
		return undefined;
	}
	protected getHealing(
		item: TPartialItemWithItemID<IItem>,
		itemResource: IItemData
	): Record<THealingStats, number> | undefined {
		return undefined;
	}
	protected getItemSets(
		itemResource: IItemData,
		equippedItems: TEquipments
	): Record<IEnchantmentData['name'], string | number>[] {
		return [];
	}
	protected getFoodEnchant(
		item: TPartialItemWithItemID<IItem>,
		itemResource: IItemData
	): Record<TFoodEnchant, string | number | boolean> | undefined {
		return undefined;
	}
	protected getAugmentCounter(item: TPartialItemWithItemID<IItem>): number {
		return 0;
	}
	/* eslint-enable */
}
