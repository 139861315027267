import React, { Component } from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import './ChatAchievement.css';

export default class ChatAchievement extends Component {
	constructor(props) {
		super(props);

		this.state = { tooltipEnabled: false };
		this.disableTooltip = this.disableTooltip.bind(this);
		this.enableTooltip = this.enableTooltip.bind(this);

		this.divStyle = { zindex: '10000', position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' };
	}

	disableTooltip() {
		if (this.state.tooltipEnabled) this.setState({ tooltipEnabled: false });
	}

	enableTooltip() {
		if (!this.state.tooltipEnabled) this.setState({ tooltipEnabled: true });
	}

	render() {
		let tooltipImage = (
			<img className='achievement-tooltip-image' src='/images/total_level.png' alt='total level' />
		);

		return (
			<div className={'chat-item gold-medal'}>
				Achievement: {this.props.achievement.name}
				<Popover
					isOpen={this.state.tooltipEnabled}
					transitionDuration={0}
					position={'top'}
					content={({ position, targetRect, popoverRect }) => (
						<ArrowContainer
							position={position}
							targetRect={targetRect}
							popoverRect={popoverRect}
							width={'100%'}
							arrowColor={'white'}
							arrowSize={8}
							arrowStyle={{ zIndex: 1000, opacity: 1 }}
						>
							<div className='achievement-tooltip'>
								<div className='achievement-tooltip-image-container'>
									<span className='achievement-tooltip-image'>{tooltipImage}</span>
								</div>
								<div className='achievement-tooltip-content'>
									<span className='achievement-tooltip-name'>{this.props.achievement.name}</span>
									<span className='achievement-tooltip-description'>
										{this.props.achievement.description}
									</span>
									<span className='achievement-tooltip-completion-date'>
										{new Date(this.props.achievement.date).toLocaleDateString()}
									</span>
								</div>
							</div>
						</ArrowContainer>
					)}
				>
					<span
						onMouseOver={this.enableTooltip}
						onMouseOut={this.disableTooltip}
						style={this.divStyle}
					></span>
				</Popover>
			</div>
		);
	}
}
