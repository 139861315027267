import { Box } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { locationsIds } from '../../../../utils/lookup-dictionaries/lookupLocationList';
import { IProgressBarRef, ProgressBar } from '../../../layout/UI/ProgressBar';

export default function CookingProgressBar({
	currentAction = 'cooking',
	theme,
}: {
	currentAction?: 'cooking' | 'alchemy' | 'preparation';
	theme?: 'cooking';
}) {
	const settings = usePlayerField('settings');
	const action = usePlayerField('actionQueue');
	const highPerformance = settings.miscellaneous.highPerformance;
	const progressBarRef = useRef<IProgressBarRef>(null);

	const active =
		action?.options?.cooking?.type === currentAction ||
		(currentAction === 'preparation' && action?.location === locationsIds.the_preparation_halls);

	useEffect(() => {
		socket.on('animation:start', onStartAnimation);

		return () => {
			socket.off('animation:start');
		};
	}, [progressBarRef, active]);

	useEffect(() => {
		if (!action || action.actionType !== 'Action-Cooking') {
			progressBarRef.current?.stopAnimation();
		}
	}, [action]);

	function onStartAnimation(data: { action: string; location: number; length: number; resource?: number }) {
		if (active) {
			progressBarRef.current?.startAnimation(data.length * 1000);
		}
	}

	return (
		<>
			{!highPerformance && (
				<Box width='100%' height='100%'>
					<ProgressBar ref={progressBarRef} value={0} max={100} theme={theme ?? 'default'} marginY='8px' />
				</Box>
			)}
		</>
	);
}
