import React from 'react';
import MarketplaceSellItem from './MarketplaceSellItem';
import { itemList } from '../../../utils/itemList';
import { Box } from '@chakra-ui/react';
import { filterItem } from '../../../utils/helperFunctions';
import { usePlayerField } from '../../../hooks/hooks';
import { useRecoilValue } from 'recoil';
import { marketplaceFilterAtom } from '../../../atoms/marketplaceFilterAtom';

export default function MarketplaceInventory() {
	const marketFilter = useRecoilValue(marketplaceFilterAtom);
	const stockpile = usePlayerField('stockpile');

	function renderItems() {
		const filteredList = stockpile.filter((item) => filterItem(item, marketFilter));
		return filteredList.map((item, index) => {
			if (!itemList[item.itemID]) return;
			const itemData = itemList[item.itemID];
			if (itemData.tradeable === true) {
				return <MarketplaceSellItem key={'marketplaceSell' + index} item={item} />;
			}
		});
	}

	return (
		<Box
			className='all-items anchor-sell-all-items'
			paddingTop='5px'
			alignContent='start'
			overflowY='auto'
			overflowX='hidden'
			height='100%'
		>
			{renderItems()}
		</Box>
	);
}
