import { Box, Image } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import { useSetRecoilState } from 'recoil';
import { TEquipmentSlots } from '../../../../../game-server/src/modules/player/PlayerTypes';
import { shareItemSetSelector } from '../../../atoms/chatInputAtom';
import { profileEditorOpenAtom } from '../../../atoms/profileEditorOpenAtom';
import { CombatGearItem } from './CombatGearItem';

export const gearSlots: TEquipmentSlots[] = [
	'helm',
	'cape',
	'necklace',
	'arrows',
	'weapon',
	'body',
	'shield',
	'legs',
	'gloves',
	'boots',
	'ring',
	'pickaxe',
	'hatchet',
	'hoe',
	'tacklebox',
	'tome',
	'tongs',
	'ladle',
	'chisel',
];

interface ICombatCharacterEquipmentProps {
	view: 'equipment' | 'cosmetics';
}

export const CombatCharacterEquipment = (props: ICombatCharacterEquipmentProps) => {
	const shareItemSet = useSetRecoilState(shareItemSetSelector);
	const openProfileEditor = useSetRecoilState(profileEditorOpenAtom);

	return (
		<Box className='combat-gear'>
			<img src='/images/combat/combat_stick_figure.png' alt='Combat stick figure' />
			{gearSlots.map((slot) => {
				return <CombatGearItem key={'character-equipment-slot-' + slot} slot={slot} type={props.view} />;
			})}
			{props.view === 'cosmetics' && (
				<>
					<CombatGearItem key={'character-equipment-slot-' + 'base'} slot='base' type={props.view} />
					<CombatGearItem key={'character-equipment-slot-' + 'face'} slot='face' type={props.view} />
				</>
			)}
			<IdlescapeWrappingTooltip content='View your profile'>
				<Box
					onClick={() => openProfileEditor(true)}
					filter='drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'
					float='left'
					cursor='pointer'
					position='absolute'
					top='6px'
					left='calc(50% - 145px)'
					zIndex='11'
					_hover={{ filter: 'brightness(2)' }}
				>
					<Image src='/images/ui/social_icon.png' width='50px' height='50px' />
				</Box>
			</IdlescapeWrappingTooltip>
			<IdlescapeWrappingTooltip content='Let other players know what you are wearing!'>
				<Box
					onClick={() => shareItemSet('Equipment')}
					filter='drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'
					float='right'
					cursor='pointer'
					position='absolute'
					top='6px'
					right='calc(50% - 145px)'
					zIndex='11'
					_hover={{ filter: 'brightness(2)' }}
				>
					<Image src='/images/chaticons/chat-icon-global.png' width='50px' height='50px' />
				</Box>
			</IdlescapeWrappingTooltip>
		</Box>
	);
};
