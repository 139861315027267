// @@@ THIS FILE IS DYNAMICALLY GENERATED. MODIFICATIONS WILL NEVER BE SAVED.
// MODIFY THE MASTER LIST AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES TO LIST ITEMS HERE

import { TForgeDataListClient } from '../../../game-server/src/modules/skills/smithing/Smithing.interface';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const forges: TForgeDataListClient = {
	1: {
		forgeID: 1,
		forgeName: 'City Forge',
		image: '/images/smithing/city_forge.png',
		backgroundImage: '/images/smithing/city_forge_with_background.png',
		forgeLevelRequired: 1,
		forgeXPMult: 1.5,
		forgeHeatCost: 1,
		forgeMaterialCost: 1,
		forgeSpeedMult: 1,
		forgeBonusBars: 0,
		forgeIntensityHeatCostMult: 1.5,
		forgeIntensityMaterialCostMult: 1.0,
		forgeIntensitySpeedMult: 0.9,
		forgeIntensityBonusBars: 1.0,
		forgeMaxIntensity: 3,
		refiningStrengthMult: 4,
		refiningTable: [
			{ id: itemsIds.infernal_dust, chance: -1 / 666, minAmount: 1, maxAmount: 3 },
			{ id: itemsIds.obsidian_glass, chance: 0.0, minAmount: 1, maxAmount: 3 },
			{ id: itemsIds.black_opal, chance: 0.01 },
			{ id: itemsIds.inert_heat_stone, chance: 0.03, minAmount: 1, maxAmount: 2 },
			{ id: itemsIds.diamond, chance: 0.05 },
			{ id: itemsIds.ruby, chance: 0.1 },
			{ id: itemsIds.emerald, chance: 0.25 },
			{ id: itemsIds.sapphire, chance: 0.35 },
			{ id: itemsIds.sand, chance: 0.55, minAmount: 50, maxAmount: 100 },
			{ id: itemsIds.silver, chance: 0.75, minAmount: 50, maxAmount: 100 },
			{ id: itemsIds.metal_slag, chance: 0.95, minAmount: 100, maxAmount: 750 },
		],
	},
	2: {
		forgeID: 2,
		forgeName: 'Dwarven Forge',
		image: '/images/smithing/dwarven_forge.png',
		backgroundImage: '/images/smithing/dwarven_forge_with_background.png',
		forgeLevelRequired: 40,
		forgeXPMult: 1.5,
		forgeHeatCost: 1,
		forgeMaterialCost: 1.0,
		forgeSpeedMult: 1.5,
		forgeBonusBars: 0.5,
		forgeIntensityHeatCostMult: 1.2,
		forgeIntensityMaterialCostMult: 1.1,
		forgeIntensitySpeedMult: 1.1,
		forgeIntensityBonusBars: 1.25,
		forgeMaxIntensity: 6,
		refiningStrengthMult: 4,
		refiningTable: [
			{ id: itemsIds.molten_ashes, chance: -2 / 666 },
			{ id: itemsIds.black_opal, chance: 0.005 },
			{ id: itemsIds.inert_heat_stone, chance: 0.03, minAmount: 1, maxAmount: 2 },
			{ id: itemsIds.diamond, chance: 0.045 },
			{ id: itemsIds.ruby, chance: 0.12 },
			{ id: itemsIds.emerald, chance: 0.2, minAmount: 1, maxAmount: 2 },
			{ id: itemsIds.sapphire, chance: 0.3, minAmount: 1, maxAmount: 3 },
			{ id: itemsIds.clay, chance: 0.45, minAmount: 100, maxAmount: 200 },
			{ id: itemsIds.metal_slag, chance: 0.8, minAmount: 100, maxAmount: 1500 },
		],
	},
	3: {
		forgeID: 3,
		forgeName: 'Volcanic Forge',
		image: '/images/smithing/volcanic_forge.png',
		backgroundImage: '/images/smithing/volcanic_forge_with_background.png',
		forgeLevelRequired: 80,
		forgeXPMult: 1,
		forgeHeatCost: 3,
		forgeMaterialCost: 1.5,
		forgeSpeedMult: 1,
		forgeBonusBars: 1,
		forgeIntensityHeatCostMult: 1.8,
		forgeIntensityMaterialCostMult: 1.3,
		forgeIntensitySpeedMult: 1.25,
		forgeIntensityBonusBars: 1.5,
		forgeMaxIntensity: 9,
		refiningStrengthMult: 3,
		refiningTable: [
			{ id: itemsIds.core_fragment, chance: -13 / 666, minAmount: 1, maxAmount: 5 },
			{ id: itemsIds.black_opal, chance: 0.01 },
			{ id: itemsIds.inert_heat_stone, chance: 0.03, minAmount: 1, maxAmount: 2 },
			{ id: itemsIds.diamond, chance: 0.05 },
			{ id: itemsIds.rune_slate, chance: 0.1, minAmount: 100, maxAmount: 250 },
			{ id: itemsIds.ruby, chance: 0.15 },
			{ id: itemsIds.emerald, chance: 0.3 },
			{ id: itemsIds.sapphire, chance: 0.45 },
			{ id: itemsIds.metal_slag, chance: 0.5, minAmount: 200, maxAmount: 2500 },
			{ id: itemsIds.stone, chance: 0.75, minAmount: 200, maxAmount: 500 },
		],
	},
};
