import React, { useState } from 'react';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import Tooltip from '../../../layout/UI/Tooltip';
import {
	Box,
	Flex,
	AbsoluteCenter,
	useDisclosure,
	BoxProps,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalHeader,
} from '@chakra-ui/react';
import { AiOutlineStop } from 'react-icons/ai';
import ItemTooltip from '../ItemTooltip';
import useIsMobile from '../../../../hooks/useIsMobile';
import { IdlescapeButton } from '@idlescape/ui';
import FakeItem from '../../Inventory/FakeItem';

interface SelectionProps extends BoxProps {
	items: IItem[];
	imageSize?: string;
	clickItem?: (item: IItem) => void;
	clickEmpty?: () => void;
	onClickBox?: (e: React.MouseEvent<HTMLElement>) => boolean | void;
	showStats?: boolean;
	showAmount?: boolean;
	showEmpty?: boolean;
	currentItem?: TPartialItemWithItemID<IItem>;
	mobileTitle?: string;
}

/**
 * Surround the element if it has a tooltip as it will override the selection tooltip
 * @param items - Array of items to add in selection
 * @param clickItem - Function when an item is clicked
 * @param clickEmpty - Function when an empty slot is clicked
 * @param onClickBox - Adds functionality to onClick, overrides if returns true
 * @param showAmount - Show the amount of items, defaults to true
 * @param showEmpty - Show the empty slot, defaults to true
 */
export default function SelectionTooltipBox({
	items,
	imageSize = '48px',
	clickItem,
	clickEmpty,
	onClickBox,
	showAmount = true,
	showEmpty = true,
	title = '',
	currentItem,
	children,
	...props
}: SelectionProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedItem, setSelectedItem] = useState<IItem>();
	const isMobile = useIsMobile();

	function selectItem(item: IItem) {
		if (isMobile) {
			setSelectedItem(item);
		} else if (clickItem) {
			clickItem(item);
		}
	}

	function renderList() {
		const borderItem = selectedItem ?? currentItem;
		return (
			<>
				{items.map((item) => (
					<FakeItem
						item={item}
						onClick={() => selectItem(item)}
						key={item.id ?? item.itemID}
						width={imageSize}
						height={imageSize}
						amountString={showAmount ? undefined : ' '}
						border={
							borderItem &&
							(!borderItem?.id || borderItem?.id === item.id) &&
							borderItem?.itemID === item.itemID
								? '3px solid white'
								: ''
						}
					/>
				))}
				{showEmpty && (
					<Box
						key='possible-equipment-unselect'
						className='item junk'
						onClick={() => {
							if (isMobile) {
								setSelectedItem(undefined);
							} else if (clickEmpty) {
								clickEmpty();
							}
						}}
						width={imageSize}
						height={imageSize}
						border={isMobile && !borderItem ? '3px solid white' : ''}
					>
						<AbsoluteCenter fontSize='28px' color='gray.400'>
							<AiOutlineStop />
						</AbsoluteCenter>
					</Box>
				)}
			</>
		);
	}

	function handleOnClick(e: React.MouseEvent<HTMLElement>) {
		if (onClickBox && onClickBox(e)) return;
		if (isOpen) {
			onClose();
		} else {
			onOpen();
		}
		e.stopPropagation();
	}

	function renderMobileModal() {
		return (
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent className='sell-item-dialog'>
					{title && <ModalHeader>{title}</ModalHeader>}
					<ModalCloseButton />
					<ModalBody>
						<Flex flexWrap='wrap' gap='10px'>
							{renderList()}
						</Flex>
						<hr />
						<Flex justifyContent='center'>
							<IdlescapeButton variant='red' size='medium' onClick={onClose}>
								Cancel
							</IdlescapeButton>
							<IdlescapeButton
								variant='green'
								size='medium'
								onClick={() => {
									if (selectedItem) {
										clickItem && clickItem(selectedItem);
									} else if (clickEmpty) {
										clickEmpty();
									}
									onClose();
								}}
							>
								Select
							</IdlescapeButton>
						</Flex>
						<Flex justifyContent='center'>
							{selectedItem && <ItemTooltip item={selectedItem} embed={true} />}
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		);
	}

	return (
		<Box
			onClick={handleOnClick}
			cursor='pointer'
			{...props}
			{...(!children // Size the box to the parent if it doesn't have a child
				? {
						position: 'absolute',
						top: '0',
						left: '0',
						height: '100%',
						width: '100%',
				  }
				: undefined)}
		>
			{children}
			{isMobile
				? renderMobileModal()
				: isOpen && (
						<Tooltip isOpen={true} onClickOutside={onClose} className='idlescape-tooltip'>
							<Flex flexWrap='wrap' gap='10px' maxWidth='240px' padding='9px'>
								{renderList()}
							</Flex>
						</Tooltip>
				  )}
		</Box>
	);
}
