// @@@ THIS FILE IS DYNAMICALLY GENERATED. MODIFICATIONS WILL NEVER BE SAVED.
// MODIFY THE MASTER LIST AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES TO LIST ITEMS HERE

import { TItemListClient } from '../../../game-server/src/modules/items/items.interface';
import { ILoot } from '../../../game-server/src/modules/items/LootInterface';
import { ILocationData, ILocationNode } from '../../../game-server/src/modules/locations/location.interface';
import { MINIMUM_ACTION_TIME_IN_MILLISECONDS } from '../utils/constantsCollection';

const baseSuccessChance = 0.4;
export const FISHING_LOCATION_MULTIPLIER = 1.25;

export function getFishingGatherLength(location: ILocationData, hasteMult: number, fishingReel: number) {
	const value = Math.max(
		MINIMUM_ACTION_TIME_IN_MILLISECONDS,
		(location.baseDuration / 1.25 / hasteMult) * (200 / (fishingReel + 200))
	);
	return value;
}

// 1.25x location mult for fishing level
export function getFishingGatherChance(
	fishingStrength: number,
	levelLoc: number,
	reel: number,
	initialResources: number,
	resourcesLeft: number,
	affixSuccessChance: number
) {
	const value =
		affixSuccessChance +
		baseSuccessChance +
		levelLoc / 275 +
		fishingStrength +
		reel / 200 +
		resourcesLeft / initialResources / 3;
	return value;
}

export function getFishingSearchLength(
	location: ILocationData,
	hasteMult: number,
	bait: number,
	searchPenalty: number
) {
	const value = Math.max(
		MINIMUM_ACTION_TIME_IN_MILLISECONDS,
		((location.baseDuration * 1.75) / hasteMult) * (1 - bait / 400) + searchPenalty
	);
	return value;
}

export function getFishingSearchChance(
	fishingStrength: number,
	LevelLoc: number,
	bait: number,
	affixSuccessChance: number,
	findFails: number
) {
	const value =
		affixSuccessChance + baseSuccessChance + LevelLoc / 275 + fishingStrength + bait / 200 + findFails / 6;
	return value;
}

export function getBaitConsumeChance(fishingLevel: number, baitPreservationChance: number, baitDeadliestCatch: number) {
	let baseConsumeChance = 1;

	baseConsumeChance += baitDeadliestCatch;
	baseConsumeChance -= fishingLevel / 550;
	baseConsumeChance -= baitPreservationChance;

	baseConsumeChance = Math.max(baseConsumeChance, 0.5);
	return baseConsumeChance;
}

export function getFishingNodes(
	location: ILocationData,
	bonus: number,
	level: number,
	fishingMagnetismStrength: number
) {
	const nodeArray = structuredClone(location.nodes);
	if (!nodeArray) {
		return [];
	}

	const newNodeArray: ILoot[] = [];
	let totalFrequency = 0;
	let id = 0;
	const levelScaling = 50;
	for (const currentNode of nodeArray) {
		const current: ILoot = {
			id,
			frequency: 0,
		};
		id++;
		current.frequency = currentNode.frequency;
		current.frequency += bonus;
		if (current.frequency <= 0) {
			continue;
		}
		current.frequency *= 1 + level / 360;
		if (currentNode.maxFrequency && current.frequency > currentNode.maxFrequency) {
			const bonusRate =
				((current.frequency - currentNode.maxFrequency) * currentNode.maxFrequency) / levelScaling;
			current.frequency = currentNode.maxFrequency + bonusRate;
		}
		newNodeArray.push(current);
		totalFrequency += current.frequency;
	}

	if (fishingMagnetismStrength > 0) {
		const bonusMult = 1 + fishingMagnetismStrength / 50;
		const averageFreq = totalFrequency / newNodeArray.length;
		for (const node of newNodeArray) {
			const freqHere = node.frequency;
			if (freqHere && freqHere <= averageFreq) {
				node.frequency = freqHere * bonusMult;
			}
		}
	}
	return newNodeArray;
}

export function getFishingNodeLoot(
	locationLoot: {
		loot?: ILoot[] | undefined;
		nodes?: Record<string, ILoot[]> | undefined;
	},
	nodeID: string,
	bonus: number,
	level: number,
	deadliestCatchStrength: number,
	fiberFinderStrength: number,
	itemList: TItemListClient
) {
	const locationNodesLoot = locationLoot.nodes;
	if (!locationNodesLoot) return [];
	const nodeLootTable = locationNodesLoot[nodeID];
	if (!nodeLootTable) return [];
	const lootArray = structuredClone(nodeLootTable);

	const newLootArray: ILoot[] = [];
	let totalFrequency = 0;
	const levelScaling = 50;
	for (const current of lootArray) {
		current.frequency = current.frequency ?? 0;
		current.frequency += bonus;
		if (current.frequency <= 0) {
			continue;
		}
		current.frequency *= Math.max(1, 1 + level / 360);

		if (current.maxFrequency && current.frequency > current.maxFrequency) {
			const bonusRate = ((current.frequency - current.maxFrequency) * current.maxFrequency) / levelScaling;
			current.frequency = current.maxFrequency + bonusRate;
		}
		newLootArray.push(current);
		totalFrequency += current.frequency;
	}

	// Below enchants allow for the frequencies to exceed the original maximums!
	if (deadliestCatchStrength > 0) {
		const bonusMult = 1 + deadliestCatchStrength / 20;
		const averageFreq = totalFrequency / newLootArray.length;
		for (const loot of newLootArray) {
			const lootFreq = loot.frequency;
			if (lootFreq && lootFreq <= averageFreq) {
				loot.frequency = lootFreq * bonusMult;
			}
		}
	}

	if (fiberFinderStrength > 0) {
		for (const loot of newLootArray) {
			const lootData = itemList[loot.id];
			if (lootData?.class === 'fiber') {
				loot.frequency = (loot.frequency ?? 1) * (1 + fiberFinderStrength);
			}
		}
	}
	return newLootArray;
}

export function getNodeSize(chosenNode: ILocationNode, level: number, bait: number, nodeBonusSize: number) {
	const minAmountBase = chosenNode.minimumBaseAmount;
	const maxAmountBase =
		chosenNode.maximumBaseAmount !== undefined ? chosenNode.maximumBaseAmount : chosenNode.minimumBaseAmount;
	const minAmount = (nodeBonusSize + 1) * (minAmountBase + level / 12 + bait / 20);
	const maxAmount = (nodeBonusSize + 1) * (maxAmountBase + level / 16 + bait / 40);

	// Swap if min is larger than max
	if (minAmount > maxAmount) {
		return { minAmount: maxAmount, maxAmount: minAmount };
	}
	return { minAmount, maxAmount };
}

/** Not suitable for UI as it includes random */
export function getFinalNodeSize(chosenNode: ILocationNode, level: number, bait: number, nodeBonusSize: number) {
	let { minAmount, maxAmount } = getNodeSize(chosenNode, level, bait, nodeBonusSize);

	const luckyChance = 0.05 + bait / 2000;
	if (Math.random() <= luckyChance) {
		minAmount *= 1.5;
		maxAmount *= 3;
	}
	return Math.floor(Math.random() * (maxAmount - minAmount + 1) + minAmount);
}
