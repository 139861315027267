import { Grid, Image, Td, Tr } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeWrappingTooltip } from '@idlescape/ui';
import React from 'react';
import { MdEdit } from 'react-icons/md';
import { TSkillName } from '../../../../../../game-server/src/Atypes/Skills';
import { IInspectData } from '../../../../../../game-server/src/modules/profile/Profile';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { tabs } from '../../../../utils/NavigationTabsList';

const medals = ['gold', 'silver', 'bronze'];
const colors = ['gold', 'silver', 'rgb(205, 127, 50)'];

export function HiscoreDisplay({ hiscore }: { hiscore: IInspectData['hiscores'][0] }) {
	function getSkillIcon() {
		if (hiscore.skill === 'total') {
			if (hiscore.mastery) return '/images/total_level_mastery_icon.png';
			return '/images/total_level.png';
		}
		return tabs[hiscore.skill].icon;
	}

	const medal = medals[hiscore.rank - 1] ?? '';
	const color = colors[hiscore.rank - 1] ?? 'white';
	return (
		<Tr color={color}>
			<Td>
				<Image
					className={`header-league-icon ${hiscore.mastery ? 'top-20-icon-mastery' : 'top-20-icon-normal'}`}
					src={getSkillIcon()}
					alt={hiscore.skill}
				/>
			</Td>
			<Td isNumeric minWidth={hiscore.rank <= 3 ? '90px' : undefined}>
				{hiscore.rank <= 3 && (
					<Image
						src={`/images/ui/highscore_${medal}.png`}
						alt={medal + ' medal'}
						width='30px'
						height='30px'
						marginRight='6px'
						verticalAlign='middle'
						borderStyle='none'
					/>
				)}
				{hiscore.rank}
			</Td>
			<Td isNumeric>{hiscore.level}</Td>
		</Tr>
	);
}

function HiscorePickerImage({
	skill,
	mastery,
	src,
	name,
	active,
}: {
	skill: TSkillName;
	mastery: boolean;
	src: string;
	name: string;
	active: boolean;
}) {
	function toggleHiscore(skill: TSkillName, mastery: boolean) {
		if (active) {
			socket.emit('profile:hiscore:remove', { skill, mastery });
		} else {
			socket.emit('profile:hiscore:add', { skill, mastery });
		}
	}

	const border = active ? { border: '1px solid #fff', borderRadius: '5px' } : {};

	return (
		<Image
			className={`header-league-icon ${mastery ? 'top-20-icon-mastery' : 'top-20-icon-normal            '}`}
			src={src}
			alt={name}
			onClick={() => toggleHiscore(skill, mastery)}
			cursor='pointer'
			_hover={{ transform: 'scale(1.05)' }}
			{...border}
		/>
	);
}

export default function HiscoreEditor({ activeHiscores }: { activeHiscores: IInspectData['hiscores'] }) {
	const skills = usePlayerField('skills');

	function hiscorePicker() {
		const skillButtons: React.ReactElement[] = [
			<HiscorePickerImage
				key='total-false'
				skill='total'
				mastery={false}
				src='/images/total_level.png'
				name='Total Level'
				active={activeHiscores.find((h) => h.skill === 'total' && h.mastery === false) !== undefined}
			/>,
			<HiscorePickerImage
				key='total-true'
				skill='total'
				mastery={true}
				src='/images/total_level_mastery_icon.png'
				name='Total Mastery Level'
				active={activeHiscores.find((h) => h.skill === 'total' && h.mastery === true) !== undefined}
			/>,
		];
		for (const skill of Object.keys(skills)) {
			if (skill === 'total') continue;
			for (const mastery of [false, true]) {
				const active = activeHiscores.find((h) => h.skill === skill && h.mastery === mastery) !== undefined;
				const skillData = tabs[skill];
				skillButtons.push(
					<HiscorePickerImage
						key={`${skill}-${mastery}`}
						skill={skill as TSkillName}
						mastery={mastery}
						src={skillData.icon}
						name={skill}
						active={active}
					/>
				);
			}
		}
		return <Grid gridTemplateColumns='repeat(8, 1fr)'>{skillButtons}</Grid>;
	}

	return (
		<IdlescapeWrappingTooltip content={hiscorePicker()} activateOnClick>
			<IdlescapeButton variant={'blue'} size='icon' position='absolute' top='0' right='0'>
				<MdEdit />
			</IdlescapeButton>
		</IdlescapeWrappingTooltip>
	);
}
