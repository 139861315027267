import React from 'react';
import { IItem } from '../../../../../../../game-server/src/modules/items/items.interface';
import { itemList } from '../../../../../utils/itemList';
import { EquipmentTooltip } from '../../../Tooltips/Types/EquipmentTooltip';
import { Box, Grid } from '@chakra-ui/react';
import {
	getEliteScrollWaves,
	getLocationTreasureHunter,
	getMonsterDifficulty,
	getMonsterTreasureHunter,
} from '../../../../../utils/itemFunctions';
import { concat } from 'lodash';
import { generateAffinityDictionary } from '../../../Tooltips/Data/DamageTypes';
import { IdlescapeFrameBox } from '@idlescape/ui';
import { formatStat } from '../../../../../utils/StringFormatter';

const equipmentStatsToLabelMapping = {
	...generateAffinityDictionary('offensiveDamageAffinity', ''),
	...generateAffinityDictionary('offensiveAccuracyAffinityRating', 'Accuracy'),
	...generateAffinityDictionary('defensiveDamageAffinity', ''),
	'weaponBonus.strength': 'Strength',
	'weaponBonus.intellect': 'Intellect',
	'weaponBonus.dexterity': 'Dexterity',
	'offensiveCritical.chance': 'Crit Chance',
	'offensiveCritical.damageMultiplier': 'Crit Mult',
	'armorBonus.protection': 'Protection',
	'armorBonus.resistance': 'Resistance',
	'armorBonus.agility': 'Agility',
	'armorBonus.stamina': 'Stamina',
	'defensiveCritical.chance': 'Crit Avoidance',
	'defensiveCritical.damageMultiplier': 'Crit Reduction',
	'toolBoost.fishing': 'Fishing',
	'toolBoost.fishingBaitPower': 'Bait Power',
	'toolBoost.fishingReelPower': 'Reel Power',
	'toolBoost.fishingRarityPower': 'Bonus Rarity',
	'toolBoost.mining': 'Mining',
	'toolBoost.foraging': 'Foraging',
	'toolBoost.farming': 'Farming',
	'toolBoost.cooking': 'Cooking',
	'toolBoost.smithing': 'Smithing',
	'toolBoost.enchanting': 'Enchanting',
	'toolBoost.runecrafting': 'Runecrafting',
};
function AugmentingItemStats({ item }: { item: IItem | undefined }) {
	if (!item) return null;
	const itemData = itemList[item.itemID];

	function getEquipmentStats(item: IItem) {
		const tooltipManager = new EquipmentTooltip();
		const { equipmentStats } = tooltipManager.generateTooltipData(item, itemData, {});
		if (!equipmentStats) return [];
		return Object.values(equipmentStats).reduce((acc, category) => {
			acc.push(
				...Object.entries(category).reduce((acc, [key, value]) => {
					const bonus = getAugmentationBonus(key);
					if (bonus !== 0) {
						const stat = equipmentStatsToLabelMapping[key as keyof typeof equipmentStatsToLabelMapping];
						acc.push({
							stat,
							currentValue: formatStat(key, value),
							bonus: formatStat(key, bonus, true),
						});
					}
					return acc;
				}, [] as { stat: string; currentValue: string; bonus: string }[])
			);
			return acc;
		}, [] as { stat: string; currentValue: string; bonus: string }[]);
	}

	function getAugmentationBonus(stat: string) {
		return itemData.equipmentStats?.augmentationBonus?.find((augBonus) => augBonus.stat === stat)?.value ?? 0;
	}

	function getDungeonStats(item: IItem) {
		if (!itemData.tags?.includes('dungeon')) return [];
		const fakeItemPlusOne: IItem = { ...item, augmentations: (item.augmentations ?? 0) + 1 };
		const keyStats: { stat: string; currentValue: string; bonus: string }[] = [
			{
				stat: 'Monster Difficulty',
				currentValue: getMonsterDifficulty(item).toString(),
				bonus: (getMonsterDifficulty(fakeItemPlusOne) - getMonsterDifficulty(item)).toString(),
			},
			{
				stat: 'Monster Treasure Hunter',
				currentValue: getMonsterTreasureHunter(item).toString(),
				bonus: (getMonsterTreasureHunter(fakeItemPlusOne) - getMonsterTreasureHunter(item)).toString(),
			},
			{
				stat: 'Location Treasure Hunter',
				currentValue: getLocationTreasureHunter(item).toString(),
				bonus: (getLocationTreasureHunter(fakeItemPlusOne) - getLocationTreasureHunter(item)).toString(),
			},
		];
		return keyStats;
	}

	function getEliteStats(item: IItem) {
		if (!itemData.tags?.includes('elite')) return [];
		const fakeItemPlusOne: IItem = { ...item, augmentations: (item.augmentations ?? 0) + 1 };
		const keyStats: { stat: string; currentValue: string; bonus: string }[] = [
			{
				stat: 'Monster Difficulty',
				currentValue: getMonsterDifficulty(item).toString(),
				bonus: (getMonsterDifficulty(fakeItemPlusOne) - getMonsterDifficulty(item)).toString(),
			},
			{
				stat: 'Elite Scroll Waves',
				currentValue: getEliteScrollWaves(item).toString(),
				bonus: (getEliteScrollWaves(fakeItemPlusOne) - getEliteScrollWaves(item)).toString(),
			},
		];
		return keyStats;
	}

	const augmentingStats: {
		stat: string;
		currentValue: string;
		bonus: string;
	}[] = concat(getEquipmentStats(item), getDungeonStats(item), getEliteStats(item));

	return (
		<IdlescapeFrameBox
			as={Grid}
			gridTemplateColumns='repeat(3,auto)'
			alignContent='center'
			justifyContent='center'
			columnGap='5px'
			minWidth='200px'
			padding='8px 15px'
			_hover={{}}
		>
			<Box fontWeight='bold'>Stat</Box>
			<Box fontWeight='bold'>Current</Box>
			<Box fontWeight='bold'>Bonus</Box>
			{augmentingStats.map((augStat, index) => {
				return (
					<React.Fragment key={index}>
						<Box>{augStat.stat}</Box>
						<Box textAlign='right'>{augStat.currentValue}</Box>
						<Box textAlign='right'>{augStat.bonus}</Box>
					</React.Fragment>
				);
			})}
		</IdlescapeFrameBox>
	);
}

export default AugmentingItemStats;
