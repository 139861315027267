import { Box } from '@chakra-ui/react';
import React from 'react';
import { IItem, TPartialItemWithItemID } from '../../../../game-server/src/modules/items/items.interface';
import { ILoot, ILootExtra, ILootWithFrequency } from '../../../../game-server/src/modules/items/LootInterface';
import { itemImage } from '../../utils/itemHelperFunctions';
import { itemList } from '../../utils/itemList';
import ItemTooltip from './Tooltips/ItemTooltip';

export interface IResourceLoot extends ILoot {
	minRarity?: number;
}

export interface IResourceLootWithFrequency extends ILootWithFrequency {
	minRarity?: number;
}

function ResourceList(props: { resources: IResourceLoot[] }) {
	const resourceDisplays = props.resources.map(
		({ id, frequency, chance, minAmount, maxAmount, minRarity, extra }) => {
			let bottomLabel: React.ReactNode;
			const chanceOrFrequency = chance ?? frequency;
			if (!chanceOrFrequency || chanceOrFrequency === 0) {
				bottomLabel = minRarity ? (
					<Box color='#F88'>
						{Math.abs(minRarity)}
						<br />
						Rarity
					</Box>
				) : (
					'???'
				);
			} else if (chanceOrFrequency < 0.001) {
				const fraction = (chanceOrFrequency * 10000).toFixed(1) + '/10k';
				bottomLabel = `${fraction}`;
			} else if (chanceOrFrequency < 0.0001) {
				const fraction = (chanceOrFrequency * 100000).toFixed(1) + '/100k';
				bottomLabel = `${fraction}`;
			} else {
				bottomLabel = chanceOrFrequency.toLocaleString('en-US', {
					maximumSignificantDigits: 3,
					style: 'percent',
				});
			}
			let topLabel: React.ReactElement = <></>;
			if (maxAmount && maxAmount !== minAmount) {
				topLabel = (
					<Box pos='absolute' top='0' right='5px' fontSize='14px'>
						{minAmount?.toLocaleString('en-US', { notation: 'compact' })}-
						{maxAmount?.toLocaleString('en-US', { notation: 'compact' })}
					</Box>
				);
			} else if (minAmount && minAmount > 1) {
				topLabel = (
					<Box pos='absolute' top='0' right='5px' fontSize='14px'>
						~{minAmount?.toLocaleString('en-US', { notation: 'compact' })}
					</Box>
				);
			}

			const itemData = itemList[id];
			const itemClass = itemData.class;
			const itemDisplay: TPartialItemWithItemID<IItem> = { itemID: id };
			if (itemData.forcedEnchant && itemData.forcedEnchantAmount) {
				itemDisplay.enchantmentStrength = itemData.forcedEnchantAmount;
				itemDisplay.enchantmentID = itemData.forcedEnchant;
			}
			let keyExtra = '';
			if (extra) {
				for (const [key, value] of Object.entries(extra)) {
					itemDisplay[key as keyof ILootExtra] = value;
					keyExtra += key + value;
				}
			}
			return (
				<div
					className={`item ${itemClass}`}
					key={'resource-' + id + '-' + chanceOrFrequency + '-' + keyExtra}
					data-itemid={id}
				>
					{itemImage(itemData)}
					{topLabel}
					<Box position='absolute' fontSize='70%' lineHeight='1.1em' bottom='2px' left='0' right='0'>
						{bottomLabel}
					</Box>
					<ItemTooltip item={itemDisplay} />
				</div>
			);
		}
	);
	return <>{resourceDisplays}</>;
}

export default ResourceList;
